import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import ExperienceIcon from "../../../../assets/images/experienceIcon.png";
import CommonButton from "../../../../component/Button";
import CommonTextField from "../../../../component/Input";
import {
  StyledDialog,
  StyledFlexColumnView,
} from "../../../common-components/StyledComponents";
import DateRangePickerComp from "../../../jobs/common-card.js/DatePicker";

const ElcDialog = ({ open, onClose, title, handlELcSubmit, elcPopup }) => {
  const initialValues = {
    school: elcPopup?.name || "",
    Date_range: {
      start_date:
        elcPopup?.start_date === ""
          ? elcPopup?.end_date
          : elcPopup?.start_date || "",
      end_date:
        elcPopup?.end_date === ""
          ? elcPopup?.start_date
          : elcPopup?.end_date || "",
    },
    index: elcPopup?.index,
  };

  const validationSchema = Yup.object({
    school: Yup.string().required("School / University is required"),
    Date_range: Yup.object({
      start_date: Yup.date()
        .nullable()
        .typeError("Start date must be a valid date"),
      end_date: Yup.date()
        .nullable()
        .typeError("End date must be a valid date"),
    })
      .test(
        "at-least-one-date",
        "Date is required",
        (value) => !!(value?.start_date || value?.end_date)
      )
      .required("Start and End dates are required"),
  });

  const handleSubmit = (values) => {
    handlELcSubmit(title, values);
  };

  return (
    <StyledDialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <img
        src={ExperienceIcon}
        alt="Education"
        style={{ position: "absolute", padding: "16px", width: "90px" }}
      />
      <DialogActions>
        <Button onClick={onClose}>
          <CloseIcon />
        </Button>
      </DialogActions>
      <DialogTitle>
        <Typography variant="h3" marginTop={"10px"}>
          {elcPopup.button === "Update"
            ? title.replace("Add", "Update")
            : title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ values, handleChange, setFieldValue, errors, touched }) => {
            return (
              <Form>
                <Grid2 container direction="column" spacing={3}>
                  <Grid2 size={12}>
                    <StyledFlexColumnView>
                      <Typography
                        fontWeight={500}
                        fontSize={"14px"}
                        color={"#344054"}
                      >
                        {"School / University"}
                      </Typography>
                      <CommonTextField
                        placeholder="Enter School / University"
                        padding="10px"
                        placeholderFontSize={"15px"}
                        error={errors.school && touched.school}
                        value={values.school}
                        onChange={(e) => {
                          setFieldValue("school", e.target.value);
                        }}
                      />
                      {errors.school && touched.school && (
                        <Typography
                          color="error"
                          variant="body1"
                          marginTop={"-10px"}
                        >
                          {errors.school}
                        </Typography>
                      )}
                    </StyledFlexColumnView>
                  </Grid2>

                  <Grid2 size={12}>
                    <StyledFlexColumnView>
                      <Typography
                        fontWeight={500}
                        fontSize={"14px"}
                        color={"#344054"}
                      >
                        {"Date"}
                      </Typography>
                      <DateRangePickerComp
                        startDateProp={values.Date_range.start_date}
                        endDateProp={values.Date_range.end_date}
                        handleChange={(start_date, end_date) => {
                          setFieldValue("Date_range", {
                            start_date: start_date,
                            end_date: end_date,
                          });
                        }}
                        error={
                          touched?.Date_range?.start_date &&
                          touched?.Date_range?.end_date &&
                          errors?.Date_range
                        }
                      />
                      {touched?.Date_range?.start_date &&
                        touched?.Date_range?.end_date &&
                        errors?.Date_range && (
                          <Typography
                            color="error"
                            variant="body1"
                            marginTop={"-10px"}
                          >
                            {errors?.Date_range}
                          </Typography>
                        )}
                    </StyledFlexColumnView>
                  </Grid2>

                  <CommonButton
                    variant="outlined"
                    color="#344054"
                    border="#D0D5DD"
                    fontWeight="600"
                    value={elcPopup.button}
                    padding={"10px"}
                    type="submit"
                    loading={elcPopup.loading}
                    disabled={elcPopup.loading}
                  />
                </Grid2>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </StyledDialog>
  );
};

export default ElcDialog;

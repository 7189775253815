import { Box, Typography } from "@mui/material";
import React from "react";
import {
  Legend,
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  RadarChart,
  Tooltip,
} from "recharts";
import {
  CommonGrid2,
  DotColor,
} from "../../../common-components/StyledComponents";
import { TooltipParagraph } from "../../style";
import { HandleSkeletion } from "../../../../utils/constants";

function Comparison({ matchedData, loading }) {
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const {
        name,
        candidate_score,
        highest_score,
        average_score,
        justification,
      } = payload[0].payload;
      return (
        <Box
          backgroundColor={"rgb(249 247 255)"}
          padding={"10px"}
          border={"1px solid rgb(127, 86, 217)"}
          borderRadius={"10px"}
        >
          <TooltipParagraph
            textAlign={"center"}
            fontWeight={700}
            fontSize={"14px"}
            color="#000"
          >
            {name}
          </TooltipParagraph>
          <CommonGrid2
            gap={"4px"}
            marginTop={"10px"}
            display={"flex"}
            flexDirection={"column"}
          >
            <TooltipParagraph
              color={"#6941C6"}
              display={"flex"}
              justifyContent={"space-between"}
              flexDirection={"row"}
              gap="10px"
            >
              Candidate Score:{" "}
              <TooltipParagraph fontWeight={700}>
                {candidate_score}
              </TooltipParagraph>
            </TooltipParagraph>
            <TooltipParagraph
              color={"#667085a1"}
              display={"flex"}
              justifyContent={"space-between"}
              flexDirection={"row"}
              gap="10px"
            >
              Highest Score:{" "}
              <TooltipParagraph fontWeight={700}>
                {highest_score}
              </TooltipParagraph>
            </TooltipParagraph>
            <TooltipParagraph
              color={"#F79009"}
              display={"flex"}
              justifyContent={"space-between"}
              flexDirection={"row"}
              gap="10px"
            >
              Average Score:{" "}
              <TooltipParagraph fontWeight={700}>
                {average_score}
              </TooltipParagraph>
            </TooltipParagraph>
            {justification && (
              <span
                style={{
                  // color: "#47CD89",
                  color: "#00a6ff",
                  width: "300px",
                  textAlign: "justify",
                  fontSize: "14px",
                  lineHeight: "18px",
                }}
                gap="10px"
              >
                Justification:{" "}
                <span fontWeight={700} style={{ fontSize: "12px" }}>
                  {justification}
                </span>
              </span>
            )}
          </CommonGrid2>
        </Box>
      );
    }
    return null;
  };

  const renderLegend = (props) => {
    const { payload } = props;
    return (
      <CommonGrid2
        justifyContent="center"
        display={"flex"}
        position={"relative"}
        top={"1.5rem"}
      >
        {payload.map((entry, index) => (
          <CommonGrid2
            key={`item-${index}`}
            alignItems={"center"}
            margin={"0 10px"}
            flexDirection={"row"}
            display={"flex"}
          >
            <DotColor
              color={entry.color}
              marginRight={"5px"}
              width={"8px"}
              height={"8px"}
            ></DotColor>
            <Typography fontSize={"13px"} fontWeight={600} color={"#475467"}>
              {entry.value}
            </Typography>
          </CommonGrid2>
        ))}
      </CommonGrid2>
    );
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return `${text.substring(0, maxLength)}...`;
    }
    return text;
  };

  const CustomPolarRadiusAxisTick = (props) => {
    const { x, y, payload } = props;
    return (
      <text
        x={x}
        y={y}
        dy={16}
        textAnchor="middle"
        fill="#666"
        fontSize={"13px"}
        fontWeight="bold"
      >
        {payload.value}
      </text>
    );
  };

  return loading ? (
    <HandleSkeletion height={100} width={"100%"} />
  ) : (
    <Box
      width={"100%"}
      padding={"10px 10px 2rem"}
      marginTop={"0.5rem"}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"end"}
    >
      <RadarChart
        cx="50%"
        cy="50%"
        outerRadius="90%"
        width={500}
        height={300}
        data={matchedData?.spider_graph_results}
      >
        <PolarGrid />

        <PolarAngleAxis
          dataKey="name"
          tick={({ payload, x, y, textAnchor }) => {
            const truncatedText = truncateText(payload.value, 15);
            return (
              <text
                x={x}
                y={y + 4}
                textAnchor={textAnchor}
                fontSize="13px"
                fontWeight="600"
                title={"payload.value"}
                fill="#475467"
              >
                {truncatedText}
              </text>
            );
          }}
        />
        <PolarRadiusAxis
          angle={30}
          domain={[0, 100]}
          tick={CustomPolarRadiusAxisTick}
          axisLine={false}
        />
        <Radar
          name="Candidate Score"
          dataKey="candidate_score"
          stroke="#6941C6"
          fill="transparent"
          fillOpacity={0.3}
          strokeWidth={2}
        />
        <Radar
          name="Highest Score"
          dataKey="highest_score"
          stroke="#667085a1"
          fill="transparent"
          fillOpacity={0.3}
          strokeWidth={2}
        />
        <Radar
          name="Average Score"
          dataKey="average_score"
          stroke="#F79009"
          fill="transparent"
          fillOpacity={0.1}
          strokeWidth={2}
        />
        <Tooltip content={<CustomTooltip />} />
        <Legend content={renderLegend} />
      </RadarChart>
    </Box>
  );
}

export default Comparison;

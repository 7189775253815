import { JOBS } from "../Action";

const intialState = {
  jobSummary: {
    results: {
      count: "",
      candidates: [
        {
          candidate_name: "",
          score: "",
          status: "'",
        },
      ],
    },

    skill: {
      primary_attributes: [
        {
          skill: "",
          high: "",
          low: "",
          median: "",
        },
      ],
      secondary_attributes: [
        {
          skill: "",
          high: "",
          low: "",
          median: "",
        },
      ],
    },
  },
  jobJobEdit: {},
  jobJobEditData: {
    jobData: {},
    formikData: {},
  },
};
const JobReducer = (state = intialState, action) => {
  switch (action.type) {
    case JOBS.jobSummary:
      return { ...state, jobSummary: action.data };
    case JOBS.jobJobEdit:
      return { ...state, jobJobEdit: action.data };
    case JOBS.jobJobEditData:
      return {
        ...state,
        jobJobEditData: {
          jobData: action.jobData,
          formikData: action.formikData,
        },
      };
    default:
      return state;
  }
};
export default JobReducer;

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2,
  MenuItem,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import RecordingIco from "../../assets/icons/RecordingIco";
import XCloseIco from "../../assets/icons/XCloseIco";
import NoteBackgroundImg from "../../assets/images/note-background.png";
import CommonButton from "../../component/Button";
import CommonTextField from "../../component/Input";
import DropDownSelect from "../../component/Select";
import DateRangePickerComp from "../jobs/common-card.js/DatePicker";
import { CommonGrid2 } from "./StyledComponents";

function CreateNote({
  openNoteDialog,
  handleCloseNoteDialog,
  loading,
  id,
  noteUpperData,
  typeData,
  handleNoteSubmit,
  noteList,
}) {
  const initialValues = {
    type: noteList.type || "",
    note: noteList?.note || "",
    interaction: "Email",
    added_by: "username",
    job_history_type: "New",
    job_history_reason: noteList?.job_history_reason || "",
    accecpting_less_more_candidates:
      noteList?.accecpting_less_more_candidates || "",
    locals_only: noteList?.locals_only || "",
    hybrid_days: noteList?.hybrid_days || "",
    project_scope: noteList?.project_scope || "",
    other_details: noteList?.other_details || "",
    Date_range: {
      deadline_start: noteList?.deadline_start || "",
      deadline_end: noteList?.deadline_end || "",
    },
  };

  const validationSchema = Yup.object({
    type: Yup.string()
      .required("Type is required")
      .oneOf(["Blank", "Supplier Call"], "Type must be Blank or Supplier Call"),

    note: Yup.string().when("type", {
      is: (value) => value === "Blank",
      then: (schema) => schema.required("Note is required"),
      otherwise: (schema) => schema.notRequired(),
    }),

    job_history_reason: Yup.string().when("type", {
      is: "Supplier Call",
      then: (schema) => schema.required("History reason is required"),
      otherwise: (schema) => schema.notRequired(),
    }),

    accecpting_less_more_candidates: Yup.string().when("type", {
      is: "Supplier Call",
      then: (schema) => schema.required("Candidates are required"),
      otherwise: (schema) => schema.notRequired(),
    }),

    locals_only: Yup.string().when("type", {
      is: "Supplier Call",
      then: (schema) => schema.required("Locals only is required"),
      otherwise: (schema) => schema.notRequired(),
    }),

    hybrid_days: Yup.number().when("type", {
      is: "Supplier Call",
      then: (schema) =>
        schema
          .typeError("Hybrid days must be a number")
          .required("Hybrid days are required")
          .min(0, "Hybrid days cannot be negative"),
      otherwise: (schema) => schema.notRequired(),
    }),

    project_scope: Yup.string().when("type", {
      is: "Supplier Call",
      then: (schema) => schema.required("Project Scope is required"),
      otherwise: (schema) => schema.notRequired(),
    }),

    other_details: Yup.string().when("type", {
      is: "Supplier Call",
      then: (schema) => schema.required("Details are required"),
      otherwise: (schema) => schema.notRequired(),
    }),

    Date_range: Yup.object({
      deadline_start: Yup.date()
        .nullable()
        .typeError("Start date must be a valid date"),
      deadline_end: Yup.date()
        .nullable()
        .typeError("End date must be a valid date"),
    }).when("type", {
      is: "Supplier Call",
      then: (schema) =>
        schema.test(
          "at-least-one-date",
          "At least one date is required",
          (value) => !!(value?.deadline_start || value?.deadline_end)
        ),
      otherwise: (schema) => schema.notRequired(),
    }),
  });

  const handleSubmit = (values) => {
    handleNoteSubmit(values);
  };

  const CustomMenuItem = (item, index) => {
    return (
      <MenuItem value={item?.value} key={index}>
        {item?.name}
      </MenuItem>
    );
  };
  return (
    <>
      <Dialog
        fullWidth
        maxWidth={"sm"}
        open={openNoteDialog}
        onClose={handleCloseNoteDialog}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "12px",
            background: "#FFF",
            boxShadow:
              "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
          },
        }}
      >
        <img
          src={NoteBackgroundImg}
          style={{ position: "absolute", width: "max-content", opacity: "0.7" }}
        />
        <DialogActions>
          <Button onClick={handleCloseNoteDialog} sx={{ marginTop: "10px" }}>
            <XCloseIco />
          </Button>
        </DialogActions>
        <DialogTitle>
          <Typography variant="h3" sx={{ mt: 2 }}>
            {id ? "Update Note" : "Create Note"}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({ values, handleChange, setFieldValue, errors, touched }) => {
              return (
                <Form>
                  <Grid2 spacing={2.5} container>
                    <CommonGrid2
                      container
                      spacing={2}
                      display={"flex"}
                      justifyContent={"space-between"}
                    >
                      {noteUpperData.map((item, index) => (
                        <CommonGrid2 size={6}>
                          <Grid2
                            item
                            xs={12}
                            display={"flex"}
                            alignItems={"center"}
                            width={"auto"}
                          >
                            <CommonTextField
                              flex={0.5}
                              read={true}
                              border={"0.1px solid #D0D5DD"}
                              value={item.title}
                              padding={"12px"}
                              fontWeight="600"
                              input_color={"#667085"}
                              paddingRight="0px"
                              fontSize={"15px"}
                              borderRadius={"7px 0 0 7px"}
                            />

                            <CommonTextField
                              flex={1}
                              read={true}
                              value={item.value}
                              border={"0.1px solid #D0D5DD"}
                              padding={"12px"}
                              paddingRight="0px"
                              borderRadius={"0 7px 7px 0"}
                              fontSize={"14px"}
                              customStyle={{
                                "& .Mui-readOnly": {
                                  borderRight: "0px !important",
                                },
                              }}
                            />
                          </Grid2>
                        </CommonGrid2>
                      ))}
                    </CommonGrid2>

                    <CommonGrid2
                      size={12}
                      gap={"6px"}
                      display={"flex"}
                      flexDirection={"column"}
                    >
                      <Typography
                        fontSize={"15px"}
                        color={
                          errors.type && touched.type ? "error" : "#344054"
                        }
                      >
                        {"Type"}
                      </Typography>
                      <DropDownSelect
                        marginTop="0px"
                        title={"Select type"}
                        removeAnimation={true}
                        data={typeData}
                        valueChange={true}
                        CustomMenuItem={CustomMenuItem}
                        value={values.type}
                        handleChange={(event, newValue) => {
                          setFieldValue("type", event.target.value);
                        }}
                        error={errors.type && touched.type}
                        customStyle={{
                          "& #demo-simple-select": { padding: "14px" },
                          "& label.MuiInputLabel-sizeMedium": { top: "-3px" },
                        }}
                      />
                      {errors.type && touched.type && (
                        <Typography
                          color="error"
                          marginTop={"-4px"}
                          fontWeight={600}
                          fontSize={"13px"}
                        >
                          {errors.type}
                        </Typography>
                      )}
                    </CommonGrid2>

                    {values?.type === "Blank" && (
                      <Grid2 size={12} container spacing={4}>
                        <CommonGrid2
                          size={12}
                          gap={"10px"}
                          display={"flex"}
                          flexDirection={"column"}
                        >
                          <Typography
                            color={
                              errors.note && touched.note ? "error" : "#344054"
                            }
                            fontSize={"15px"}
                            fontWeight={500}
                          >
                            Note
                          </Typography>
                          <CommonTextField
                            padding={"0px"}
                            placeholder="Enter your note"
                            multiline
                            rows={4}
                            value={values.note}
                            error={errors.note && touched.note}
                            onChange={(e) => {
                              setFieldValue("note", e.target.value);
                            }}
                            fullWidth
                            placeholderFontSize="15px"
                            fontSize={"14px"}
                          />
                          {errors.note && touched.note && (
                            <Typography
                              color="error"
                              marginTop={"-4px"}
                              fontWeight={600}
                              fontSize={"13px"}
                            >
                              {errors.note}
                            </Typography>
                          )}
                        </CommonGrid2>

                        <Grid2 size={12}>
                          <CommonButton
                            variant="outlined"
                            color="#344054"
                            border="#D0D5DD"
                            fontWeight="600"
                            width={"100%"}
                            value={id ? "Start Recording" : "Record"}
                            padding="10px 15px"
                            startIcon={<RecordingIco />}
                            disabled={true}
                          />
                        </Grid2>
                      </Grid2>
                    )}
                    {values?.type === "Supplier Call" && (
                      <>
                        <CommonGrid2
                          size={6}
                          gap={"6px"}
                          display={"flex"}
                          flexDirection={"column"}
                        >
                          <Typography
                            fontWeight={500}
                            fontSize={"15px"}
                            color={
                              touched?.Date_range?.deadline_start &&
                              touched?.Date_range?.deadline_end &&
                              errors?.Date_range
                                ? "error"
                                : "#344054"
                            }
                          >
                            {"Deadline"}
                          </Typography>
                          <DateRangePickerComp
                            fontSize="14px"
                            startDateProp={values.Date_range.deadline_start}
                            endDateProp={values.Date_range.deadline_end}
                            handleChange={(start_date, end_date) => {
                              setFieldValue("Date_range", {
                                deadline_start: start_date,
                                deadline_end: end_date,
                              });
                            }}
                            error={
                              touched?.Date_range?.deadline_start &&
                              touched?.Date_range?.deadline_end &&
                              errors?.Date_range
                            }
                          />
                          {touched?.Date_range?.deadline_start &&
                            touched?.Date_range?.deadline_end &&
                            errors?.Date_range && (
                              <Typography
                                color="error"
                                marginTop={"-4px"}
                                fontWeight={600}
                                fontSize={"13px"}
                              >
                                {errors?.Date_range}
                              </Typography>
                            )}
                        </CommonGrid2>
                        <CommonGrid2
                          size={6}
                          gap={"6px"}
                          display={"flex"}
                          flexDirection={"column"}
                        >
                          <Typography
                            fontWeight={500}
                            fontSize={"15px"}
                            color={"#344054"}
                          >
                            {"Job Description History"}
                          </Typography>
                          <DropDownSelect
                            marginTop="0px"
                            title={"Select type"}
                            removeAnimation={true}
                            data={[{ name: "New" }]}
                            value={"New"}
                            customStyle={{
                              "& #demo-simple-select": { padding: "12px" },
                              "& label.MuiInputLabel-sizeMedium": {
                                top: "-3px",
                              },
                            }}
                          />
                        </CommonGrid2>
                        <CommonGrid2
                          size={12}
                          gap={"6px"}
                          display={"flex"}
                          flexDirection={"column"}
                        >
                          <Typography
                            color={
                              errors.job_history_reason &&
                              touched.job_history_reason
                                ? "error"
                                : "#344054"
                            }
                            fontSize={15}
                            fontWeight={500}
                          >
                            History Reason
                          </Typography>
                          <CommonTextField
                            padding={"0px"}
                            placeholder="Enter History Reason"
                            multiline
                            rows={4}
                            fullWidth
                            placeholderFontSize="15px"
                            fontSize={"14px"}
                            value={values.job_history_reason}
                            onChange={(event, newValue) => {
                              setFieldValue(
                                "job_history_reason",
                                event.target.value
                              );
                            }}
                            error={
                              errors.job_history_reason &&
                              touched.job_history_reason
                            }
                          />
                          {errors.job_history_reason &&
                            touched.job_history_reason && (
                              <Typography
                                color="error"
                                marginTop={"-4px"}
                                fontWeight={600}
                                fontSize={"13px"}
                              >
                                {errors.job_history_reason}
                              </Typography>
                            )}
                        </CommonGrid2>
                        <CommonGrid2
                          size={12}
                          gap={"6px"}
                          display={"flex"}
                          flexDirection={"column"}
                        >
                          <Typography
                            fontWeight={500}
                            fontSize={"15px"}
                            color={
                              errors.accecpting_less_more_candidates &&
                              touched.accecpting_less_more_candidates
                                ? "error"
                                : "#344054"
                            }
                          >
                            {"Accepting Less / More Experienced Canidates"}
                          </Typography>
                          <DropDownSelect
                            marginTop="0px"
                            title={"Select type"}
                            removeAnimation={true}
                            data={[{ name: "Yes" }, { name: "No" }]}
                            customStyle={{
                              "& #demo-simple-select": { padding: "12px" },
                              "& label.MuiInputLabel-sizeMedium": {
                                top: "-3px",
                              },
                            }}
                            value={values.accecpting_less_more_candidates}
                            handleChange={(event, newValue) => {
                              setFieldValue(
                                "accecpting_less_more_candidates",
                                event.target.value
                              );
                            }}
                            error={
                              errors.accecpting_less_more_candidates &&
                              touched.accecpting_less_more_candidates
                            }
                          />
                          {errors.accecpting_less_more_candidates &&
                            touched.accecpting_less_more_candidates && (
                              <Typography
                                color="error"
                                marginTop={"-4px"}
                                fontWeight={600}
                                fontSize={"13px"}
                              >
                                {errors.accecpting_less_more_candidates}
                              </Typography>
                            )}
                        </CommonGrid2>
                        <CommonGrid2
                          size={6}
                          gap={"6px"}
                          display={"flex"}
                          flexDirection={"column"}
                        >
                          <Typography
                            fontSize={"15px"}
                            color={
                              errors.locals_only && touched.locals_only
                                ? "error"
                                : "#344054"
                            }
                          >
                            {"Locals Only"}
                          </Typography>

                          <DropDownSelect
                            marginTop="0px"
                            title={"Select type"}
                            removeAnimation={true}
                            data={[{ name: "Yes" }, { name: "No" }]}
                            customStyle={{
                              "& #demo-simple-select": { padding: "12px" },
                              "& label.MuiInputLabel-sizeMedium": {
                                top: "-3px",
                              },
                            }}
                            value={values.locals_only}
                            handleChange={(event, newValue) => {
                              setFieldValue("locals_only", event.target.value);
                            }}
                            error={errors.locals_only && touched.locals_only}
                          />
                          {errors.locals_only && touched.locals_only && (
                            <Typography
                              color="error"
                              marginTop={"-4px"}
                              fontWeight={600}
                              fontSize={"13px"}
                            >
                              {errors.locals_only}
                            </Typography>
                          )}
                        </CommonGrid2>
                        <CommonGrid2
                          size={6}
                          gap={"6px"}
                          display={"flex"}
                          flexDirection={"column"}
                        >
                          <Typography
                            fontWeight={500}
                            fontSize={"15px"}
                            color={
                              errors.hybrid_days && touched.hybrid_days
                                ? "error"
                                : "#344054"
                            }
                          >
                            {"Hybrid Number of Days on Site"}
                          </Typography>
                          <CommonTextField
                            padding={"10px"}
                            placeholder="Enter Days Number"
                            fullWidth
                            placeholderFontSize="15px"
                            fontSize={"14px"}
                            value={values.hybrid_days}
                            onChange={(event, newValue) => {
                              setFieldValue("hybrid_days", event.target.value);
                            }}
                            error={errors.hybrid_days && touched.hybrid_days}
                          />
                          {errors.hybrid_days && touched.hybrid_days && (
                            <Typography
                              color="error"
                              marginTop={"-4px"}
                              fontWeight={600}
                              fontSize={"13px"}
                            >
                              {errors.hybrid_days}
                            </Typography>
                          )}
                        </CommonGrid2>
                        <CommonGrid2
                          size={12}
                          gap={"6px"}
                          display={"flex"}
                          flexDirection={"column"}
                        >
                          <Typography
                            color={
                              errors.project_scope && touched.project_scope
                                ? "error"
                                : "#344054"
                            }
                            fontSize={15}
                          >
                            Project Scope
                          </Typography>

                          <CommonTextField
                            padding={"0px"}
                            placeholder="Enter Project Scope"
                            multiline
                            rows={4}
                            fullWidth
                            placeholderFontSize="15px"
                            fontSize={"14px"}
                            value={values.project_scope}
                            onChange={(event, newValue) => {
                              setFieldValue(
                                "project_scope",
                                event.target.value
                              );
                            }}
                            error={
                              errors.project_scope && touched.project_scope
                            }
                          />
                          {errors.project_scope && touched.project_scope && (
                            <Typography
                              color="error"
                              marginTop={"-4px"}
                              fontWeight={600}
                              fontSize={"13px"}
                            >
                              {errors.project_scope}
                            </Typography>
                          )}
                        </CommonGrid2>
                        <CommonGrid2
                          size={12}
                          gap={"6px"}
                          display={"flex"}
                          flexDirection={"column"}
                        >
                          <Typography
                            color={
                              errors.other_details && touched.other_details
                                ? "error"
                                : "#344054"
                            }
                            gap={"3px"}
                            fontSize={15}
                            display={"flex"}
                            alignItems={"center"}
                          >
                            Other Details -{" "}
                            <Typography fontStyle={"italic"} fontSize={"12px"}>
                              This field is used for scoring
                            </Typography>
                          </Typography>

                          <CommonTextField
                            padding={"0px"}
                            placeholder="Enter Other Details"
                            multiline
                            rows={4}
                            fullWidth
                            placeholderFontSize="15px"
                            fontSize={"14px"}
                            value={values.other_details}
                            onChange={(event, newValue) => {
                              setFieldValue(
                                "other_details",
                                event.target.value
                              );
                            }}
                            error={
                              errors.other_details && touched.other_details
                            }
                          />
                          {errors.other_details && touched.other_details && (
                            <Typography
                              color="error"
                              marginTop={"-4px"}
                              fontWeight={600}
                              fontSize={"13px"}
                            >
                              {errors.other_details}
                            </Typography>
                          )}
                        </CommonGrid2>
                      </>
                    )}
                    <Grid2 size={12}>
                      <CommonButton
                        width="100%"
                        variant="outlined"
                        color="#344054"
                        border="#D0D5DD"
                        fontWeight="600"
                        value={id ? "Update" : "Create Note"}
                        padding="10px 20px"
                        disabled={loading}
                        loading={loading}
                        type="submit"
                      />
                    </Grid2>
                  </Grid2>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default CreateNote;

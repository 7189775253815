import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2,
  Typography,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import SkillImage from "../../../../assets/images/SkilImage.png";
import CommonButton from "../../../../component/Button";
import CommonTextField from "../../../../component/Input";
import ToogleButton from "../../../../component/ToogleButton";
import { CommonGrid2 } from "../../../common-components/StyledComponents";
import { ToogleParentContainer } from "../../style";

const AddNewSkillDialog = ({
  open,
  handleClose,
  jobModifyData,
  handleEditSkillChange,
  handleSkillClick,
  buttonLoading,
}) => {
  const validationSchema = Yup.object({
    skill: Yup.string()
      .required("Skill is required")
      .matches(/^[^\s].*$/, "Skill cannot start with spaces")
      .test("no-whitespace", "Skill cannot be just whitespace", (value) => {
        return value && value.trim().length > 0;
      }),
    experience_in_yrs: Yup.number()
      .min(0, "Experience must be a positive number")
      .required("Experience is required")
      .typeError("Experience must be a number"),
    expertise_level: Yup.string().required("Criticality is required"),
  });

  const initialValues = {
    skill: "",
    experience_in_yrs: "",
    bucket: "Additional Qualities",
    key: "medium_priority",
    expertise_level: "Familiar",
    type: "extracted_hard_skill",
    // ...(dialogData?.id && { id: dialogData?.id }),
  };

  const handleSubmit = (values, form) => {
    handleSkillClick(values, form);
  };

  return (
    <>
      <Dialog open={open} fullWidth maxWidth={"xs"} onClose={handleClose}>
        <img
          src={SkillImage}
          style={{ position: "absolute", width: "max-content", opacity: "0.7" }}
        />
        <DialogActions>
          <Button onClick={handleClose}>
            <CloseIcon />
          </Button>
        </DialogActions>
        <DialogTitle>
          <Typography variant="h3" sx={{ mt: 2 }}>
            Create New Skill
          </Typography>
        </DialogTitle>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          validateOnChange={true}
          validateOnBlur={true}
          validateOnMount={true}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
            setValues,
          }) => {
            return (
              <Form>
                <DialogContent>
                  <Grid2 gap="20px" display="flex" flexDirection="column">
                    <Grid2 display={"flex"} flexDirection={"column"}>
                      <Typography
                        variant="p"
                        color={
                          errors.skill && touched.skill ? "error" : "#344054"
                        }
                        fontSize={14}
                        fontFamily={"Inter"}
                        fontWeight={500}
                        marginBottom={"5px"}
                      >
                        Skill
                      </Typography>
                      <Field
                        name="skill"
                        component={CommonTextField}
                        padding={"10px"}
                        border={
                          errors.skill && touched.skill && "1px solid #d32f2f"
                        }
                        placeholder="Enter Skill"
                        value={values.skill}
                        onChange={(e) => {
                          setFieldValue("skill", e.target.value);
                        }}
                        placeholderFontSize="14px"
                        sx={{
                          border: "1px solid red",
                        }}
                      />
                      {errors.skill && touched.skill && (
                        <Typography color="error" variant="body1">
                          {errors.skill}
                        </Typography>
                      )}
                    </Grid2>

                    <Grid2 display={"flex"} flexDirection={"column"}>
                      <Typography
                        variant="p"
                        color={
                          errors.expertise_level && touched.expertise_level
                            ? "error"
                            : "#344054"
                        }
                        fontSize={14}
                        fontFamily={"Inter"}
                        fontWeight={500}
                        marginBottom={"0.5rem"}
                      >
                        Criticality
                      </Typography>
                      <CommonGrid2></CommonGrid2>
                      <ToogleParentContainer>
                        <ToogleButton
                          alignment={values.expertise_level}
                          padding={"5px 10px"}
                          ToogleData={
                            values["type"] === "extracted_hard_skill"
                              ? jobModifyData.criticalityHardData
                              : jobModifyData.criticalitySoftData
                          }
                          handleChange={(event, newAlignment) => {
                            if (newAlignment !== null)
                              setFieldValue("expertise_level", newAlignment);
                          }}
                          width="100%"
                          gap={"10px"}
                          border={"none !important"}
                          borderRadius={"5px !important"}
                          activeColor={"#FFFF"}
                          color={"#667085"}
                          activeBackgroundColor={
                            values?.expertise_level === "Familiar" ||
                            values?.expertise_level === "Novice"
                              ? "#A668F4"
                              : values?.expertise_level === "Expert"
                              ? "#3C097C"
                              : "#6510CF"
                          }
                        />
                      </ToogleParentContainer>
                      {errors.expertise_level && touched.expertise_level && (
                        <Typography color="error" variant="body1">
                          {errors.expertise_level}
                        </Typography>
                      )}
                    </Grid2>

                    <Grid2 display={"flex"} flexDirection={"column"}>
                      <Typography
                        variant="p"
                        color={
                          errors.experience_in_yrs && touched.experience_in_yrs
                            ? "error"
                            : "#344054"
                        }
                        fontSize={14}
                        fontFamily={"Inter"}
                        fontWeight={500}
                        marginBottom={"0.5rem"}
                      >
                        Years
                      </Typography>
                      <Field
                        name="experience_in_yrs"
                        component={CommonTextField}
                        padding={"10px"}
                        placeholder="Enter Year"
                        placeholderFontSize="14px"
                        value={values.experience_in_yrs}
                        border={
                          errors.experience_in_yrs &&
                          touched.experience_in_yrs &&
                          "1px solid #d32f2f"
                        }
                        onChange={(e) => {
                          setFieldValue(
                            "experience_in_yrs",
                            e.target.value.trim()
                          );
                        }}
                      />
                      {errors.experience_in_yrs &&
                        touched.experience_in_yrs && (
                          <Typography color="error" variant="body1">
                            {errors.experience_in_yrs}
                          </Typography>
                        )}
                    </Grid2>

                    <Grid2 display={"flex"} flexDirection={"column"}>
                      <Typography
                        variant="p"
                        color="#344054"
                        fontSize={14}
                        fontFamily={"Inter"}
                        fontWeight={500}
                        marginBottom={"0.5rem"}
                      >
                        Priority
                      </Typography>
                      <CommonGrid2></CommonGrid2>
                      <ToogleParentContainer>
                        <ToogleButton
                          alignment={values.bucket}
                          padding={"5px 10px"}
                          ToogleData={jobModifyData.priorityData}
                          handleChange={(event, newAlignment) => {
                            if (newAlignment !== null)
                              setValues((prevValues) => ({
                                ...prevValues,
                                bucket: newAlignment,
                                key: jobModifyData.priorityData.filter((item) =>
                                  item.value.includes(newAlignment)
                                )[0].key,
                              }));
                          }}
                          width="100%"
                          gap={"10px"}
                          border={"none !important"}
                          borderRadius={"5px !important"}
                          activeColor={"#FFFF"}
                          color={"#667085"}
                          activeBackgroundColor={"#7F56D9"}
                        />
                      </ToogleParentContainer>
                    </Grid2>

                    <Grid2 display={"flex"} flexDirection={"column"}>
                      <Typography
                        variant="p"
                        color="#344054"
                        fontSize={14}
                        fontFamily={"Inter"}
                        fontWeight={500}
                        marginBottom={"0.5rem"}
                      >
                        Skill Type
                      </Typography>
                      <CommonGrid2></CommonGrid2>
                      <ToogleParentContainer>
                        <ToogleButton
                          alignment={values.type}
                          padding={"5px 10px"}
                          ToogleData={jobModifyData.skillType}
                          handleChange={(event, newAlignment) => {
                            if (newAlignment !== null) {
                              setFieldValue("type", newAlignment);
                              setFieldValue("expertise_level", "");
                            }
                          }}
                          width="100%"
                          gap={"10px"}
                          border={"none !important"}
                          borderRadius={"5px !important"}
                          activeColor={"#FFFF"}
                          color={"#667085"}
                          activeBackgroundColor={"#7F56D9"}
                        />
                      </ToogleParentContainer>
                    </Grid2>

                    <CommonButton
                      variant="contained"
                      color="white"
                      background="#7F56D9"
                      fontWeight="600"
                      value={"Add Skill"}
                      padding="8px"
                      type="submit"
                      disabled={buttonLoading}
                      loading={buttonLoading}
                    />
                  </Grid2>
                </DialogContent>
              </Form>
            );
          }}
        </Formik>
      </Dialog>
    </>
  );
};

export default AddNewSkillDialog;

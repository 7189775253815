import { HARIZON } from "../Action";

const intialState = {
  harizonAnalytics: {},
};
const HarizonReducer = (state = intialState, action) => {
  switch (action.type) {
    case HARIZON.harizonAnalytics:
      return { ...state, harizonAnalytics: action.data };
    default:
      return state;
  }
};
export default HarizonReducer;

export default function JobStarIco() {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 10C0 4.47715 4.47715 0 10 0H10.7021C16.225 0 20.7021 4.47715 20.7021 10C20.7021 15.5228 16.225 20 10.7021 20H10C4.47715 20 0 15.5228 0 10Z"
        fill="#EBF8FF"
      />
      <path
        d="M9.11771 4.65378C9.49421 3.94805 10.5058 3.94805 10.8823 4.65378L12.2921 7.29632C12.3854 7.4713 12.5287 7.61457 12.7037 7.70791L15.3462 9.11771C16.0519 9.49421 16.0519 10.5058 15.3462 10.8823L12.7037 12.2921C12.5287 12.3854 12.3854 12.5287 12.2921 12.7037L10.8823 15.3462C10.5058 16.0519 9.49421 16.0519 9.11771 15.3462L7.70791 12.7037C7.61457 12.5287 7.4713 12.3854 7.29632 12.2921L4.65378 10.8823C3.94805 10.5058 3.94805 9.49421 4.65378 9.11771L7.29632 7.70791C7.4713 7.61457 7.61457 7.4713 7.70791 7.29632L9.11771 4.65378Z"
        fill="#0070F2"
      />
    </svg>
  );
}

import { Download, ExpandMore } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  Grid2,
  Link,
  Menu,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BookMarkIco from "../../../../assets/icons/BookMarkIco";
import BriefCaseIco from "../../../../assets/icons/BriefCaseIco";
import CalenderIco from "../../../../assets/icons/CalenderIco";
import ClipBoardCheck from "../../../../assets/icons/ClipBoardCheckIco";
import CopyIco from "../../../../assets/icons/CopyIco";
import DotsVerticalIco from "../../../../assets/icons/DotsVerticalIco";
import ScalesIco from "../../../../assets/icons/ScalesIco";
import ShareIco from "../../../../assets/icons/ShareIco";
import UsIco from "../../../../assets/icons/UsIco";
import XCircleCloseIco from "../../../../assets/icons/XCircleCloseIco";
import {
  DotColor,
  StyledFlexColumnView,
  StyledFlexRowView,
  SubTitleTypo,
  TitleTypo,
} from "../../../common-components/StyledComponents";
import { StyledSpaceBetweenBox } from "../../../quick-match/components/StyledComponent";

import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useSelector } from "react-redux";
import EyeIco from "../../../../assets/icons/EyeIco";
import CommonButton from "../../../../component/Button";
import { HandleSkeletion } from "../../../../utils/constants";
import { CustomizeMenu } from "../../style";
import ScheduleInterview from "./ScheduleInterview";

function Profile({
  isReviewCandidateProfile,
  matchedData,
  filteredResumeTab,
  resumeId,
  handleDownloadResume,
  handleClickAddNote,
  isCandidatePerspectiveOpen,
  loading,
  handleSaveToCandidatehub,
  handleViewCandidate,
  handleView,
  menuViewLoader,
  downloadMenuLoader,
  handleShareClick,
  isRequestLoading,
  handleRequestInfo,
}) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElCompare, setAnchorElCompare] = React.useState(null);
  const [openScheduleInterview, setScheduleInterview] = useState(false);
  const open = Boolean(anchorEl);
  const openCompare = Boolean(anchorElCompare);

  const [candidateProfileValue, setCandidateProfileValue] =
    useState("In Progress");
  const resumeIntelligence = useSelector(
    (state) => state.resumeIntelligence.resumeIntelligenceUpload
  );
  const location = useLocation();
  const query = new URLSearchParams(useLocation().search);
  const dataLoading = loading;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeScheduleInterview = () => {
    setScheduleInterview(true);
  };
  const handleCloseScheduleInterview = () => {
    setScheduleInterview(false);
  };

  const handleCloseCompare = () => {
    setAnchorElCompare(null);
  };
  const handleClickCompare = (event) => {
    setAnchorElCompare(event.currentTarget);
  };
  const statusOptions = [
    { label: "Hired", color: "#17B26A" }, // Green
    { label: "In Progress", color: "#9E77ED" }, // Purple
    { label: "Rejected", color: "#F04438" }, // Red
  ];

  const handleCompareNavigate = (item) => {
    navigate(
      `/jobs/candidate/compare/${resumeId}/${item?.id}?title=${query.get(
        "title"
      )}`
    );
  };

  const menuItems = [
    {
      label: "Share",
      icon: <ShareIco />,
      onClick: () => {
        handleShareClick();
        handleClose();
      },
    },
    {
      label: "Compare",
      icon: <CalenderIco />,
      onClick: handleClickCompare,
      ariaControls: open ? "basic-menu" : undefined,
      ariaHasPopup: "true",
      ariaExpanded: open ? "true" : undefined,
    },
    {
      label: "Add Note",
      icon: <ClipBoardCheck />,
      onClick: () => {
        handleClickAddNote();
        handleClose();
      },
    },
    {
      label: "Download",
      disabled: downloadMenuLoader,
      icon: downloadMenuLoader ? (
        <CircularProgress
          size={20}
          thickness={5}
          style={{ color: "#667085" }}
        />
      ) : (
        <Download sx={{ fontSize: "16px" }} />
      ),
      onClick: handleDownloadResume,
    },
    ...(matchedData?.temp_rice_created
      ? [
          {
            label: matchedData?.rice_created
              ? "View Profile"
              : "Save to Candidate Hub",
            icon: menuViewLoader ? (
              <CircularProgress
                size={20}
                thickness={5}
                style={{ color: "#667085" }}
              />
            ) : (
              <EyeIco size={"16px"} />
            ),
            disabled: menuViewLoader,
            onClick: async () => {
              if (matchedData?.rice_created) {
                navigate(
                  `/candidates/candidate-view?view-id=${matchedData.resume_id}`
                );
              } else if (matchedData?.duplication_present) {
                handleClose();
                handleViewCandidate();
              } else {
                await handleView();
                handleClose();
              }
            },
          },
        ]
      : []),
    ...(matchedData?.coversheet_added === true
      ? [
          {
            label: "Cover Sheet",
            icon: <ClipBoardCheck />,
            onClick: () =>
              navigate(
                `/candidates/candidate-perspective?id=${resumeId}&view_resume=true`
              ),
          },
        ]
      : []),
  ];

  const reviewCandidateProfileMenuItems = [
    {
      label: "Save to Candidate Hub",
      disabled: query.get("resume") === "parse" ? false : true,
      icon: <BookMarkIco />,
      onClick: () => {
        handleClose();
        handleSaveToCandidatehub();
      },
    },
    {
      label: "Match to Active Jobs",
      icon: <BriefCaseIco />,
      disabled: true,
      onClick: handleClose,
    },
    {
      label: "Close",
      icon: <XCircleCloseIco />,
      onClick: handleClose,
    },

    {
      label: "Schedule Interview",
      disabled: true,
      icon: <ClipBoardCheck />,
      onClick: handleClose,
    },
    {
      label: "Compare",
      disabled: true,
      icon: <ScalesIco />,
      onClick: handleClose,
    },
    {
      label: "Download",
      disabled:
        window.location.search.includes("resume=parse") || downloadMenuLoader,
      icon: downloadMenuLoader ? (
        <CircularProgress
          size={20}
          thickness={5}
          style={{ color: "#667085" }}
        />
      ) : (
        <Download sx={{ fontSize: "16px" }} />
      ),
      onClick: handleDownloadResume,
    },
  ];

  const gridData = [
    { title: "Title", contentKey: matchedData?.inferred_title || "-" },
    {
      title: "Rate",
      contentKey: matchedData?.pay_rate || matchedData?.rate || "-",
    },
    {
      title: "Location",
      contentKey: matchedData?.location || "-",
      icon: <UsIco />,
    },
    {
      title: "Website",
      website: matchedData?.website || "-",
      isLink: true,
    },
  ];

  const reviewGridData = [
    { title: "Visa Status", contentKey: matchedData?.visa || "-" },
    { title: "Seniority", contentKey: matchedData?.seniority_level || "-" },
    {
      title: "Occupational Classification",
      contentKey: matchedData?.occupational_classification || "-",
    },
    { title: "Industry Experience", contentKey: matchedData?.industry || "-" },
  ];

  return (
    <Grid2 container columnSpacing={4} rowSpacing={dataLoading ? 2 : 4}>
      {!query.get("view-id") && (
        <Grid2 size={12}>
          <Divider />
        </Grid2>
      )}
      {!isCandidatePerspectiveOpen && (
        <Grid2 size={12}>
          <StyledSpaceBetweenBox>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Avatar
                alt={matchedData?.candidate_name || "Avatar"}
                src={matchedData?.avatar_url}
                sx={{
                  width: 64,
                  height: 64,
                  backgroundColor: "#F2F4F7",
                  color: "#667085",
                  border: "1px solid #D0D5DD",
                  fontFamily: "Inter",
                  fontWeight: 600,
                  fontSize: "24px",
                }}
              >
                {dataLoading ? (
                  <HandleSkeletion height={35} width={30} />
                ) : (
                  !matchedData?.avatar_url &&
                  matchedData?.candidate_name?.[0]?.toUpperCase()
                )}
              </Avatar>
              <Box>
                <StyledFlexRowView>
                  <Typography fontSize={"24px"} fontWeight={600}>
                    {dataLoading ? (
                      <HandleSkeletion height={35} width={300} />
                    ) : (
                      matchedData?.candidate_name
                    )}
                  </Typography>
                  {isReviewCandidateProfile ? null : dataLoading ? (
                    <HandleSkeletion height={35} width={80} />
                  ) : (
                    <FormControl>
                      <Select
                        value={candidateProfileValue}
                        onChange={(e) => {
                          setCandidateProfileValue(e.target.value);
                        }}
                        displayEmpty
                        renderValue={(selected) => {
                          const selectedOption = statusOptions.find(
                            (option) => option.label === selected
                          );
                          return (
                            <Box display={"flex"} alignItems={"center"}>
                              <DotColor
                                color={selectedOption.color}
                                marginRight="8px"
                              />
                              <Typography>{selectedOption.label}</Typography>
                            </Box>
                          );
                        }}
                        IconComponent={ExpandMore}
                        sx={{
                          "& .MuiSelect-select": {
                            paddingLeft: "10px !important",
                            paddingTop: "1px !important",
                            paddingBottom: "1px !important",
                            display: "flex",
                            alignItems: "center",
                            borderRadius: "6px",
                            paddingRight: "25px !important",
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#D0D5DD",
                            boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                          },
                          "& .MuiSelect-icon": {
                            fontSize: "14px",
                          },
                        }}
                      >
                        {statusOptions.map((option) => (
                          <MenuItem key={option.label} value={option.label}>
                            <Box display={"flex"} alignItems={"center"}>
                              {option.label}
                            </Box>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </StyledFlexRowView>

                {dataLoading ? (
                  <HandleSkeletion height={35} width={200} />
                ) : (
                  <>
                    {matchedData?.email ? (
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Link
                          href={`mailto:${matchedData?.email}`}
                          underline="none"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            color: "#7F56D9",
                            fontSize: "16px",
                            fontFamily: "Inter",
                          }}
                        >
                          {matchedData?.email}
                        </Link>
                        <CopyIco />
                      </Stack>
                    ) : null}
                  </>
                )}
              </Box>
            </Stack>

            {dataLoading ? (
              <HandleSkeletion height={100} width={200} />
            ) : (
              <StyledFlexRowView gap={"1.5rem"}>
                {isReviewCandidateProfile ? null : (
                  <CommonButton
                    value={"Schedule Interview"}
                    padding={"10px 14px"}
                    variant="outlined"
                    size="small"
                    color="rgb(127, 86, 217)"
                    borderRadius="8px"
                    fontWeight={600}
                    border="rgb(127, 86, 217)"
                    onClick={handleChangeScheduleInterview}
                    disabled={!matchedData?.rice_created}
                  />
                )}
                <CommonButton
                  value={"Request Info"}
                  padding={"10px 14px"}
                  variant="outlined"
                  color={"#344054"}
                  border={"#D0D5DD"}
                  fontWeight={"600"}
                  onClick={handleRequestInfo}
                  loading={isRequestLoading}
                  disabled={
                    !location.pathname.includes("jobs-view") ||
                    isRequestLoading ||
                    !matchedData?.rice_created
                  }
                />

                <div>
                  <CommonButton
                    value={<DotsVerticalIco />}
                    padding={"10px 14px"}
                    variant="outlined"
                    color={"#344054"}
                    border={"#D0D5DD"}
                    fontWeight={"600"}
                    svg={true}
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                    minWidth={"20px !important"}
                  />

                  <CustomizeMenu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    {(isReviewCandidateProfile
                      ? reviewCandidateProfileMenuItems
                      : menuItems
                    ).map((item, index) => (
                      <MenuItem
                        key={index}
                        onClick={item.disabled ? () => {} : item.onClick}
                        aria-controls={item.ariaControls}
                        aria-haspopup={item.ariaHasPopup}
                        aria-expanded={item.ariaExpanded}
                        style={{ opacity: item.disabled ? 0.5 : 1 }}
                        disabled={item?.disabled}
                      >
                        <StyledFlexRowView>
                          {item.icon}
                          <Typography>{item.label}</Typography>
                        </StyledFlexRowView>
                      </MenuItem>
                    ))}
                  </CustomizeMenu>

                  <Menu
                    id="basic-menu"
                    anchorEl={anchorElCompare}
                    open={openCompare}
                    onClose={handleCloseCompare}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    {filteredResumeTab?.map((item, imdex) => (
                      <MenuItem
                        sx={{ marginTop: "10px" }}
                        onClick={() => handleCompareNavigate(item)}
                      >
                        <>
                          <ListItemIcon>
                            <Avatar
                              alt={"a"}
                              src="https://mui.com/static/images/avatar/1.jpg"
                              sx={{ width: 24, height: 24 }}
                            />
                          </ListItemIcon>
                          <ListItemText>
                            <Typography>{item?.name}</Typography>
                          </ListItemText>
                          <Chip
                            label={`${item?.score}%`}
                            variant="outlined"
                            size="small"
                            sx={{
                              paddingX: "5px",
                              paddingY: "1px",
                              marginLeft: "30px",
                            }}
                          />
                        </>
                      </MenuItem>
                    ))}
                  </Menu>
                </div>
              </StyledFlexRowView>
            )}
          </StyledSpaceBetweenBox>
        </Grid2>
      )}
      {gridData.map((item) => (
        <Grid2 size={3} key={item.id}>
          {dataLoading ? (
            <HandleSkeletion height={50} />
          ) : (
            <StyledFlexColumnView gap={"6px"}>
              {item.title && <TitleTypo>{item.title}</TitleTypo>}

              {item.contentKey && (
                <SubTitleTypo>{item.contentKey}</SubTitleTypo>
              )}

              {item.isLink && (
                <SubTitleTypo fontSize={"15px"}>{item.website}</SubTitleTypo>
              )}
            </StyledFlexColumnView>
          )}
        </Grid2>
      ))}
      {isReviewCandidateProfile &&
        reviewGridData.map((item) => (
          <Grid2 size={3}>
            {dataLoading ? (
              <HandleSkeletion height={50} />
            ) : (
              <StyledFlexColumnView gap={"6px"}>
                <TitleTypo>{item.title}</TitleTypo>
                <SubTitleTypo>{item.contentKey}</SubTitleTypo>
              </StyledFlexColumnView>
            )}
          </Grid2>
        ))}
      {!isCandidatePerspectiveOpen && (
        <Grid2 size={12}>
          <Divider />
        </Grid2>
      )}
      <ScheduleInterview
        open={openScheduleInterview}
        onClose={handleCloseScheduleInterview}
        onApply={() => {
          handleCloseScheduleInterview();
        }}
      />
    </Grid2>
  );
}

export default Profile;

import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import {
  Button,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React from "react";

const StyledTextField = styled(TextField)(
  ({
    padding,
    borderRadius,
    color,
    placeholderFontSize,
    border,
    error,
    width,
    fontSize,
    labelShrink,
    placeholderFontWeight,
    backgroundColor,
    customStyle,
    flex,
    paddingRight,
    fontWeight,
    input_color,
    multiline,
  }) => ({
    flex: flex,
    "& input.Mui-readOnly": {
      borderRight: "1px solid #D0D5DD",
    },
    "& label.MuiFormLabel-root": {
      transform: `translate(14px, ${labelShrink}) scale(1)`,
    },
    "& label.Mui-focused": {
      transform: `translate(14px, -${labelShrink}) scale(0.75)`,
    },
    "& label.MuiFormLabel-filled ": {
      transform: `translate(14px, -${labelShrink}) scale(0.75)`,
    },

    "& .MuiInputBase-root": {
      background: backgroundColor,
      borderRadius: borderRadius || "8px",
      paddingRight: paddingRight,
    },
    "& .MuiInputBase-input": {
      padding: padding || "6px 10px",
      fontSize: fontSize || "16px",
      color: input_color,
      fontWeight: fontWeight,
      width: width,
      "&::placeholder": {
        color: error ? "#d32f2f" : color || "#667085",
        opacity: 1,
        fontSize: placeholderFontSize,
        fontWeight: placeholderFontWeight,
      },
    },
    "& .MuiInputBase-inputMultiline": {
      resize: multiline ? "none" : "auto",
    },
    "& .MuiInputAdornment-positionStart": {
      margin: "0px",
    },
    "& .MuiInputAdornment-root": {
      cursor: "pointer",
    },
    "& .MuiSelect-select": {
      fontSize: "14px",
    },
    "& .MuiFormHelperText-root": {
      margin: "3px 0px",
      fontWeight: 600,
    },
    "& fieldset": {
      border: border || "1px solid #D0D5DD",
      borderRadius: borderRadius || "6px",
    },
    "& .Mui-error .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1.4px",
    },
    ...customStyle,
  })
);

const CustomButton = styled(Button)(() => ({
  fontSize: "inherit",
  color: "#475467",
  padding: "5px",
}));

const CommonTextField = ({
  placeholder,
  fullWidth = true,
  variant = "outlined",
  startIcon,
  showSelect = false,
  menuItemData,
  handleCurrencyChange,
  currency,
  padding,
  calender = false,
  calenderIcon,
  value,
  readOnly = false,
  borderRadius,
  handleFileChange,
  color,
  placeholderFontSize,
  onChange,
  border,
  error,
  helperText,
  datePickerCalender,
  width,
  fontSize,
  label,
  labelShrink,
  placeholderFontWeight,
  handleIconClick,
  backgroundColor,
  disabled,
  customStyle,
  endIcon,
  flex,
  paddingRight,
  read,
  fontWeight,
  input_color,
  multiline = false,
  rows,
}) => {
  return calender ? (
    <LocalizationProvider>
      <DatePicker
        value={value}
        onChange={onChange}
        renderInput={(params) => (
          <StyledTextField
            {...params}
            placeholder={placeholder}
            variant={variant}
            fullWidth={fullWidth}
            padding={padding}
            borderRadius={borderRadius}
            color={color}
            placeholderFontWeight={placeholderFontWeight}
            placeholderFontSize={placeholderFontSize}
          />
        )}
      />
    </LocalizationProvider>
  ) : (
    <StyledTextField
      placeholder={placeholder}
      label={label}
      variant={variant}
      fullWidth={fullWidth}
      padding={padding}
      value={value}
      borderRadius={borderRadius}
      color={color}
      placeholderFontSize={placeholderFontSize}
      placeholderFontWeight={placeholderFontWeight}
      onChange={onChange}
      error={error}
      flex={flex}
      helperText={helperText}
      backgroundColor={backgroundColor}
      border={border}
      onKeyDown={onChange}
      width={width}
      fontSize={fontSize}
      labelShrink={labelShrink}
      disabled={disabled}
      customStyle={customStyle}
      paddingRight={paddingRight}
      fontWeight={fontWeight}
      input_color={input_color}
      multiline={multiline}
      rows={rows}
      InputProps={{
        readOnly: readOnly || read,
        startAdornment: startIcon && (
          <InputAdornment position="start">{startIcon}</InputAdornment>
        ),
        endAdornment: (
          <>
            {endIcon && (
              <InputAdornment position="end">{endIcon}</InputAdornment>
            )}
            {showSelect && (
              <InputAdornment position="end">
                <Select
                  value={currency}
                  onChange={handleCurrencyChange}
                  variant="standard"
                  disableUnderline
                  style={{ fontSize: "inherit" }}
                >
                  {menuItemData.map((item) => (
                    <MenuItem value={item.name}>{item.name}</MenuItem>
                  ))}
                </Select>
              </InputAdornment>
            )}
            {datePickerCalender && (
              <InputAdornment position="end" onClick={handleIconClick}>
                {calenderIcon}
              </InputAdornment>
            )}
            {readOnly && (
              <InputAdornment position="end">
                <input
                  type="file"
                  accept=""
                  style={{ display: "none" }}
                  id="upload-button"
                  onChange={handleFileChange}
                />
                <label htmlFor="upload-button">
                  <CustomButton
                    variant="text"
                    component="span"
                    startIcon={<FileUploadOutlinedIcon />}
                  >
                    Upload
                  </CustomButton>
                </label>
              </InputAdornment>
            )}
          </>
        ),
      }}
    />
  );
};

export default CommonTextField;

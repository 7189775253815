import { Skeleton } from "@mui/material";
import { DataFound } from "../modules/common-components/StyledComponents";
import NoData from "../assets/icons/NoData";
import FileExtensionIco from "../assets/icons/FileExtensionIco";

const fontFamily = ["Inter"].join(",");

const colors = {
  blue: {
    main: "#004E87",
    dark: "#005689",
    light: "#0065A1",
    100: "#F0F9FF",
    200: "#043F69",
    300: "#0B6AAF",
    400: "#165BAA",
    500: "#007AC3",
    600: "#F5F8FB",
    700: "#DDF1FF",
    750: "#D5DDF3",
    800: "#285E85",
    850: "#DEEEF7",
    900: "#E5EAEE",
    950: "#003A64",
    1000: "#F6F8FB",
  },
  green: {
    light: "#DDF6DB",
    main: "#76B729",
    dark: "#789F48",
    100: "#44740B",
    200: "#83B943",
    300: "#00A28E",
    400: "#1C1B1F",
    500: "#3C9A56",
    600: "#DDECD0",
    700: "#61A620",
    800: "#EAF5E7",
    900: "#008000",
    950: "#80c52b",
  },
  grey: {
    A100: "#6A6A6D",
    A200: "#A6A6A6",
    A400: "#F9F6F2",
    A700: "#E4E4E4",
    50: "#616167",
    100: "#6C6C6C",
    200: "#F6F6F6",
    250: "#D8D8D8",
    300: "#B9C1CC",
    350: "#DDDDDD",
    400: "#969696",
    450: "#0000001f",
    500: "#E6E6E6",
    600: "#D1D1D1",
    650: "#DFF2FF",
    700: "#BDBDBD",
    750: "#E0E0E0",
    800: "#E5E6EC",
    850: "#C1C1C1",
    900: "#B0B0B0",
    950: "#D9D9D9",
  },
  black: {
    main: "#1E1F21",
    light: "#49454F",
    dark: "#231C16",
    100: "#000000",
    200: "0A0A0A",
  },
  sea: {
    main: "#4A9F9A",
    dark: "#FBFBFB",
    light: "#F5FAFF",
    100: "#EFF4F9",
    200: "#DBE5ED",
    300: "#E5EDF1",
    400: "#5B35D5",
    500: "#ECE5F6",
    600: "#658EA7",
    700: "#1567a3",
  },
  red: {
    main: "#E05F62",
    dark: "#CF5A5A",
    light: "#FADCDC",
    100: "#A6361F",
    200: "#DB5A63",
    300: "#E55E5E",
    400: "#BB2F38",
    500: "#F9DFE0",
    600: "#FF0000",
  },
  sandal: {
    main: "#E05F62",
  },
  warning: {
    main: "#FA8C42",
    dark: "#F18C33",
    light: "#FFF1DE",
  },
  white: {
    main: "#fff",
  },
  yellow: {
    dark: "#FFAF49",
    light: "#CEA046",
  },
};

export { fontFamily, colors };

export const hexToRgba = (hex, alpha) => {
  let r = 0,
    g = 0,
    b = 0;

  // Check for 3-digit hex
  if (hex.length === 4) {
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
  }
  // Check for 6-digit hex
  else if (hex.length === 7) {
    r = parseInt(hex[1] + hex[2], 16);
    g = parseInt(hex[3] + hex[4], 16);
    b = parseInt(hex[5] + hex[6], 16);
  }

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const getColor = (score) => {
  let color;
  const roundedScore = Math.round(score);

  if (roundedScore <= 35) {
    color = "#7F56D9";
  } else if (roundedScore > 35 && roundedScore <= 70) {
    color = "#F79009";
  } else if (roundedScore > 70 && roundedScore <= 100) {
    color = "#17B26A";
  }

  return color;
};

export const HandleSkeletion = ({ height, width, marginRight }) => {
  return (
    <Skeleton
      height={height}
      width={width}
      style={{ marginRight: marginRight }}
    />
  );
};

export const ErrorFound = ({ title }) => {
  return (
    <DataFound>
      <NoData />
      {title}
    </DataFound>
  );
};

export const getFileIcon = (file) => {
  const { name, type } = file;

  let extension = "";

  if (type?.includes("pdf")) {
    extension = "PDF";
    return (
      <FileExtensionIco extension={extension} extensionColor={"#D92D20"} />
    );
  } else if (
    type?.includes("wordprocessingml.document") ||
    name?.endsWith(".docx")
  ) {
    extension = "DOCX";
    return (
      <FileExtensionIco extension={extension} extensionColor={"#155EEF"} />
    );
  } else if (type?.includes("msword") || name?.endsWith(".doc")) {
    extension = "DOC";
    return (
      <FileExtensionIco extension={extension} extensionColor={"#155EEF"} />
    );
  }

  return <FileExtensionIco extension={""} extensionColor={""} />;
};

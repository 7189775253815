import React from "react";

export default function IceCandidateHubIco({ color }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_737_41150)">
        <path
          d="M10 8.20664L1.08495 4.95563M10 8.20664L18.9178 4.96637M10 8.20664V18.9999M3.13498 16.6666L8.935 18.7416C9.3297 18.8828 9.527 18.9533 9.7294 18.9814C9.909 19.0062 10.091 19.0062 10.2706 18.9814C10.473 18.9533 10.6703 18.8828 11.065 18.7416L16.865 16.6666C17.6337 16.3916 18.018 16.2541 18.3018 16.0075C18.5523 15.7898 18.7459 15.5126 18.8651 15.2006C19 14.8473 19 14.4342 19 13.6079V6.39204C19 5.56578 19 5.15266 18.8651 4.79942C18.7459 4.48743 18.5523 4.21019 18.3018 3.99243C18.018 3.74588 17.6337 3.60838 16.865 3.33338L11.065 1.25845C10.6703 1.11726 10.473 1.04666 10.2706 1.01864C10.091 0.993787 9.909 0.993787 9.7294 1.01864C9.527 1.04666 9.3297 1.11726 8.935 1.25845L3.13498 3.33338C2.36629 3.60838 1.98195 3.74588 1.69824 3.99243C1.44766 4.21019 1.25414 4.48743 1.13495 4.79942C1 5.15266 1 5.56578 1 6.39204V13.6079C1 14.4342 1 14.8473 1.13495 15.2006C1.25414 15.5126 1.44766 15.7898 1.69824 16.0075C1.98195 16.2541 2.36629 16.3916 3.13498 16.6666Z"
          stroke="#667085"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_737_41150">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

function LocationIco() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 19C14.7223 16.2 17.4445 13.6928 17.4445 10.6C17.4445 7.50721 15.0069 5 12 5C8.99317 5 6.5556 7.50721 6.5556 10.6C6.5556 13.6928 9.27783 16.2 12 19Z" stroke="#A9B4BE" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12 12C12.8591 12 13.5555 11.3036 13.5555 10.4445C13.5555 9.58535 12.8591 8.8889 12 8.8889C11.1408 8.8889 10.4444 9.58535 10.4444 10.4445C10.4444 11.3036 11.1408 12 12 12Z" stroke="#A9B4BE" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
}

export default LocationIco;

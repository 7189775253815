import { Grid2 } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  addDameNotes,
  deleteDameNote,
  DownloadResume,
  getDameNotes,
  shareCoverSheet,
  updateDameNote,
} from "../../../api/Api";
import {
  DELETE,
  GET,
  GETDOWNLOAD,
  POST,
  PUT,
} from "../../../redux/axios/Axios";
import AddUpdateNote from "../../common-components/AddUpdateNote";
import CommonAccordion from "../../common-components/CommonAccordion";
import DeleteNote from "../../common-components/DeleteNote";
import Interactions from "../jobs-summary/components/Interactions";
import Experience from "./components/Experience";
import MatchingSummary from "./components/MatchingSummary";
import Notes from "./components/Notes";
import Profile from "./components/Profile";
import Recommendations from "./components/Recommendations";
import StrengthWeak from "./components/StrengthWeak";
import DialogCandidateHub from "./components/DialogCandidateHub";
import { JobJobShare, JobResumeDuplicate } from "../../../redux/action/Job";
import { CommonGrid2 } from "../../common-components/StyledComponents";
import CommonButton from "../../../component/Button";
import CandidateHub from "../../../assets/icons/CandidateHub";
import SnackBar from "../../../component/SnackBar";
import ShareQuickMatch from "../../quick-match/components/ShareQuickMatch";

function JobsCandidate({
  matchedData,
  resumeId,
  resumeLoader,
  filteredResumeTab,
  fetchResumeData,
}) {
  const [noteList, setNoteList] = useState([
    { note: "", interaction: "Email", added_by: "username" },
  ]);
  console.log(matchedData,'matchedData')
  const query = new URLSearchParams(useLocation().search);
  const queryValue = query?.get("value");
  const navigate = useNavigate();
  const [openNoteDialog, setOpenNoteDialog] = useState(false);
  const [deleteNoteDialogOpen, setDeleteNoteDialogOpen] = useState(false);
  const [noteId, setNoteId] = useState("");
  const [notesTableList, setNotesTableList] = useState([]);
  const [addNoteLoading, setAddNoteLoading] = useState(false);
  const [notesTableListLoading, setNotesTableListLoading] = useState(false);
  const [viewResume, setViewResume] = useState({
    open: false,
    disabled: false,
    duplicateLoading: false,
    replacedLoading: false,
  });
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [menuViewLoader, setMenuViewLoader] = useState(false);
  const [downloadMenuLoader, setDownloadMenuLoader] = useState(false);
  const [shareDialogOpen, setShareDialogOpen] = useState({
    open: false,
    loading: false,
  });
  const [isRequestLoading, setIsRequestLoading] = useState(false);

  const handleClickAddNote = () => {
    setOpenNoteDialog(true);
  };

  const handleCloseNoteDialog = () => {
    setNoteId("");
    setOpenNoteDialog(false);
    setNoteList([{ note: "", interaction: "Email", added_by: "username" }]);
  };

  const handleDeleteNote = (row) => {
    setDeleteNoteDialogOpen(true);
    setNoteId(row?.id);
    setNoteList([
      {
        note: row?.note,
        interaction: row?.interaction,
        added_by: row?.added_by,
        created_at: row?.created_at,
      },
    ]);
  };

  const handleDeleteNoteDialogClose = () => {
    setDeleteNoteDialogOpen(false);
    setNoteId("");
    setNoteList([{ note: "", interaction: "Email", added_by: "username" }]);
  };

  const handleUpdateNote = (row) => {
    if (row?.id) {
      setNoteId(row?.id);
      setOpenNoteDialog(true);
      setNoteList([
        {
          note: row?.note,
          interaction: row?.interaction,
          added_by: row?.added_by,
        },
      ]);
    }
  };

  const fetchNotesTableList = async (jobId) => {
    setNotesTableListLoading(true);
    const res = await GET(getDameNotes(jobId));
    if (res) {
      setNotesTableList(res?.data);
      setNotesTableListLoading(false);
    }
  };

  const handleCreateNote = async () => {
    setAddNoteLoading(true);
    if (resumeId) {
      const payload = noteList;
      if (noteId) {
        const res = await PUT(updateDameNote(noteId), noteList[0]);
        if (res?.success) {
          console.log(res?.data?.success, "tr");
          setSnackbarState({
            open: true,
            severity: "success",
            message: res?.message,
          });
        } else {
        }
      } else {
        const res = await POST(addDameNotes(resumeId), payload);
        console.log(res?.data, "note");
        if (res?.success) {
          console.log(res?.data?.success, "tr");
          setSnackbarState({
            open: true,
            severity: "success",
            message: res?.message,
          });
        } else {
        }
      }
      setAddNoteLoading(false);
      fetchNotesTableList(resumeId);
      setOpenNoteDialog(false);
      setNoteList([{ note: "", interaction: "Email", added_by: "username" }]);
    }
  };

  const handleDeleteNoteById = async (row) => {
    const res = await DELETE(deleteDameNote(noteId));
    console.log(res?.data, "note");
    if (res?.success) {
      console.log(res?.data?.success, "tr");
      setSnackbarState({
        open: true,
        severity: "success",
        message: res?.message,
      });
      fetchNotesTableList(resumeId);
      setNoteList([{ note: "", interaction: "Email", added_by: "username" }]);
      setDeleteNoteDialogOpen(false);
      setNoteId("");
    } else {
    }
  };

  useEffect(() => {
    fetchNotesTableList(resumeId);
  }, [resumeId]);

  const handleNoteTextChange = (index, e) => {
    const newNoteList = [...noteList];
    newNoteList[index].note = e.target.value;
    setNoteList(newNoteList);
  };

  const handleDownloadResume = () => {
    if (resumeId) {
      setDownloadMenuLoader(true);
      GETDOWNLOAD(DownloadResume(resumeId), {
        params: { type: "match_resume" },
        responseType: "blob",
      })
        .then((response) => {
          const contentType = response.headers["content-type"];
          const extension =
            contentType === "application/pdf"
              ? "pdf"
              : contentType ===
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              ? "docx"
              : "doc";

          const fileName = `${matchedData?.file_name}`;

          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: contentType })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
          setDownloadMenuLoader(false);
        })
        .catch((error) => {
          if (error.response && error.response.data instanceof Blob) {
            const reader = new FileReader();
            reader.onload = () => {
              const responseText = reader.result;
              try {
                const json = JSON.parse(responseText);
                const errorMessage =
                  json.error || "An unexpected error occurred.";
                setSnackbarState({
                  open: true,
                  severity: "error",
                  message: errorMessage,
                });
              } catch (e) {
                setSnackbarState({
                  open: true,
                  severity: "error",
                  message:
                    "An unexpected error occurred while parsing the error message.",
                });
              }
            };
            reader.readAsText(error.response.data);
          } else {
            setSnackbarState({
              open: true,
              severity: "error",
              message: "An unexpected error occurred.",
            });
          }
          setDownloadMenuLoader(false);
        });
    } else {
      setSnackbarState({
        open: true,
        severity: "error",
        message: "Resume was not parsed successfully. Unable to download.",
      });
      setDownloadMenuLoader(false);
    }
  };

  const handleScoreChange = (label) => {
    fetchResumeData(resumeId, queryValue - 2, "", { spider_key: label });
  };

  const handleDuplicate = async (action) => {
    let params = {
      action: action,
      match_resume_id: matchedData.id,
      resume_id: matchedData.resume_id,
    };
    setViewResume((prev) => ({
      ...prev,
      disabled: true,
      duplicateLoading: action === "duplicate" ? true : false,
      replacedLoading: action === "replaced" ? true : false,
    }));
    try {
      const data = await JobResumeDuplicate(params);
      setSnackbarState({
        open: true,
        severity: "success",
        message: data?.message,
      });
      handleViewCandidateClose();
      navigate(`/candidates/candidate-view?view-id=${data?.data?.id}`);
    } catch (error) {
      setSnackbarState({
        open: true,
        severity: "error",
        message: error.message,
      });
      setViewResume((prev) => ({
        ...prev,
        disabled: false,
        duplicateLoading: false,
        replacedLoading: false,
      }));
    }
  };

  const FooterViewResume = () => (
    <CommonGrid2 display={"flex"} gap={"1rem"}>
      <CommonButton
        value={"Duplicate"}
        variant="outlined"
        width={"100%"}
        padding={"10px"}
        marginTop={"2rem"}
        fontWeight={"700"}
        fontSize={"15px"}
        color="#344054"
        border="#D0D5DD"
        disabled={viewResume.disabled}
        loading={viewResume.duplicateLoading}
        onClick={() => handleDuplicate("duplicate")}
      />
      <CommonButton
        value={"Replace Existing"}
        width={"100%"}
        padding={"10px"}
        marginTop={"2rem"}
        fontWeight={"700"}
        fontSize={"15px"}
        disabled={viewResume.disabled}
        loading={viewResume.replacedLoading}
        onClick={() => handleDuplicate("replaced")}
      />
    </CommonGrid2>
  );

  const handleViewCandidateClose = () => {
    setViewResume({
      open: false,
      disabled: false,
      duplicateLoading: false,
      replacedLoading: false,
    });
  };

  const handleViewCandidate = () => {
    setViewResume((prev) => ({
      ...prev,
      open: true,
      disabled: false,
      duplicateLoading: false,
      replacedLoading: false,
    }));
  };

  const handleView = async () => {
    setMenuViewLoader(true);
    let params = {
      match_resume_id: matchedData.id,
      resume_id: matchedData.resume_id,
    };

    try {
      const data = await JobResumeDuplicate(params);
      fetchResumeData(resumeId, queryValue - 2, "", "");
      setSnackbarState({
        open: true,
        severity: "success",
        message: data?.message,
      });

      // navigate(`/candidates/candidate-view?view-id=${data?.data?.id}`);
      setMenuViewLoader(false);
    } catch (error) {
      setSnackbarState({
        open: true,
        severity: "error",
        message: error.message,
      });
      setMenuViewLoader(false);
    }
  };

  const handleCloseValidation = () => {
    setSnackbarState((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const handleShareDialogClose = () => {
    setShareDialogOpen({ open: false, loading: false });
  };

  const handleShareInvite = async (shareData) => {
    setShareDialogOpen((prev) => ({
      ...prev,
      loading: true,
    }));
    try {
      let payload = {
        url: window.location.href,
        to_mail: shareData.map((item) => item.email),
        cc_mail: [],
      };
      const data = await JobJobShare(payload, "jobResume");
      setSnackbarState({
        open: true,
        message: data.message,
        severity: "success",
      });
      handleShareDialogClose();
    } catch (error) {
      setSnackbarState({
        open: true,
        message: error.message,
        severity: "error",
      });
      setShareDialogOpen((prev) => ({
        ...prev,
        loading: false,
      }));
    }
  };

  const handleShareClick = () => {
    setShareDialogOpen({ open: true, loading: false });
  };

  const handleRequestInfo = async () => {
    setIsRequestLoading(true);
    const url = process.env.REACT_APP_URL;

    try {
      const payload = {
        url: `${url}/candidates/candidate-perspective?id=${resumeId}`,
        match_resume_id: resumeId,
      };
      const response = await POST(shareCoverSheet(), payload);
      setSnackbarState({
        open: true,
        message: response.message || "Request successful!",
        severity: "success",
      });
      setIsRequestLoading(false);
    } catch (error) {
      setSnackbarState({
        open: true,
        message: error.message || "Request failed!",
        severity: "error",
      });
      setIsRequestLoading(false);
    }
  };

  return (
    <Grid2 container spacing={4}>
      <Grid2 size={12}>
        <Profile
          matchedData={matchedData}
          filteredResumeTab={filteredResumeTab}
          resumeId={resumeId}
          handleDownloadResume={handleDownloadResume}
          handleClickAddNote={handleClickAddNote}
          loading={resumeLoader}
          handleViewCandidate={handleViewCandidate}
          handleView={handleView}
          menuViewLoader={menuViewLoader}
          downloadMenuLoader={downloadMenuLoader}
          handleShareClick={handleShareClick}
          isRequestLoading={isRequestLoading}
          handleRequestInfo={handleRequestInfo}
        />
      </Grid2>
      <Grid2 size={12}>
        <CommonAccordion
          title={"Matching Summary"}
          children={
            <MatchingSummary
              matchedData={matchedData}
              handleScoreChange={handleScoreChange}
              loading={resumeLoader}
              spiderGraph={true}
            />
          }
        />
      </Grid2>
      <Grid2 size={12}>
        <CommonAccordion
          title={"Strengths and Weaknesses"}
          children={
            <StrengthWeak
              size={6}
              matchedData={matchedData}
              loading={resumeLoader}
            />
          }
        />
      </Grid2>
      <Grid2 size={12}>
        <CommonAccordion
          title={"Recomendations"}
          children={
            <Recommendations matchedData={matchedData} loading={resumeLoader} />
          }
        />
      </Grid2>
      <Grid2 size={12}>
        <CommonAccordion
          title={"Experience"}
          children={
            <Experience
              matchedData={matchedData?.experience}
              isLoading={resumeLoader}
            />
          }
        />
      </Grid2>
      <Grid2 size={12}>
        <CommonAccordion
          title={"Notes"}
          children={
            <Notes
              disabled={!matchedData?.rice_created}
              isShown={true}
              handleClickAddNote={handleClickAddNote}
              handleDeleteNoteClick={handleDeleteNote}
              handleUpdateNoteClick={handleUpdateNote}
              notesTableList={notesTableList}
              notesTableListLoading={notesTableListLoading || resumeLoader}
              isLoading={resumeLoader}
            />
          }
        />
      </Grid2>

      <Grid2 size={12}>
        <CommonAccordion
          title={"Interactions"}
          children={<Interactions tableLoader={resumeLoader} />}
        />
      </Grid2>

      <DeleteNote
        deleteNoteDialogOpen={deleteNoteDialogOpen}
        handleDeleteNoteDialogClose={handleDeleteNoteDialogClose}
        noteList={noteList}
        handleDeleteNoteById={handleDeleteNoteById}
      />
      <AddUpdateNote
        openNoteDialog={openNoteDialog}
        handleCloseNoteDialog={handleCloseNoteDialog}
        id={noteId}
        handleCreateNote={handleCreateNote}
        noteList={noteList}
        handleNoteTextChange={handleNoteTextChange}
        addNoteLoading={addNoteLoading}
      />
      <SnackBar
        snackbarState={snackbarState}
        handleCloseValidation={handleCloseValidation}
      />

      <ShareQuickMatch
        shareDialogOpen={shareDialogOpen.open}
        handleShareDialogClose={handleShareDialogClose}
        handleShareInvite={handleShareInvite}
        isLoading={shareDialogOpen.loading}
      />

      <DialogCandidateHub
        open={viewResume.open}
        handleClose={handleViewCandidateClose}
        icon={<CandidateHub margin={"10px 0px 0px 10px"} />}
        footer={<FooterViewResume />}
        title="Candidate Already Exists"
        titlepadding={"0px 0px 16px"}
        content={
          <>
            You are about to save a candidate under the name{" "}
            <strong style={{ fontWeight: 700 }}>
              {matchedData?.candidate_name}
            </strong>{" "}
            and the role{" "}
            <strong style={{ fontWeight: 700 }}>
              {matchedData?.inferred_title}
            </strong>{" "}
            in the Candidate Hub.
          </>
        }
      />
    </Grid2>
  );
}

export default JobsCandidate;

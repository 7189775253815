import {
  Box,
  Grid2,
  IconButton,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import DeleteIco from "../../../assets/icons/DeleteIco";
import CommonButton from "../../../component/Button";
import CommonLinearProgress from "../../../component/Linearprogress";
import {
  CommonGrid2,
  TableContainerBorder,
} from "../../common-components/StyledComponents";
import AddMoreCandidate from "./AddMoreCandidate";
import ShareQuickMatch from "./ShareQuickMatch";
import { getColor, HandleSkeletion } from "../../../utils/constants";

function ReviewMatch({
  handleAddMoreCandidateDragOver,
  handleAddMoreCandidateDragLeave,
  handleAddMoreCandidateDrop,
  handleAddMoreCandidateFileUpload,
  addMoreCandidateFiles,
  isAddMoreCandidateDragging,
  handleAddMoreCandidateDeleteFile,
  handleQmShare,
  qmShareDialogOpen,
  handleQmShareDialogClose,
  tableLoader,
  reviewMatchData,
  handleAddMoreCandidateClick,
  headerData,
  addMoreCandidateDialogOpen,
  handleAddMoreCandidate,
  handleAddMoreCandidateDialogClose,
  isLoading,
  handleViewJob,
  buttonDisabled,
  handleResumeDelete,
}) {
  return (
    <Box width={"600px"} margin={"auto"}>
      <Grid2 container spacing={2}>
        <CommonGrid2
          size={12}
          gap={"10px"}
          alignItems={"center"}
          justifyContent={"space-between"}
          flexDirection={"row"}
          display={"flex"}
        >
          <Typography fontSize={"16px"} color={"#101828"} fontWeight={700}>
            {"Results"}
          </Typography>
          <CommonButton
            variant="outlined"
            color={"#344054"}
            fontWeight="600"
            value="Share"
            disabled
            padding={"5px 0px"}
            onClick={handleQmShare}
            border={"#D0D5DD"}
          />
        </CommonGrid2>
        <Grid2 size={12}>
          <TableContainerBorder marginTop="1rem" fontSize={"16px"}>
            <Table>
              <TableHead>
                <TableRow>
                  {headerData.map((item, index) => {
                    return <TableCell key={index}>{item}</TableCell>;
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {reviewMatchData.map((row) => (
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row">
                      {tableLoader ? (
                        <HandleSkeletion height={30} />
                      ) : (
                        <Typography>{row.candidate_name}</Typography>
                      )}
                    </TableCell>
                    <TableCell align="left">
                      {tableLoader ? (
                        <HandleSkeletion height={30} />
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "10px",
                            alignItems: "center",
                          }}
                        >
                          <CommonLinearProgress
                            variant="determinate"
                            value={row.score}
                            flex={1}
                            height={"8px"}
                            borderRadius={"4px"}
                            barbackgroundColor={getColor(row.score)}
                          />
                          <Typography> {row.score}%</Typography>
                        </div>
                      )}
                    </TableCell>

                    <TableCell align="right">
                      {tableLoader ? (
                        <HandleSkeletion height={30} />
                      ) : (
                        <IconButton onClick={() => handleResumeDelete(row)}>
                          <DeleteIco />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainerBorder>
        </Grid2>

        <CommonGrid2 size={12} gap={"1rem"} marginTop={2} container>
          <CommonButton
            width={"100%"}
            variant="outlined"
            color="#344054"
            border="#D0D5DD"
            fontWeight="600"
            value="Add More Candidates"
            padding={"10px 0px"}
            onClick={handleAddMoreCandidate}
          />
          <CommonButton
            width={"100%"}
            variant="contained"
            color="white"
            background="#7F56D9"
            fontWeight="600"
            value=" View Job"
            padding={"10px 0px"}
            onClick={handleViewJob}
          />
        </CommonGrid2>
      </Grid2>

      <AddMoreCandidate
        addMoreCandidateDialogOpen={addMoreCandidateDialogOpen}
        handleAddMoreCandidateDialogClose={handleAddMoreCandidateDialogClose}
        handleAddMoreCandidateDragOver={handleAddMoreCandidateDragOver}
        handleAddMoreCandidateDragLeave={handleAddMoreCandidateDragLeave}
        handleAddMoreCandidateDrop={handleAddMoreCandidateDrop}
        handleAddMoreCandidateFileUpload={handleAddMoreCandidateFileUpload}
        addMoreCandidateFiles={addMoreCandidateFiles}
        isAddMoreCandidateDragging={isAddMoreCandidateDragging}
        handleAddMoreCandidateDeleteFile={handleAddMoreCandidateDeleteFile}
        handleAddMoreCandidateClick={handleAddMoreCandidateClick}
        isLoading={isLoading}
        buttonDisabled={buttonDisabled}
      />

      <ShareQuickMatch
        qmShareDialogOpen={qmShareDialogOpen}
        handleQmShareDialogClose={handleQmShareDialogClose}
      />
    </Box>
  );
}

export default ReviewMatch;

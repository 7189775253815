import { Grid2, Skeleton } from "@mui/material";
import React from "react";
import CommonTextField from "../../../../component/Input";
import { CommonGrid2 } from "../../../common-components/StyledComponents";

function References({ data, values, setFieldValue, loading }) {
  return (
    <CommonGrid2
      container
      spacing={2}
      gap={loading ? "0px" : "1rem"}
      display={"flex"}
      justifyContent={"space-between"}
    >
      {data.map((item, index) => {
        return loading ? (
          <Grid2 size={data.length - 1 === index ? 12 : 6}>
            <Skeleton height={100} />
          </Grid2>
        ) : (
          <CommonGrid2
            width={data.length - 1 === index ? "100%" : "auto"}
            xs={12}
            flex={data.length - 1 !== index && 1}
          >
            <Grid2
              item
              xs={12}
              display={"flex"}
              alignItems={"center"}
              width={data.length - 1 === index ? "100%" : "auto"}
            >
              <CommonTextField
                flex={0.3}
                disabled={true}
                border={"0.1px solid #D0D5DD"}
                value={item.title}
                padding={"12px"}
                borderRadius={"8px 0 0 8px"}
              />

              <CommonTextField
                flex={1}
                value={values[item.titleName]}
                onChange={(e) => {
                  setFieldValue(item.titleName, e.target.value);
                }}
                border={"0.1px solid #D0D5DD"}
                disabled={item.disabled}
                placeholder={item.placeholder}
                placeholderFontSize={item.placeholderFontSize}
                padding={"12px"}
                borderRadius={"0 8px 8px 0"}
                fontSize={"15px"}
              />
            </Grid2>
          </CommonGrid2>
        );
      })}
    </CommonGrid2>
  );
}

export default References;

import { Avatar, Box, Grid2, Typography } from "@mui/material";
import { Gauge, gaugeClasses } from "@mui/x-charts/Gauge";
import React from "react";
import AlertCircleIco from "../../../../assets/icons/AlertCircleIco";
import CheckIco from "../../../../assets/icons/CheckIco";
import { HandleSkeletion } from "../../../../utils/constants";
import { CustomPaper } from "../../../resume-intelligence/create-candidate-resume/components/Style";
import Comparison from "./Comparison";
import { CommonGrid2 } from "../../../common-components/StyledComponents";
import ErrorCodeIco from "../../../../assets/icons/ErrorCodeIco";

function SkillRating({ label, score, handleScoreChange, spiderGraph }) {
  const color = score >= 75 ? "#47CD89" : score >= 30 ? "#FDB022" : "red";

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      mx={1.5}
      sx={{ cursor: handleScoreChange === "" ? "auto" : "pointer" }}
      onClick={() => (handleScoreChange === "" ? {} : handleScoreChange(label))}
    >
      <Gauge
        width={90}
        height={90}
        value={score}
        cornerRadius="50%"
        text={({ value, valueMax }) => `${score}%`}
        sx={(theme) => ({
          [`& .${gaugeClasses.valueText}`]: {
            fontSize: spiderGraph ? 13 : 14,
            color: "#101828",
            fontWeight: 600,
          },
          [`& .${gaugeClasses.valueArc}`]: {
            fill: color,
          },
        })}
      />
      <Typography fontSize={"12px"} color={"#475467"} fontWeight={600}>
        {label}
      </Typography>
    </Box>
  );
}

function FeedbackCard({ icon, message, marginBottom }) {
  return (
    <CustomPaper
      variant="outlined"
      width="100%"
      padding={"12px"}
      marginBottom={marginBottom}
      display={"flex"}
      alignItems={"start"}
    >
      <Avatar
        sx={{
          bgcolor: "transparent",
          marginRight: "4px",
          height: "25px !important",
        }}
      >
        {icon}
      </Avatar>
      <Typography fontSize={"16px"} fontWeight={500} color={"#667085"}>
        {message}
      </Typography>
    </CustomPaper>
  );
}
function MatchingSummary({
  matchedData,
  handleScoreChange,
  loading,
  spiderGraph = false,
}) {
  return (
    <Box>
      <CustomPaper
        variant="outlined"
        width="100%"
        padding={"12px"}
        marginBottom={"16px"}
      >
        <Grid2
          container
          justifyContent="center"
          alignItems={"center"}
          spacing={2}
        >
          <CommonGrid2
            item
            display={"flex"}
            size={{ xs: 12, md: 5, sm: 12 }}
            container
            justifyContent={"center"}
            spacing={0}
            width={spiderGraph ? "auto" : "100% !important"}
          >
            {loading
              ? Array.from({ length: 5 }).map((_, index) => (
                  <HandleSkeletion
                    height={100}
                    width={100}
                    marginRight={"1rem"}
                  />
                ))
              : matchedData?.match_summary?.map((item, index) => (
                  <Grid2 item key={index}>
                    <SkillRating
                      label={item.name}
                      score={item.score}
                      handleScoreChange={handleScoreChange}
                      spiderGraph={spiderGraph}
                    />
                  </Grid2>
                ))}
          </CommonGrid2>
          {spiderGraph && (
            <Grid2 item size={{ xs: 12, md: 7, sm: 12 }}>
              <Comparison matchedData={matchedData} loading={loading} />
            </Grid2>
          )}
        </Grid2>
      </CustomPaper>
      <Typography
        fontSize={"20px"}
        fontWeight={600}
        color={"#101828"}
        marginBottom={"10px"}
      >
        {"Analysis"}
      </Typography>
      {loading ? (
        <HandleSkeletion height={100} width={"100%"} marginRight={"1rem"} />
      ) : (
        <FeedbackCard
          message={matchedData?.insights?.feedback?.analysis}
          icon={<CheckIco />}
          marginBottom="16px"
        />
      )}

      <Typography
        fontSize={"20px"}
        fontWeight={600}
        color={"#101828"}
        marginBottom={"10px"}
      >
        {"Red Flags"}
      </Typography>
      {loading ? (
        <HandleSkeletion height={100} width={"100%"} marginRight={"1rem"} />
      ) : (
        matchedData?.insights?.feedback?.red_flags?.map((item, index) => (
          <FeedbackCard
            key={index}
            message={item}
            icon={<AlertCircleIco />}
            marginBottom="10px"
          />
        ))
      )}

      {matchedData &&
        matchedData?.insights &&
        matchedData?.insights &&
        matchedData?.insights &&
        matchedData?.insights?.feedback &&
        matchedData?.insights &&
        matchedData?.insights?.feedback?.qm_errors && (
          <>
            <Typography
              fontSize={"20px"}
              fontWeight={600}
              color={"#101828"}
              marginBottom={"10px"}
              marginTop={"1rem"}
            >
              {"Data Error"}
            </Typography>

            {matchedData?.insights?.feedback?.qm_errors?.map((item, index) => (
              <CustomPaper
                variant="outlined"
                width="100%"
                padding={"12px"}
                marginBottom={"10px"}
                display={"flex"}
                alignItems={"start"}
                gap="14px"
              >
                <div>
                  <ErrorCodeIco />
                </div>
                <CommonGrid2
                  display={"flex"}
                  alignItems={"flex-start"}
                  gap={"4px"}
                  flexDirection={"column"}
                >
                  <Typography
                    fontSize={"16px"}
                    fontWeight={700}
                    color={"#D80027"}
                  >
                    Error Code : {item.code}
                  </Typography>

                  <Typography
                    fontSize={"14px"}
                    fontWeight={500}
                    color={"#00144A"}
                  >
                    {item.message}
                  </Typography>
                </CommonGrid2>
              </CustomPaper>
            ))}
          </>
        )}
    </Box>
  );
}

export default MatchingSummary;

import { URL } from "../../api/Api";
import { RESUMEINTELLIGENCE } from "../Action";
import { catchError, DELETE, GET, getToken, POST, PUT } from "../axios/Axios";

export const ResumeIntelligenceUpload = async (formData) => {
  try {
    const token = await getToken();
    const response = await fetch(`${URL}/resume/parse/`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });

    if (response.status === 401 || response.status === 403) {
      window.localStorage.clear();
      window.sessionStorage.clear();
      window.location.href = "/auth/login";
      return;
    } else if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response;
  } catch (error) {
    if (error.message.includes("401") || error.message.includes("403")) {
      window.localStorage.clear();
      window.sessionStorage.clear();
      window.location.href = "/auth/login";
    }
    throw error;
  }
};

export const ResumeIntelligenceUploadResume = (data) => {
  return {
    type: RESUMEINTELLIGENCE.resumeIntelligenceUpload,
    data: data,
  };
};

export const ResumeIntelligenceSave = (params) => {
  return async (dispatch) => {
    try {
      const response = await POST(`${URL}/resume/save/`, {}, params);
      dispatch({
        type: RESUMEINTELLIGENCE.resumeIntelligenceSave,
        data: response.data,
      });
      return response.data;
    } catch (error) {
      throw catchError(error);
    }
  };
};

export const ResumeIntelligenceFix = async (id) => {
  try {
    const response = await GET(`${URL}/resume/error/fix/${id}`, {});
    return response;
  } catch (error) {
    throw catchError(error);
  }
};

export const ResumeIntelligenceGETNote = async (id) => {
  try {
    const response = await GET(`${URL}/resume/notes/retrive/${id}/`, {});
    return response;
  } catch (error) {
    throw catchError(error);
  }
};

export const ResumeIntelligencePostNote = async (id, payload) => {
  try {
    const response = await POST(`${URL}/resume/notes/${id}/`, payload, {});
    return response;
  } catch (error) {
    throw catchError(error);
  }
};

export const ResumeIntelligencePutNote = async (id, payload) => {
  try {
    const response = await PUT(`${URL}/resume/notes/access/${id}`, payload, {});
    return response;
  } catch (error) {
    throw catchError(error);
  }
};

export const ResumeIntelligenceDeleteNote = async (id) => {
  try {
    const response = await DELETE(`${URL}/resume/notes/access/${id}`, {});
    return response;
  } catch (error) {
    throw catchError(error);
  }
};

import { styled } from "@mui/material";

export const ChatBotFooter = styled("div")(() => ({
  fontWeight: "600",
  fontSize: "12px",
  lineHeight: "16px",
  color: "rgba(0, 0, 0, 0.6)",
}));

export const WholePageOpacity = styled("div")(() => ({
  width: "100%",
  height: "100%",
  opacity: 0.6,
  top: "0%",
  background: "rgb(245 253 255)",
  zIndex: 9999,
  borderRadius: "10px",
  position: "absolute",
}));
export const WholePageEllipse = styled("div")(() => ({
  zIndex: 99999,
  position: "absolute",
  left: "40%",
  top: "42%",
}));

export const InnerContainer = styled("div")(({ right }) => ({
  padding: "1rem 0px 5px",
  right: "10px",
  right: right,
  // border: "1px solid",
  position: "relative",
  width: "400px",
  borderRadius: "10px",
  boxShadow: "1px 1px 5px 3px rgb(16 24 40 / 13%)",
  background: "#F5EEFF",
}));

export const CustomDiv = styled("div")(({ position, margin }) => ({
  position: position,
  margin: margin,
}));

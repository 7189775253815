function TalairoLogo({
  color,
  width = "100%",
  height = "auto",
  marginLeft = "0",
}) {
  return (
    <svg
      width={width}
      height={height}
      style={{ marginLeft }}
      viewBox="0 0 572 162"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.05127 136.187C89.2428 197.947 139.79 132.85 141.352 102.761L111.686 101.178C104.191 139.184 48.647 142.521 26.7878 115.6L8.05127 136.187Z"
        fill="url(#paint0_linear_737_41060)"
      />
      <ellipse
        cx="78.8978"
        cy="82.1739"
        rx="32.7889"
        ry="33.2554"
        fill="url(#paint1_radial_737_41060)"
      />
      <path
        d="M88.2656 20.4146C104.532 23.4143 108.471 32.8505 107.466 42.3513C106.142 54.8573 95.69 64.6649 83.5816 68.0615C82.1201 68.4715 80.7104 68.8007 79.3641 69.0607C70.8452 70.7061 61.9749 69.073 53.352 70.034C8.86229 74.9922 8.67147 111.888 14.8809 131.266C4.40126 123.295 0.943148 106.602 0.344526 92.7691C-0.0978484 82.547 1.86905 72.2974 6.09908 62.981C23.097 25.5445 57.5535 14.7511 88.2656 20.4146Z"
        fill="url(#paint2_linear_737_41060)"
      />
      <path
        d="M49.2316 99.5934C104.192 110.995 124.178 70.5609 127.3 48.9185C153.531 78.0565 141.353 113.846 131.984 128.098C124.178 117.013 110.125 105.928 92.9501 115.429C84.0608 120.347 74.6922 125.972 67.6349 130.331C61.6435 134.032 54.9645 136.994 47.954 136.326C38.5147 135.426 28.2537 131.111 27.3723 118.597C26.1232 100.86 41.4247 98.5377 49.2316 99.5934Z"
        fill="url(#paint3_linear_737_41060)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M434.762 80.978V134.527H449.8V80.978H434.762ZM322.256 60.2495V134.527H337.295V60.2495H322.256ZM240.359 63.9075V77.32H219.529V134.527H204.389V77.32H183.559V63.9075H240.359ZM287.447 80.978H301.368V134.527H287.447V128.633C283.586 133.206 277.997 135.847 270.986 135.847C255.541 135.847 244.364 123.756 244.364 107.803C244.364 91.7487 255.541 80.6731 270.986 80.6731C277.997 80.6731 283.586 83.1118 287.447 87.481V80.978ZM273.12 122.537C281.35 122.537 286.939 116.542 286.939 107.803C286.939 99.0646 281.35 92.968 273.12 92.968C265.093 92.968 259.301 99.2678 259.301 107.803C259.301 116.338 265.093 122.537 273.12 122.537ZM404.145 134.527L399.166 119.387H372.95L367.971 134.527H353.035L376.812 63.9075H395.203L418.98 134.527H404.145ZM395.101 107.092L386.058 79.7587L377.015 107.092H395.101ZM484.948 86.1601C488.606 82.2989 493.991 79.657 500.291 79.657V93.7809C491.146 93.7809 485.761 97.9469 485.761 105.06V134.527H470.722V80.978H484.948V86.1601ZM513.172 107.803C513.172 123.756 525.162 135.847 542.131 135.847C558.999 135.847 571.09 123.756 571.09 107.803C571.09 91.7487 558.999 79.7587 542.131 79.7587C525.162 79.7587 513.172 91.7487 513.172 107.803ZM556.052 107.701C556.052 116.338 550.362 122.435 542.233 122.435C533.901 122.435 528.312 116.338 528.312 107.803C528.312 99.1662 533.901 93.1712 542.233 93.1712C550.565 93.1712 556.052 99.1662 556.052 107.701Z"
        fill="url(#paint4_linear_737_41060)"
      />
      <path
        d="M417.489 21.0181C419.161 24.4103 421.911 27.2148 425.284 28.9253C421.911 30.6358 419.161 33.4404 417.489 36.8326C415.817 33.4404 413.067 30.6358 409.694 28.9253C413.067 27.2148 415.817 24.4103 417.489 21.0181Z"
        fill="url(#paint5_linear_737_41060)"
      />
      <path
        d="M441.488 0.0180661C443.16 3.41026 445.91 6.21485 449.283 7.92531C445.91 9.63577 443.16 12.4404 441.488 15.8326C439.816 12.4404 437.066 9.63577 433.693 7.92531C437.066 6.21485 439.816 3.41026 441.488 0.0180661Z"
        fill="url(#paint6_linear_737_41060)"
      />
      <path
        d="M444.735 39.8691C446.557 43.5668 449.53 46.5735 453.207 48.438L457.324 50.5258L453.207 52.6136C449.53 54.4781 446.557 57.4848 444.735 61.1825L442.651 65.41L440.567 61.1825C438.745 57.4848 435.772 54.4781 432.095 52.6136L427.978 50.5258L432.095 48.438C435.772 46.5735 438.745 43.5668 440.567 39.8691L442.651 35.6416L444.735 39.8691Z"
        fill="url(#paint7_linear_737_41060)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_737_41060"
          x1="107.979"
          y1="150.439"
          x2="52.7304"
          y2="106.86"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#7337A5" />
          <stop offset="0.5" stop-color="#5B2E80" />
          <stop offset="1" stop-color="#42245A" stop-opacity="0" />
          <stop offset="1" stop-color="#42245A" stop-opacity="0" />
        </linearGradient>
        <radialGradient
          id="paint1_radial_737_41060"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(78.8978 82.1739) rotate(90) scale(33.2554 32.7889)"
        >
          <stop stop-color="#8C45CA" />
          <stop offset="1" stop-color="#3D1C5B" />
        </radialGradient>
        <linearGradient
          id="paint2_linear_737_41060"
          x1="107.612"
          y1="75.1335"
          x2="0.245117"
          y2="75.1335"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.364" stop-color="#C59FE8" />
          <stop offset="0.794" stop-color="#873FC5" />
          <stop offset="1" stop-color="#5C347F" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_737_41060"
          x1="143.135"
          y1="92.7175"
          x2="27.3003"
          y2="92.7175"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#5C347F" />
          <stop offset="0.199079" stop-color="#8435C8" />
          <stop offset="0.724" stop-color="#C59FE8" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_737_41060"
          x1="357.916"
          y1="60.0588"
          x2="357.916"
          y2="185.868"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#B18DD0" />
          <stop offset="1" stop-color="#6B4092" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_737_41060"
          x1="421.797"
          y1="28.1055"
          x2="407.887"
          y2="28.1055"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#B18DD0" />
          <stop offset="1" stop-color="#6B4092" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_737_41060"
          x1="445.797"
          y1="7.10545"
          x2="431.887"
          y2="7.10545"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#B18DD0" />
          <stop offset="1" stop-color="#6B4092" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_737_41060"
          x1="450.761"
          y1="48.9826"
          x2="424.577"
          y2="48.9826"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#B18DD0" />
          <stop offset="1" stop-color="#6B4092" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default TalairoLogo;

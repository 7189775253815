import { URL } from "../../api/Api";
import { CHATBOT } from "../Action";
import { catchError, GET, POST } from "../axios/Axios";

export const ChatBotCall = (payload) => {
  return async (dispatch) => {
    try {
      const response = await POST(`${URL}/chatbot/chat/`, payload, {});
      dispatch({ type: CHATBOT.chatBot, data: response.data });
      return response.data;
    } catch (error) {
      throw catchError(error);
    }
  };
};

export const ChatBotGetCall = async (job_id) => {
  try {
    const response = await GET(`${URL}/chatbot/chat/messages/${job_id}`, {});
    return response;
  } catch (error) {
    throw catchError(error);
  }
};

export const CandidateChatBot = (payload) => {
  return async (dispatch) => {
    try {
      const response = await POST(`${URL}/chatbot/chat/resume/`, payload, {});
      dispatch({ type: CHATBOT.candidatechatBot, data: response.data });
      return response.data;
    } catch (error) {
      throw catchError(error);
    }
  };
};

export const CandidateChatBotGetCall = async (job_id) => {
  try {
    const response = await GET(
      `${URL}/chatbot/chat/resume/messages/${job_id}`,
      {}
    );
    return response;
  } catch (error) {
    throw catchError(error);
  }
};

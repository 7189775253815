import { Box, Typography } from "@mui/material";
import React from "react";
import { CustomPaper } from "../../../resume-intelligence/create-candidate-resume/components/Style";
import { HandleSkeletion } from "../../../../utils/constants";

function Recommendations({ matchedData, loading }) {
  return loading ? (
    <HandleSkeletion height={100} width={"100%"} />
  ) : (
    <Box marginTop={"0.5rem"}>
      {matchedData?.insights?.feedback?.recommendations?.map((item, index) => (
        <CustomPaper
          variant="outlined"
          width="100%"
          padding={"12px"}
          marginBottom={"8px"}
          display={"flex"}
          alignItems={"start"}
          key={index}
        >
          <Typography fontSize={"16px"} fontWeight={500} color={"#667085"}>
            {item}
          </Typography>
        </CustomPaper>
      ))}
    </Box>
  );
}

export default Recommendations;

import axios from "axios";

axios.interceptors.request.use(
  function (request) {
    const decryptToken = JSON.parse(localStorage.getItem("credential"))?.access;
    if (decryptToken) {
      request.headers = {
        ...request.headers,
        Authorization: `Bearer ${decryptToken}`,
        // 'Access-Control-Allow-Origin': '*',
      };
    }
    return request;
  },
  async function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  async function (successRes) {
    return successRes;
  },
  async function (error) {
    const { response } = error;
    if (response) {
      if (response.status === 401 || response.status === 403) {
        window.localStorage.clear();
        window.sessionStorage.clear();
        window.location.href = "/auth/login";
      }
    }
    return Promise.reject(error);
  }
);

export const GET = async (url, params) => {
  try {
    const param = params !== undefined ? params : {};
    const response = await axios.get(url, {
      params: {
        ...param,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GETDOWNLOAD = async (url, options = {}) => {
  try {
    const { params, ...axiosConfig } = options;

    const response = await axios.get(url, {
      params: params || {},
      ...axiosConfig,
    });
    return axiosConfig.responseType ? response : response.data;
  } catch (error) {
    throw error;
  }
};

export const POST = async (url, data, params, response, error) => {
  try {
    const param = params !== undefined ? params : {};
    const response = await axios.post(url, data, {
      params: {
        ...param,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const PUT = async (url, data, params) => {
  try {
    const param = params !== undefined ? params : {};
    const response = await axios.put(url, data, {
      params: {
        ...param,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const DELETE = async (url, params) => {
  try {
    const param = params !== undefined ? params : {};
    const response = await axios.delete(url, {
      params: {
        ...param,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const catchError = (error) => {
  let message = "Expectation Failed";
  if (error) {
    if (error.message && error.message?.length !== 0) {
      message = error.message;
    } else if (
      error.response &&
      typeof error.response.data === "string" &&
      error.response.data?.length !== 0
    )
      message = error.response.data;
    else if (
      error.response &&
      error.response.data.message &&
      error.response.data.message.length !== 0
    )
      message = error.response.data.message;
    else if (
      error.response &&
      error.response.data.error &&
      error.response.data.error.length !== 0
    )
      message = error.response.data.error;
  }

  let obj = {
    success: false,
    message: message,
    ...error?.response,
  };
  console.log(obj, "error Message");
  return {
    success: false,
    message: message,
    ...error?.response,
  };
};

export const getToken = async () => {
  try {
    const credentials = localStorage.getItem("credential");
    if (credentials) {
      const { access } = JSON.parse(credentials);
      return access;
    }
    throw new Error("No token found");
  } catch (error) {
    console.error("Error retrieving token:", error);
    throw error;
  }
};

function StarIco({ color }) {
    return (
        <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.77582 1.14891C6.86752 0.9631 7.13248 0.963097 7.22418 1.14891L8.44592 3.62442C8.70083 4.14092 9.19357 4.49891 9.76356 4.58174L12.4954 4.9787C12.7005 5.0085 12.7824 5.26049 12.634 5.40513L10.6572 7.33204C10.2447 7.73408 10.0565 8.31333 10.1539 8.88101L10.6206 11.6019C10.6556 11.8061 10.4412 11.9618 10.2578 11.8654L7.81435 10.5808C7.30453 10.3128 6.69547 10.3128 6.18565 10.5808L3.74218 11.8654C3.55878 11.9618 3.34442 11.8061 3.37945 11.6019L3.84611 8.88102C3.94347 8.31333 3.75526 7.73408 3.34281 7.33204L1.36601 5.40512C1.21763 5.26049 1.2995 5.0085 1.50456 4.9787L4.23644 4.58174C4.80644 4.49891 5.29917 4.14091 5.55408 3.62441L4.88153 3.29249L5.55408 3.62441L6.77582 1.14891Z"
                stroke={color || "#079455"} // Default color is green (#079455), if no color is passed
                strokeWidth="1.5"
            />
        </svg>
    );
}

export default StarIco;

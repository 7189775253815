import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { Skeleton, Switch, Tooltip, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { Field } from "formik";
import React, { useState } from "react";
import { AutocompleteText } from "../../../../component/AutoComplete";
import CommonTextField from "../../../../component/Input";
import DropDownSelect from "../../../../component/Select";
import { CommonGrid2 } from "../../../common-components/StyledComponents";
import DateRangePickerComp from "../../../jobs/common-card.js/DatePicker";
import { ErrorContent } from "../../../jobs/style";
import { CustomDatePicker } from "./style";

const MixedComponent = ({ editData, loading, marginTop }) => {
  const [fileName, setFileName] = useState("");

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
    }
  };

  // useEffect(() => {
  //   document.getElementsByClassName("MuiIconButton-edgeEnd")[0].innerHTML =
  //     "de";
  // });
  return (
    <CommonGrid2
      container
      columnSpacing={3}
      rowSpacing={loading ? 0 : 3}
      marginTop={marginTop}
    >
      {editData?.map((item, index) => {
        return (
          <CommonGrid2
            item
            size={{
              xs: 12,
              md: item.size ? 6 : 4,
              sm: 6,
            }}
            key={index + item.title}
            gap={"8px"}
            display={"flex"}
            flexDirection={item.component === "switch" ? "row" : "column"}
            justifyContent={"flex-start"}
          >
            {loading ? (
              <Skeleton height={100} />
            ) : (
              <>
                {item.component !== "input-select" && (
                  <CommonGrid2
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    width={"100%"}
                  >
                    <Tooltip arrow title={item.title} placement="top">
                      <Typography
                        variant="p"
                        color="#344054"
                        fontSize="16px"
                        textOverflow={"ellipsis"}
                        whiteSpace={"nowrap"}
                        overflow={"hidden"}
                      >
                        {item.title}
                      </Typography>
                    </Tooltip>

                    {item.type === "additional" && (
                      <Field name={item.toggleName}>
                        {({ field, form }) => {
                          const toggleValue =
                            form.values[item.toggleName] || false;
                          return (
                            <Switch
                              checked={toggleValue}
                              disabled={item.disabled}
                              onChange={(event) => {
                                form.setFieldValue(
                                  item.toggleName,
                                  event.target.checked
                                );
                              }}
                            />
                          );
                        }}
                      </Field>
                    )}
                  </CommonGrid2>
                )}
                {item.component === "input" && (
                  <Field name={item.titleName}>
                    {({ field, form }) => {
                      return (
                        <CommonTextField
                          {...field}
                          placeholder={
                            item?.placeholder ? item?.placeholder : item.title
                          }
                          disabled={item?.disabled}
                          padding="10px"
                          error={Boolean(
                            form.touched[item.titleName] &&
                              form.errors[item.titleName]
                          )}
                          placeholderFontSize={item?.placeholderFontSize}
                          helperText={
                            form.touched[item.titleName] &&
                            form.errors[item.titleName]
                          }
                          value={form.values[item.titleName]}
                          onChange={(e) =>
                            form.setFieldValue(item.titleName, e.target.value)
                          }
                        />
                      );
                    }}
                  </Field>
                )}
                {item.component === "input-select" && (
                  <CommonGrid2 container spacing={2}>
                    {item.data.map((innerItem) => (
                      <CommonGrid2
                        size={6}
                        display={"flex"}
                        flexDirection={"column"}
                        gap={"8px"}
                      >
                        <Tooltip arrow title={innerItem.title} placement="top">
                          <Typography
                            variant="p"
                            color="#344054"
                            fontSize="16px"
                            textOverflow={"ellipsis"}
                            whiteSpace={"nowrap"}
                            overflow={"hidden"}
                          >
                            {innerItem.title}
                          </Typography>
                        </Tooltip>
                        <Field name={innerItem.titleName}>
                          {({ field, form }) => {
                            const handleChange = (event, name, currency) => {
                              const updatedPayRate = {
                                ...form.values[innerItem.titleName],
                                [currency]: event.target.value,
                              };

                              form.setFieldValue(name, updatedPayRate);
                            };

                            return (
                              <CommonTextField
                                placeholder={"Enter the rate"}
                                onChange={(e) =>
                                  handleChange(
                                    e,
                                    innerItem.titleName,
                                    innerItem.currency
                                  )
                                }
                                disabled={innerItem?.disabled}
                                padding="10px"
                                error={
                                  form.touched[innerItem.titleName]?.[
                                    innerItem.currency
                                  ] &&
                                  form.errors[innerItem.titleName]?.[
                                    innerItem.currency
                                  ]
                                }
                                helperText={
                                  form.touched[innerItem.titleName]?.[
                                    innerItem.currency
                                  ] &&
                                  form.errors[innerItem.titleName]?.[
                                    innerItem.currency
                                  ]
                                }
                                startIcon={"$"}
                                value={
                                  form.values[innerItem.titleName][
                                    innerItem.currency
                                  ]
                                }
                              />
                            );
                          }}
                        </Field>
                      </CommonGrid2>
                    ))}
                  </CommonGrid2>
                )}
                {item.component === "dropDown" && (
                  <Field name={item.titleName}>
                    {({ field, form }) => {
                      const handleDropdownChange = (event) => {
                        form.setFieldValue(item.titleName, event.target.value);
                      };

                      return (
                        <DropDownSelect
                          marginTop="0px"
                          title={
                            item?.placeholder ? item?.placeholder : item.title
                          }
                          disabled={item?.disabled}
                          removeAnimation={true}
                          data={form.values[item.dropDownData]}
                          error={Boolean(
                            form.touched[item.titleName] &&
                              form.errors[item.titleName]
                          )}
                          helperText={
                            form.touched[item.titleName] &&
                            form.errors[item.titleName]
                          }
                          value={form.values[item.titleName]}
                          handleChange={handleDropdownChange}
                        />
                      );
                    }}
                  </Field>
                )}
                {item.component === "calender" && (
                  <Field name={item.titleName}>
                    {({ field, form }) => {
                      const isError =
                        form.touched[item.titleName]?.start_date &&
                        form.touched[item.titleName]?.end_date &&
                        Boolean(form.errors["Date_Range"]);

                      const helperText = isError
                        ? form?.errors["Date_Range"]
                        : "";

                      return (
                        <>
                          <DateRangePickerComp
                            startDateProp={form.values.Date_Range?.start_date}
                            endDateProp={form.values.Date_Range?.end_date}
                            handleChange={(start_date, end_date) => {
                              form.setFieldValue(item.titleName, {
                                start_date: start_date,
                                end_date: end_date,
                              });
                            }}
                            error={isError}
                          />
                          {isError && <ErrorContent>{helperText}</ErrorContent>}
                        </>
                      );
                    }}
                  </Field>
                )}

                {item.component === "autoComplete" && (
                  <Field name={item.titleName}>
                    {({ field, form }) => {
                      const handleAutoCompleteChange = (e, newValue) => {
                        const checkData = form?.values[item.titleName];
                        if (e?.key === "Backspace" && e.target.value === "") {
                          const updatedValue = [
                            ...(form.values[item.titleName] || []),
                          ];
                          updatedValue.pop();
                          form.setFieldValue(item.titleName, updatedValue);
                        } else {
                          const formattedValues = newValue.map(
                            (item, index) => {
                              if (typeof item === "string") {
                                return {
                                  content: item,
                                  ...(checkData[0]?.name
                                    ? { name: "inferred" }
                                    : { id: index }),
                                };
                              }
                              return item;
                            }
                          );

                          form.setFieldValue(item.titleName, formattedValues);
                        }
                      };

                      const handleDelete = (option) => {
                        const updatedValue = form.values[item.titleName].filter(
                          (item, i) => item !== option
                        );
                        form.setFieldValue(item.titleName, updatedValue);
                      };

                      const handleKeyDown = (e) => {
                        const checkData = form?.values[item.titleName];
                        const dataValue = e.target.value;
                        form.setFieldValue(item.inputValue, dataValue);
                        if (e.key === "Tab") {
                          e.preventDefault();

                          if (dataValue !== "") {
                            const data = {
                              content: dataValue,
                              ...(checkData[0]?.name
                                ? { name: "inferred" }
                                : { id: index }),
                            };

                            const formattedValues = [...checkData, data];
                            form.setFieldValue(item.titleName, formattedValues);
                            form.setFieldValue(item.inputValue, "");
                          }
                        }
                      };

                      const onInputChange = (e, newValue) => {
                        form.setFieldValue(item.inputValue, newValue);
                      };

                      const isError =
                        form.touched[item.titleName] &&
                        form.errors[item.titleName];
                      const helperText = isError
                        ? form.errors[item.titleName]
                        : "";
                      return (
                        <>
                          <AutocompleteText
                            autoData={{
                              options: [],
                              key: `autocomplete-${index}`,
                              title: "Select Emails",
                              placeholder: item.placeholder,
                              isDisabled: item.disabled,
                              value: form.values[item.titleName] || "",
                              onDelete: handleDelete,
                              onKeyDown: handleKeyDown,
                              onChange: handleAutoCompleteChange,
                              inputValue: form.values[item.inputValue] || "",
                              onInputChange: onInputChange,
                            }}
                            error={isError}
                          />
                          {isError && (
                            <ErrorContent margin={"-5px 0px 0px"}>
                              {helperText}
                            </ErrorContent>
                          )}
                        </>
                      );
                    }}
                  </Field>
                )}

                {item.component === "input-list" && (
                  <Field name={item.titleName}>
                    {({ field, form }) => {
                      return (
                        <CommonTextField
                          {...field}
                          placeholder={item.title}
                          padding="10px"
                          error={Boolean(
                            form.touched[item.titleName]?.[index] &&
                              form.errors[item.titleName]?.[index]
                          )}
                          helperText={
                            form.touched[item.titleName]?.[index] &&
                            form.errors[item.titleName]?.[index]
                          }
                          value={form.values[item.titleName]?.[index] || ""}
                          onChange={(e) => {
                            const updatedValues = [
                              ...form.values[item.titleName],
                            ];
                            updatedValues[index] = e.target.value;
                            form.setFieldValue(item.titleName, updatedValues);
                          }}
                        />
                      );
                    }}
                  </Field>
                )}
                {item.component === "fileUpload" && (
                  <CommonTextField
                    readOnly={true}
                    handleFileChange={handleFileChange}
                    value={fileName}
                    padding="10px"
                  />
                )}

                {item.component === "input-link" && (
                  <Field name={item.titleName}>
                    {({ field, form }) => {
                      return (
                        <CommonTextField
                          {...field}
                          placeholder={item.placeholder}
                          padding="10px"
                          startIcon={"http://"}
                          error={Boolean(
                            form.touched[item.titleName] &&
                              form.errors[item.titleName]
                          )}
                          placeholderFontSize={item?.placeholderFontSize}
                          helperText={
                            form.touched[item.titleName] &&
                            form.errors[item.titleName]
                          }
                          value={form.values[item.titleName]}
                          onChange={(e) =>
                            form.setFieldValue(item.titleName, e.target.value)
                          }
                          customStyle={{
                            "& .MuiInputAdornment-root": {
                              maxHeight: "none",
                              height: "100%",
                              borderRight: "1px solid #D0D5DD",
                              paddingRight: "10px",
                            },
                          }}
                        />
                      );
                    }}
                  </Field>
                )}
                {item.component === "input-link-linkedin" && (
                  <Field name={item.titleName}>
                    {({ field, form }) => {
                      return (
                        <CommonTextField
                          {...field}
                          placeholder={item.placeholder}
                          padding="10px"
                          endIcon={form.values["first_name"]}
                          error={Boolean(
                            form.touched[item.titleName] &&
                              form.errors[item.titleName]
                          )}
                          placeholderFontSize={item?.placeholderFontSize}
                          helperText={
                            form.touched[item.titleName] &&
                            form.errors[item.titleName]
                          }
                          value={form.values[item.titleName]}
                          onChange={(e) =>
                            form.setFieldValue(item.titleName, e.target.value)
                          }
                          disabled={item.disabled}
                          customStyle={{
                            "& .MuiInputAdornment-root": {
                              maxHeight: "none",
                              height: "100%",
                              borderLeft: "1px solid #D0D5DD",
                              paddingLeft: "1rem",
                            },
                          }}
                        />
                      );
                    }}
                  </Field>
                )}
                {item.component === "single-calender" && (
                  <Field name={item.titleName}>
                    {({ field, form }) => {
                      const isError =
                        form.touched[item.titleName] &&
                        Boolean(form.errors[item.titleName]);

                      const helperText = isError
                        ? form?.errors[item.titleName]
                        : "";

                      return (
                        <>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <CustomDatePicker
                              disabled={item.disabled}
                              onChange={(newValue) => {
                                const formattedDate = newValue
                                  ? dayjs(newValue).format("DD MMM")
                                  : "";
                                form.setFieldValue(
                                  item?.titleName,
                                  formattedDate
                                );
                              }}
                              value={
                                form?.values?.[item?.titleName]
                                  ? dayjs(form.values[item.titleName], "DD MMM")
                                  : null
                              }
                              format="DD MMM"
                              error={isError}
                              slots={{
                                openPickerIcon: () => (
                                  <CommonGrid2
                                    display={"flex"}
                                    fontSize={"15px"}
                                    gap={"5px"}
                                    alignItems={"center"}
                                    color={"#000"}
                                    fontWeight={600}
                                  >
                                    <CalendarTodayIcon
                                      style={{ height: "20px" }}
                                    />
                                    <Typography
                                      fontWeight={600}
                                      fontSize={"13px"}
                                    >
                                      Select
                                    </Typography>
                                  </CommonGrid2>
                                ),
                              }}
                            />
                          </LocalizationProvider>
                          {isError && (
                            <ErrorContent
                              margin={"-5px 0px 0px"}
                              className="Mui-error"
                            >
                              {helperText}
                            </ErrorContent>
                          )}
                        </>
                      );
                    }}
                  </Field>
                )}
                {item.component === "hour" && (
                  <Field name={item.titleName}>
                    {({ field, form }) => {
                      const handleChange = (event, name) => {
                        form.setFieldValue(name, event.target.value);
                      };

                      return (
                        <CommonTextField
                          placeholder={item.placeholder}
                          onChange={(e) => handleChange(e, item.titleName)}
                          padding="10px"
                          disabled={item.disabled}
                          error={
                            form.touched[item.titleName] &&
                            form.errors[item.titleName]
                          }
                          placeholderFontSize={item?.placeholderFontSize}
                          helperText={
                            form.touched[item.titleName] &&
                            form.errors[item.titleName]
                          }
                          startIcon={"$"}
                          value={form.values[item.titleName]}
                        />
                      );
                    }}
                  </Field>
                )}
              </>
            )}
          </CommonGrid2>
        );
      })}
    </CommonGrid2>
  );
};
export default MixedComponent;

import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid2,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import DotsGrid from "../../../../assets/icons/DotsGrid";
import DeleteIco from "../../../../assets/icons/DeleteIco";
import { StyledFlexRowView } from "../../../common-components/StyledComponents";
import CommonButton from "../../../../component/Button";
import { CustomPaper } from "../../../resume-intelligence/create-candidate-resume/components/Style";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Field } from "formik";
import { HandleSkeletion } from "../../../../utils/constants";
import CommonTextField from "../../../../component/Input";

function SeededPreScreen({
  alignment,
  preScreen,
  onDragEnd,
  handleSeededDelete,
  isLoading,
  handleAddSeeded,
}) {
  return (
    <Field>
      {({ form }) => {
        const convertData =
          alignment === "Edit"
            ? form.values["pre_screen_questions"]
            : preScreen;

        return (
          <Grid2 container spacing={3}>
            <Grid2 item size={12} marginTop={"1rem"}>
              <Typography fontSize={"16px"} fontWeight={500} color={"#101828"}>
                {"Seeded Pre-Screen Questions"}
              </Typography>
            </Grid2>
            <Grid2 item size={12}>
              {isLoading ? (
                Array.from({ length: 2 }).map((_, index) => (
                  <HandleSkeletion height={60} />
                ))
              ) : (
                <DragDropContext onDragEnd={(event) => onDragEnd(event, form)}>
                  <Droppable droppableId="droppable-questions">
                    {(provided) => (
                      <Grid2
                        container
                        // spacing={2.5}
                        size={12}
                        {...provided?.droppableProps}
                        ref={provided.innerRef}
                      >
                        {convertData?.map((question, index) => (
                          <Draggable
                            draggableId={`drag-${index}`}
                            index={index}
                            key={`question-${index}`}
                          >
                            {(provided) => (
                              <Grid2
                                item
                                size={12}
                                ref={provided.innerRef}
                                marginBottom={"1.2rem"}
                                {...provided?.draggableProps}
                              >
                                <StyledFlexRowView
                                  gap="15px"
                                  alignItems={"flex-start"}
                                >
                                  {alignment === "View" ? (
                                    <CustomPaper
                                      variant="outlined"
                                      border="1px solid #D0D5DD !important"
                                      width="100%"
                                      padding={"10px 12px"}
                                      borderRadius="8px"
                                    >
                                      <Typography
                                        fontSize={"16px"}
                                        fontWeight={500}
                                        color={"#101828"}
                                      >
                                        {question}
                                      </Typography>
                                    </CustomPaper>
                                  ) : (
                                    <CommonTextField
                                      placeholder={"Enter the Question"}
                                      padding="10px"
                                      error={Boolean(
                                        form.touched["pre_screen_questions"]?.[
                                          index
                                        ] &&
                                          form.errors["pre_screen_questions"]?.[
                                            index
                                          ]
                                      )}
                                      helperText={
                                        form.touched["pre_screen_questions"]?.[
                                          index
                                        ] &&
                                        form.errors["pre_screen_questions"]?.[
                                          index
                                        ]
                                      }
                                      value={question}
                                      onChange={(e) => {
                                        const updatedQuestions = [
                                          ...form.values[
                                            "pre_screen_questions"
                                          ],
                                        ];
                                        updatedQuestions[index] =
                                          e.target.value;
                                        form.setFieldValue(
                                          "pre_screen_questions",
                                          updatedQuestions
                                        );
                                      }}
                                    />
                                  )}

                                  <IconButton
                                    size="small"
                                    disabled={alignment === "View"}
                                    sx={{
                                      border: "1px solid #D0D5DD",
                                      borderRadius: "7px",
                                      padding: "10px",
                                      opacity: alignment === "View" ? 0.5 : 1,
                                    }}
                                    {...provided?.dragHandleProps}
                                  >
                                    <DotsGrid />
                                  </IconButton>

                                  <IconButton
                                    size="small"
                                    disabled={alignment === "View"}
                                    sx={{
                                      border: "1px solid #D0D5DD",
                                      borderRadius: "7px",
                                      padding: "10px",
                                      opacity: alignment === "View" ? 0.5 : 1,
                                    }}
                                    onClick={() =>
                                      alignment === "View"
                                        ? {}
                                        : handleSeededDelete(
                                            question,
                                            index,
                                            form
                                          )
                                    }
                                  >
                                    <DeleteIco />
                                  </IconButton>
                                </StyledFlexRowView>
                              </Grid2>
                            )}
                          </Draggable>
                        ))}

                        {provided?.placeholder}
                      </Grid2>
                    )}
                  </Droppable>
                </DragDropContext>
              )}
              {alignment !== "View" && (
                <Grid2 item size={12}>
                  <CommonButton
                    variant="outlined"
                    color="#344054"
                    border="#D0D5DD"
                    fontWeight="600"
                    value={"Add Questions"}
                    disabled={isLoading}
                    onClick={() => handleAddSeeded(form)}
                    padding="10px 20px"
                  />
                </Grid2>
              )}
            </Grid2>
          </Grid2>
        );
      }}
    </Field>
  );
}

export default SeededPreScreen;

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  Checkbox,
  Grid2,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import React from "react";
import CommonButton from "../../../component/Button";
import { HandleSkeletion } from "../../../utils/constants";
import { TableContainerBorder } from "../../common-components/StyledComponents";

const CommonTable = ({
  data = [],
  columns = [],
  sortedColumns = [],
  onSortChange,
  paginationProps = { count: 0, page: 1, onPageChange: () => {} },
  withPagination = false,
  withCheckbox = false,
  handleTableBodyData,
  loading,
  handleSingleSelect,
  checkSelected,
  handleSelectAll,
}) => {
  const { count, page, onPageChange } = paginationProps;
  const loadingLength = withCheckbox ? 1 : 0;

  return (
    <TableContainerBorder>
      <Table>
        <TableHead>
          <TableRow>
            {withCheckbox && (
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  checked={
                    data &&
                    data.length !== 0 &&
                    data.every((row) =>
                      (checkSelected || []).some((item) => item.id === row.id)
                    )
                  }
                  onChange={handleSelectAll}
                />
              </TableCell>
            )}
            {columns.map((col, index) => (
              <TableCell
                key={index}
                style={{
                  color: "#475467",
                  padding: "16px",
                }}
              >
                {sortedColumns.includes(col) && onSortChange ? (
                  <TableSortLabel
                    active={sortedColumns.includes(col)}
                    direction="asc"
                    onClick={() => onSortChange(col)}
                  >
                    <Typography
                      variant="body2"
                      fontWeight="600"
                      color="#475467"
                    >
                      {col}
                    </Typography>
                  </TableSortLabel>
                ) : (
                  <Typography variant="body2" fontWeight="600" color="#475467">
                    {col}
                  </Typography>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {loading ? (
          <>
            {Array.from({ length: 10 }).map((_, index) => (
              <TableRow>
                {Array.from({
                  length: columns.length + loadingLength,
                }).map((_, index) => (
                  <TableCell key={index}>
                    <HandleSkeletion height={30} />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </>
        ) : (
          <TableBody>
            {data.length > 0 ? (
              data.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {withCheckbox && (
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={checkSelected.some(
                          (item) => item.id === row.id
                        )}
                        onChange={() => {
                          handleSingleSelect(row);
                        }}
                      />
                    </TableCell>
                  )}
                  {handleTableBodyData(row)}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  <Typography>No data available</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        )}
      </Table>

      {withPagination && (
        <Grid2 display="flex" justifyContent="space-between" sx={{ p: 2 }}>
          <CommonButton
            variant="outlined"
            border="#D0D5DD"
            color="#344054"
            fontWeight="600"
            value="Previous"
            padding="3px 10px"
            startIcon={<ArrowBackIcon />}
            onClick={() => {
              if (page > 1) {
                onPageChange(page - 1);
              }
            }}
            disabled={page === 1 || loading}
          />
          <Pagination
            count={count}
            page={page}
            onChange={(_, newPage) => onPageChange(newPage)}
            shape="rounded"
            color="primary"
            hideNextButton
            hidePrevButton
            disabled={loading}
          />
          <CommonButton
            variant="outlined"
            border="#D0D5DD"
            color="#344054"
            fontWeight="600"
            value="Next"
            padding="3px 10px"
            endIcon={<ArrowForwardIcon />}
            onClick={() => {
              if (page < count) {
                onPageChange(page + 1);
              }
            }}
            disabled={page === count || loading}
          />
        </Grid2>
      )}
    </TableContainerBorder>
  );
};

export default CommonTable;

function UserManagementIco({ color }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_752_42424)">
        <path
          d="M18.0001 7.16C17.9401 7.15 17.8701 7.15 17.8101 7.16C16.4301 7.11 15.3301 5.98 15.3301 4.58C15.3301 3.15 16.4801 2 17.9101 2C19.3401 2 20.4901 3.16 20.4901 4.58C20.4801 5.98 19.3801 7.11 18.0001 7.16Z"
          stroke="#667085"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M16.9695 14.4401C18.3395 14.6701 19.8495 14.4301 20.9095 13.7201C22.3195 12.7801 22.3195 11.2401 20.9095 10.3001C19.8395 9.59017 18.3095 9.35016 16.9395 9.59016"
          stroke="#667085"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M5.97047 7.16C6.03047 7.15 6.10047 7.15 6.16047 7.16C7.54047 7.11 8.64047 5.98 8.64047 4.58C8.64047 3.15 7.49047 2 6.06047 2C4.63047 2 3.48047 3.16 3.48047 4.58C3.49047 5.98 4.59047 7.11 5.97047 7.16Z"
          stroke="#667085"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.99945 14.4401C5.62945 14.6701 4.11945 14.4301 3.05945 13.7201C1.64945 12.7801 1.64945 11.2401 3.05945 10.3001C4.12945 9.59017 5.65945 9.35016 7.02945 9.59016"
          stroke="#667085"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12.0001 14.6288C11.9401 14.6188 11.8701 14.6188 11.8101 14.6288C10.4301 14.5788 9.33008 13.4488 9.33008 12.0488C9.33008 10.6188 10.4801 9.46875 11.9101 9.46875C13.3401 9.46875 14.4901 10.6288 14.4901 12.0488C14.4801 13.4488 13.3801 14.5888 12.0001 14.6288Z"
          stroke="#667085"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.0907 17.7794C7.6807 18.7194 7.6807 20.2594 9.0907 21.1994C10.6907 22.2694 13.3107 22.2694 14.9107 21.1994C16.3207 20.2594 16.3207 18.7194 14.9107 17.7794C13.3207 16.7194 10.6907 16.7194 9.0907 17.7794Z"
          stroke="#667085"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_752_42424">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default UserManagementIco;

export default function ClientsPartnersIco({ color }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_752_42241)">
        <g clip-path="url(#clip1_752_42241)">
          <path
            d="M15.4475 12.7625C16.8051 11.666 17.6842 9.9435 17.6842 8C17.6842 4.6865 15.1391 2 12 2C8.86089 2 6.31579 4.6865 6.31579 8C6.31579 9.9435 7.19495 11.666 8.55253 12.7625C5.82565 14.0197 3.77558 16.7212 3.17808 20.0103C2.98066 21.0971 3.89543 22 5 22H19C20.1046 22 21.0193 21.0971 20.8219 20.0103C20.2244 16.7212 18.1744 14.0197 15.4475 12.7625ZM8.21053 8C8.21053 5.7945 9.91058 4 12 4C14.0894 4 15.7895 5.7945 15.7895 8C15.7895 10.2055 14.0894 12 12 12C9.91058 12 8.21053 10.2055 8.21053 8ZM12 14C15.3049 14 18.0897 16.5535 18.8803 20H5.11974C5.91032 16.5535 8.69511 14 12 14Z"
            fill="#667085"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_752_42241">
          <rect width="24" height="24" fill="white" />
        </clipPath>
        <clipPath id="clip1_752_42241">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

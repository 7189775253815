import { Grid2, Typography } from "@mui/material";
import React from "react";
import { HandleSkeletion } from "../../../utils/constants";
import { CommonGrid2 } from "../../common-components/StyledComponents";
import { StyledSpaceBetweenBox } from "../../quick-match/components/StyledComponent";
import { SelectCreateCard } from "../style";

const AnalyticsCard = ({ harizonAnalytics = [], isLoading }) => {
  return (
    <Grid2 container columnSpacing={2} rowSpacing={isLoading ? 0 : 3}>
      {isLoading ? (
        <>
          {Array.from({ length: 6 }).map((_, index) => (
            <CommonGrid2
              item
              size={{
                xs: 12,
                md: 4,
                sm: 12,
                lg: 2,
              }}
              gap={"5px"}
              display={"flex"}
              flexDirection={"row"}
            >
              <HandleSkeletion height={100} width={"100%"} />
            </CommonGrid2>
          ))}
        </>
      ) : (
        <>
          {Object.keys(harizonAnalytics).length !== 0 &&
            harizonAnalytics?.map(
              ({ name, value, percentage, decreament }, index) => (
                <Grid2 item size={{ xs: 12, sm: 12, md: 6, lg: 2 }}>
                  <SelectCreateCard key={name + index} minHeight="100%">
                    <StyledSpaceBetweenBox alignItems="baseline">
                      <Typography
                        color="#5B738B"
                        variant="h6"
                        fontSize={"15px"}
                      >
                        {name}
                      </Typography>
                      {/* <Box
                    display={"flex"}
                    alignItems={"center"}
                    color={!decreament ? "#188918" : "#EE3939"}
                    backgroundColor={!decreament ? "#F5FAE5" : "#FFDCE8"}
                    borderRadius={"5px"}
                    width={"fit-content"}
                    padding={"2px 5px"}
                    border={`1px solid ${!decreament ? "#188918" : "#EE3939"}`}
                  >
                    <Typography variant="subtitle2" fontWeight={600}>
                      {percentage}
                    </Typography>

                    {!decreament ? (
                      <NorthRounded fontSize="30px" fontWeight="bold" />
                    ) : (
                      <SouthRounded fontSize="30px" fontWeight="bold" />
                    )}
                  </Box> */}
                    </StyledSpaceBetweenBox>
                    <Typography variant="h2" marginTop={"10px"}>
                      {value}
                    </Typography>
                  </SelectCreateCard>
                </Grid2>
              )
            )}
        </>
      )}
    </Grid2>
  );
};

export default AnalyticsCard;

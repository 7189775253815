export default function LogoutIco() {
  return (
    <svg
      width="65"
      height="65"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 10C0 4.47715 4.47715 0 10 0H38C43.5228 0 48 4.47715 48 10V38C48 43.5228 43.5228 48 38 48H10C4.47715 48 0 43.5228 0 38V10Z"
        fill="#FFEAF4"
      />
      <path
        d="M25.2045 11H22.1742V24.5659H25.2045V11ZM31.4545 13.9438L29.3466 15.8702C31.4413 17.593 32.7803 20.2384 32.7803 23.2093C32.7803 28.4593 28.6307 32.7054 23.5 32.7054C18.3693 32.7054 14.2197 28.4593 14.2197 23.2093C14.2197 20.2384 15.5587 17.593 17.6402 15.8566L15.5455 13.9438C12.9735 16.1822 11 19.4922 11 23.2093C11 29.9515 16.911 36 23.5 36C30.089 36 36 29.9515 36 23.2093C36 19.4922 34.0265 16.1822 31.4545 13.9438Z"
        fill="#FF0000"
      />
    </svg>
  );
}

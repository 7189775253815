export default function Ellipse() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      width="50"
      height="50"
    >
      <g>
        <circle fill="#0070f2" r="10" cy="50" cx="84">
          <animate
            begin="0s"
            keySplines="0 0.5 0.5 1"
            values="10;0"
            keyTimes="0;1"
            calcMode="spline"
            dur="0.25s"
            repeatCount="indefinite"
            attributeName="r"
          ></animate>
          <animate
            begin="0s"
            values="#0070f2;rgba(0, 112, 242, 0.824);#4e9ffd;#4b9eff;#0070f2"
            keyTimes="0;0.25;0.5;0.75;1"
            calcMode="discrete"
            dur="1s"
            repeatCount="indefinite"
            attributeName="fill"
          ></animate>
        </circle>
        <circle fill="#0070f2" r="10" cy="50" cx="16">
          <animate
            begin="0s"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            values="0;0;10;10;10"
            keyTimes="0;0.25;0.5;0.75;1"
            calcMode="spline"
            dur="1s"
            repeatCount="indefinite"
            attributeName="r"
          ></animate>
          <animate
            begin="0s"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            values="16;16;16;50;84"
            keyTimes="0;0.25;0.5;0.75;1"
            calcMode="spline"
            dur="1s"
            repeatCount="indefinite"
            attributeName="cx"
          ></animate>
        </circle>
        <circle fill="#4b9eff" r="10" cy="50" cx="50">
          <animate
            begin="-0.25s"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            values="0;0;10;10;10"
            keyTimes="0;0.25;0.5;0.75;1"
            calcMode="spline"
            dur="1s"
            repeatCount="indefinite"
            attributeName="r"
          ></animate>
          <animate
            begin="-0.25s"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            values="16;16;16;50;84"
            keyTimes="0;0.25;0.5;0.75;1"
            calcMode="spline"
            dur="1s"
            repeatCount="indefinite"
            attributeName="cx"
          ></animate>
        </circle>
        <circle fill="#4e9ffd" r="10" cy="50" cx="84">
          <animate
            begin="-0.5s"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            values="0;0;10;10;10"
            keyTimes="0;0.25;0.5;0.75;1"
            calcMode="spline"
            dur="1s"
            repeatCount="indefinite"
            attributeName="r"
          ></animate>
          <animate
            begin="-0.5s"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            values="16;16;16;50;84"
            keyTimes="0;0.25;0.5;0.75;1"
            calcMode="spline"
            dur="1s"
            repeatCount="indefinite"
            attributeName="cx"
          ></animate>
        </circle>
        <circle fill="rgba(0, 112, 242, 0.824)" r="10" cy="50" cx="16">
          <animate
            begin="-0.75s"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            values="0;0;10;10;10"
            keyTimes="0;0.25;0.5;0.75;1"
            calcMode="spline"
            dur="1s"
            repeatCount="indefinite"
            attributeName="r"
          ></animate>
          <animate
            begin="-0.75s"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            values="16;16;16;50;84"
            keyTimes="0;0.25;0.5;0.75;1"
            calcMode="spline"
            dur="1s"
            repeatCount="indefinite"
            attributeName="cx"
          ></animate>
        </circle>
        <g></g>
      </g>
    </svg>
  );
}

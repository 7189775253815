import { combineReducers } from "redux";

import customizationReducer from "./customizationReducer";
import QuickMatchReducer from "../redux/reducer/QuickMatchReducer";
import JobReducer from "../redux/reducer/JobReducer";
import ResumeIntelligenceReducer from "../redux/reducer/ResumeIntelligence";
import CandidateReducer from "../redux/reducer/CandidateReducer";
import CoverSheetReducer from "../redux/reducer/CoverSheetReducer";
import HarizonReducer from "../redux/reducer/HarizonReducer";

const reducer = combineReducers({
  customization: customizationReducer,
  quickMatchState: QuickMatchReducer,
  jobs: JobReducer,
  resumeIntelligence: ResumeIntelligenceReducer,
  candidate: CandidateReducer,
  coverSheet: CoverSheetReducer,
  harizon: HarizonReducer,
});

export default reducer;

import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../component/Breadcrumbs";
import { Grid2, Tab, Typography } from "@mui/material";
import {
  CommonGrid2,
  TabPanelView,
  TabViewSection,
} from "../common-components/StyledComponents";
import CommonButton from "../../component/Button";
import EditIndex from "../jobs/common-card.js/Edit-Index";
import { Form, Formik } from "formik";
import { TabContext } from "@mui/lab";
import CriticalityScore from "./CriticalityScore";
import {
  PartnerCriticalScore,
  PartnerCriticalScoreUpdate,
} from "../../redux/action/Partner";
import SnackBar from "../../component/SnackBar";
import * as Yup from "yup";

export default function Client() {
  const [value, setValue] = useState("2");
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: "",
    severity: "",
  });

  const [clientData, setClientData] = useState({
    data: [],
    primaryTotalCount: "",
    secondaryTotalCount: "",
    inputField: ["primary", "secondary"],
    headerData: ["Skill", "Experience"],
    loader: false,
    buttonLoader: false,
  });

  const tabData = [
    {
      name: "Preset 1 ⏤ Balanced",
      disabled: true,
    },
    {
      name: "Preset 2 ⏤  Experience is King",
      disabled: true,
    },
    {
      name: "Preset 3 ⏤ Bedside Manners Rules",
      disabled: false,
    },
  ];
  const breadCrumbData = [
    {
      title: "Setting",
      navigate: "",
      disabled: true,
    },
    // {
    //   title: "Clients",
    //   navigate: "",
    //   disabled: true,
    // },
    {
      title: "Weights",
      active: true,
      disabled: true,
    },
  ];

  const primaryViewData = [
    {
      title: "Name",
      component: "input",
      titleName: "job_title",
    },
    {
      title: "Company",
      component: "fileUpload",
      titleName: "company_name",
    },
    {
      title: "Corporate Address",
      component: "input",
      titleName: "corporate_address",
    },
    {
      title: "Industry",
      component: "dropDown",
      titleName: "industry",
      dropDownData: "available_industry",
    },

    {
      title: "Company Type",
      component: "dropDown",
      titleName: "company_type",
      dropDownData: "available_management_level",
    },

    {
      title: "Countries Operating In",
      component: "autoComplete",
      titleName: "countries_operating_in",
      placeholder: "Enter Countries",
    },
    {
      title: "Hub Cities",
      component: "autoComplete",
      titleName: "hub_cities",
      placeholder: "Enter Cities",
    },
    {
      title: "Primary Time Zone",
      component: "dropDown",
      titleName: "primary_time_zone",
      dropDownData: "available_employment_types",
    },
    {
      title: "VMS",
      component: "input",
      titleName: "vms",
    },
    {
      title: "Sourcing Contact",
      component: "input",
      titleName: "vms",
    },
    {
      title: "Email Address",
      component: "input",
      titleName: "vms",
    },
    {
      title: "Email Address",
      component: "input",
      titleName: "vms",
    },
  ];

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    fetchCriticalScore();
  }, []);

  const fetchCriticalScore = async () => {
    setClientData((prevData) => {
      return {
        ...prevData,
        loader: true,
      };
    });
    try {
      const data = await PartnerCriticalScore();
      setClientData((prevData) => {
        return {
          ...prevData,
          data: data,
          primaryTotalCount: data.reduce(
            (sum, item) =>
              sum + (item.attribute === "primary" ? item.weightage : 0),
            0
          ),
          secondaryTotalCount: data.reduce(
            (sum, item) =>
              sum + (item.attribute === "secondary" ? item.weightage : 0),
            0
          ),
          loader: false,
          buttonLoader: false,
        };
      });
    } catch (error) {
      setSnackbarState({
        open: true,
        severity: "error",
        message: error.message,
      });
      setClientData((prevData) => {
        return {
          ...prevData,
          loader: false,
          buttonLoader: false,
        };
      });
    }
  };

  const handleCloseValidation = () => {
    setSnackbarState((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const handleSliderChange = (id, newValue) => {
    setClientData((prevData) => {
      const updatedData = prevData.data.map((item) =>
        item.id === id ? { ...item, weightage: newValue } : item
      );

      return {
        ...prevData,
        data: updatedData,
        primaryTotalCount: updatedData.reduce(
          (sum, item) =>
            sum + (item.attribute === "primary" ? item.weightage : 0),
          0
        ),
        secondaryTotalCount: updatedData.reduce(
          (sum, item) =>
            sum + (item.attribute === "secondary" ? item.weightage : 0),
          0
        ),
      };
    });
  };

  const handleSubmitClient = async (values, actions) => {
    const updatedData = values.data.reduce(
      (sum, item) => sum + item.weightage,
      0
    );
    if (updatedData !== 100) {
      setTimeout(() => {
        const errorElement = document.querySelector(".error-occur");
        if (errorElement) {
          errorElement.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }, 0);
    } else {
      try {
        setClientData((prevData) => {
          return {
            ...prevData,
            loader: false,
            buttonLoader: true,
          };
        });
        const data = await PartnerCriticalScoreUpdate(values.data);

        fetchCriticalScore();
        setSnackbarState({
          open: true,
          severity: "success",
          message: "Critical Score Updated",
        });
      } catch (error) {
        setSnackbarState({
          open: true,
          severity: "error",
          message: error.message,
        });
        setClientData((prevData) => {
          return {
            ...prevData,
            loader: false,
            buttonLoader: false,
          };
        });
      }
    }
  };
  const validationSchema = Yup.object({
    data: "",
  });

  const handleScrollToError = (errors) => {
    console.log("errors", errors);
  };
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={clientData}
        validationSchema={validationSchema}
        validateOnMount={true}
        onSubmit={async (values, actions) => {
          handleSubmitClient(values, actions);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          handleSubmit,
        }) => {
          console.log(values, "dfndklgjkdlglkdnlk");
          return (
            <Form
              onKeyDown={(e) => {
                if (e.key === "Enter") e.preventDefault();
              }}
            >
              <Grid2>
                <Breadcrumbs breadCrumbData={breadCrumbData} />
                <Grid2
                  container
                  spacing={2}
                  marginTop={"2rem"}
                  marginBottom={"2rem"}
                >
                  <CommonGrid2
                    size={{ xs: 12, md: 4, sm: 12 }}
                    item
                    alignItems={"center"}
                    display={"flex"}
                  >
                    <Typography variant="h3">Weights</Typography>
                  </CommonGrid2>
                  <CommonGrid2
                    item
                    size={{ xs: 12, md: 8, sm: 12 }}
                    gap="10px"
                    display="flex"
                    justifyContent={"end"}
                  >
                    {/* <CommonButton
                      variant="outlined"
                      color="#344054"
                      border="#D0D5DD"
                      fontWeight="600"
                      value="Cancel"
                      padding="8px 20px"
                      disabled={true}
                    /> */}
                    <CommonButton
                      variant="contained"
                      color="white"
                      background="#7F56D9"
                      fontWeight="600"
                      value="Save"
                      padding="8px 20px"
                      type="submit"
                      disabled={clientData.buttonLoader}
                      loading={clientData.buttonLoader}
                      onClick={() => {
                        setTimeout(() => {
                          handleScrollToError(errors);
                        }, 0);
                      }}
                    />
                  </CommonGrid2>
                </Grid2>
                {/* <EditIndex
                  editData={primaryViewData}
                  rowSize={true}
                  loading={false}
                /> */}
              </Grid2>

              <TabContext value={value}>
                {/* <TabViewSection
                  onChange={handleTabChange}
                  variant="scrollable"
                  style={{ marginTop: "3rem" }}
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                >
                  {tabData.map((item, index) => (
                    <Tab
                      label={item.name}
                      value={index.toString()}
                      disabled={item.disabled}
                    />
                  ))}
                </TabViewSection> */}

                <TabPanelView value="2" padding="0rem 0px">
                  <CriticalityScore
                    clientData={clientData}
                    handleSliderChange={handleSliderChange}
                  />
                </TabPanelView>
              </TabContext>
            </Form>
          );
        }}
      </Formik>
      <SnackBar
        snackbarState={snackbarState}
        handleCloseValidation={handleCloseValidation}
      />
    </>
  );
}

import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import {
  CustomAccordion,
  CustomTable,
  DialogTableList,
  TableContainerBorder,
} from "../../../common-components/StyledComponents";
import {
  CustomizeTableCell,
  CustomizeTableRow,
  EllipseData,
} from "../../style";

const SummaryInteractionDialog = ({ open, handleClose, interActionData }) => {
  const SkillHeader = ["", "values", "Skill", "Category", "Experience In Year"];
  const skillBodyData = [
    { name: "Updated", value: "updated" },
    { name: "Added", value: "added" },
    { name: "Deleted", value: "deleted" },
  ];

  const RelavancyHeader = ["", "Values", "OrderNo", "Score", "Skill", "Status"];

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="md"
      onClose={handleClose}
      sx={{ "& .MuiDialog-paperFullWidth": { padding: "0px 25px 20px" } }}
    >
      <DialogActions>
        <Button onClick={handleClose} sx={{ left: "1.5rem" }}>
          <CloseIcon />
        </Button>
      </DialogActions>
      <DialogTitle>
        <Typography variant="h3" textAlign={"center"} marginTop={-5}>
          {interActionData.action}
        </Typography>
      </DialogTitle>

      {interActionData?.updated_fields?.map((item, index) => {
        return (
          <CustomAccordion borderBottom={"1px solid #EAECF0"}>
            <AccordionSummary
              expandIcon={<KeyboardArrowUpIcon />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
            >
              <Typography variant="h4" margin={"0px"} fontWeight={600}>
                {item.field}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainerBorder
                sx={{
                  marginTop: "0px !important",
                  width:
                    item.field === "extracted_soft_skills" ||
                    item.field === "relevant_soft_skills" ||
                    item.field === "relevant_hard_skills" ||
                    item.field === "extracted_hard_skills"
                      ? "90%"
                      : item.field === "relevancy_index"
                      ? "100%"
                      : "79%",
                  margin: "auto",
                }}
              >
                <CustomTable>
                  <TableHead>
                    {item.field === "extracted_soft_skills" ||
                    item.field === "relevant_soft_skills" ||
                    item.field === "relevant_hard_skills" ||
                    item.field === "extracted_hard_skills" ? (
                      <TableRow>
                        {SkillHeader.map((item) => (
                          <TableCell>{item}</TableCell>
                        ))}
                      </TableRow>
                    ) : item.field === "relevancy_index" ? (
                      <TableRow>
                        {RelavancyHeader.map((item) => (
                          <TableCell>{item}</TableCell>
                        ))}
                      </TableRow>
                    ) : item.field[0] === "order" ||
                      item.field === "rescore" ? (
                      <>
                        <TableRow>
                          <CustomizeTableCell
                            rowSpan={2}
                            fontWeight={"bold"}
                            textAlign={"center"}
                          >
                            Value
                          </CustomizeTableCell>
                          <CustomizeTableCell
                            colSpan={4}
                            fontWeight={"bold"}
                            textAlign={"center"}
                          >
                            Name
                          </CustomizeTableCell>
                        </TableRow>

                        <TableRow>
                          {item.candidates.map((candidate) => (
                            <>
                              <CustomizeTableCell textAlign="center">
                                {candidate?.name}
                              </CustomizeTableCell>
                            </>
                          ))}
                        </TableRow>
                      </>
                    ) : (
                      <TableRow>
                        <TableCell
                          sx={{
                            fontWeight: "800 !important",
                            minWidth: "120px",
                          }}
                        >
                          Value
                        </TableCell>

                        {item.field !== "candidates" &&
                          typeof item?.old_value === "object" &&
                          !Array.isArray(item?.old_value) &&
                          item?.old_value !== null &&
                          Object.keys(item?.old_value).map((key) => {
                            if (key === "id" || key === "job_info") return null;
                            return (
                              <TableCell
                                key={key}
                                sx={{ fontWeight: "800 !important" }}
                              >
                                {key
                                  .toLowerCase()
                                  .split(" ")
                                  .map(
                                    (word) =>
                                      word.charAt(0).toUpperCase() +
                                      word.slice(1)
                                  )
                                  .join(" ")}
                              </TableCell>
                            );
                          })}
                        {item.field !== "candidates" &&
                          Array.isArray(item?.old_value) &&
                          item?.old_value.map((item, index) => {
                            if (item?.name === undefined) return null;
                            return (
                              <TableCell
                                key={index}
                                sx={{ fontWeight: "800 !important" }}
                              >
                                {item.name
                                  .toLowerCase()
                                  .split(" ")
                                  .map(
                                    (word) =>
                                      word.charAt(0).toUpperCase() +
                                      word.slice(1)
                                  )
                                  .join(" ")}
                              </TableCell>
                            );
                          })}

                        {item.field !== "candidates" &&
                          (item.field === "shift" ||
                            item.field === "location_targets") &&
                          Array.isArray(item?.old_value) && (
                            <TableCell
                              key={index}
                              sx={{ fontWeight: "800 !important" }}
                            >
                              Field
                            </TableCell>
                          )}

                        {item.field !== "candidates" &&
                          typeof item?.old_value !== "object" &&
                          !Array.isArray(item?.old_value) && (
                            <TableCell sx={{ fontWeight: "800 !important" }}>
                              Field
                            </TableCell>
                          )}
                        {item.field === "candidates" && (
                          <TableCell
                            key={index}
                            sx={{ fontWeight: "800 !important" }}
                          >
                            {"Field"}
                          </TableCell>
                        )}
                      </TableRow>
                    )}
                  </TableHead>
                  {item.field === "extracted_soft_skills" ||
                  item.field === "relevant_soft_skills" ||
                  item.field === "relevant_hard_skills" ||
                  item.field === "extracted_hard_skills" ? (
                    <TableBody>
                      {skillBodyData.map((getItem, index) => {
                        const { old_value, new_value } = item[getItem.value];
                        const hasOldValues = old_value.length > 0;
                        const hasNewValues = new_value.length > 0;

                        return (
                          <React.Fragment key={index}>
                            <TableRow>
                              <TableCell
                                rowSpan={2}
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "center",
                                }}
                              >
                                {getItem.name} Value
                              </TableCell>
                              <TableCell>Old Value</TableCell>
                              <CustomizeTableCell textAlign="center">
                                {hasOldValues
                                  ? old_value.map((value, i) => (
                                      <TableRow key={i}>
                                        <TableCell>
                                          <Tooltip
                                            title={value?.skill}
                                            arrow
                                            placement="top"
                                          >
                                            <EllipseData width="145px">
                                              {value?.skill}
                                            </EllipseData>
                                          </Tooltip>
                                        </TableCell>
                                      </TableRow>
                                    ))
                                  : "-"}
                              </CustomizeTableCell>
                              <CustomizeTableCell textAlign="center">
                                {hasOldValues
                                  ? old_value.map((value, i) => (
                                      <TableRow key={i}>
                                        <TableCell>{value?.category}</TableCell>
                                      </TableRow>
                                    ))
                                  : "-"}
                              </CustomizeTableCell>
                              <CustomizeTableCell textAlign="center">
                                {hasOldValues
                                  ? old_value.map((value, i) => (
                                      <CustomizeTableRow
                                        key={i}
                                        display="grid"
                                        marginBottom="10px"
                                      >
                                        <CustomizeTableCell textAlign="center">
                                          {value?.experience_in_yrs}
                                        </CustomizeTableCell>
                                      </CustomizeTableRow>
                                    ))
                                  : "-"}
                              </CustomizeTableCell>
                            </TableRow>

                            {/* Second Row */}
                            <TableRow>
                              <TableCell>New Value</TableCell>
                              <CustomizeTableCell textAlign="center">
                                {hasNewValues
                                  ? new_value.map((value, i) => (
                                      <TableRow key={i}>
                                        <TableCell>
                                          <Tooltip
                                            title={value?.skill}
                                            arrow
                                            placement="top"
                                          >
                                            <EllipseData width="145px">
                                              {value?.skill}
                                            </EllipseData>
                                          </Tooltip>
                                        </TableCell>
                                      </TableRow>
                                    ))
                                  : "-"}
                              </CustomizeTableCell>
                              <CustomizeTableCell textAlign="center">
                                {hasNewValues
                                  ? new_value.map((value, i) => (
                                      <TableRow key={i}>
                                        <TableCell>{value?.category}</TableCell>
                                      </TableRow>
                                    ))
                                  : "-"}
                              </CustomizeTableCell>
                              <CustomizeTableCell textAlign="center">
                                {hasNewValues
                                  ? new_value.map((value, i) => (
                                      <CustomizeTableRow
                                        key={i}
                                        display="grid"
                                        marginBottom="10px"
                                      >
                                        <CustomizeTableCell textAlign="center">
                                          {value?.experience_in_yrs}
                                        </CustomizeTableCell>
                                      </CustomizeTableRow>
                                    ))
                                  : "-"}
                              </CustomizeTableCell>
                            </TableRow>
                          </React.Fragment>
                        );
                      })}
                    </TableBody>
                  ) : item.field === "relevancy_index" ? (
                    <TableBody>
                      <React.Fragment key={index}>
                        <TableRow>
                          <TableCell
                            rowSpan={2}
                            style={{
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Value
                          </TableCell>
                          <TableCell>Old Value</TableCell>
                          <CustomizeTableCell textAlign="center">
                            {item.old_value.map((value, i) => (
                              <TableRow key={i}>
                                <TableCell>
                                  <Tooltip
                                    title={value?.order_no}
                                    arrow
                                    placement="top"
                                  >
                                    <EllipseData width="auto">
                                      {value?.order_no}
                                    </EllipseData>
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            ))}
                          </CustomizeTableCell>
                          <CustomizeTableCell textAlign="center">
                            {item.old_value.map((value, i) => (
                              <TableRow key={i}>
                                <TableCell>
                                  <Tooltip
                                    title={value?.skill}
                                    arrow
                                    placement="top"
                                  >
                                    <EllipseData width="145px">
                                      {value?.skill}
                                    </EllipseData>
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            ))}
                          </CustomizeTableCell>
                          <CustomizeTableCell textAlign="center">
                            {item.old_value.map((value, i) => (
                              <TableRow key={i}>
                                <TableCell>
                                  <Tooltip
                                    title={value?.relevancy_score}
                                    arrow
                                    placement="top"
                                  >
                                    <EllipseData width="auto">
                                      {value?.relevancy_score}
                                    </EllipseData>
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            ))}
                          </CustomizeTableCell>
                          <CustomizeTableCell textAlign="center">
                            {item.old_value.map((value, i) => (
                              <CustomizeTableRow
                                key={i}
                                display="grid"
                                marginBottom="10px"
                              >
                                <CustomizeTableCell>
                                  <Tooltip
                                    title={value?.skill_availability_status}
                                    arrow
                                    placement="top"
                                  >
                                    <EllipseData width="auto">
                                      {value?.skill_availability_status}
                                    </EllipseData>
                                  </Tooltip>
                                </CustomizeTableCell>
                              </CustomizeTableRow>
                            ))}
                          </CustomizeTableCell>
                        </TableRow>

                        {/* Second Row */}
                        <TableRow>
                          <TableCell>New Value</TableCell>
                          <CustomizeTableCell textAlign="center">
                            {item.new_value.map((value, i) => (
                              <TableRow key={i}>
                                <TableCell>
                                  <Tooltip
                                    title={value?.order_no}
                                    arrow
                                    placement="top"
                                  >
                                    <EllipseData width="auto">
                                      {value?.order_no}
                                    </EllipseData>
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            ))}
                          </CustomizeTableCell>
                          <CustomizeTableCell textAlign="center">
                            {item.new_value.map((value, i) => (
                              <TableRow key={i}>
                                <TableCell>
                                  <Tooltip
                                    title={value?.skill}
                                    arrow
                                    placement="top"
                                  >
                                    <EllipseData width="145px">
                                      {value?.skill}
                                    </EllipseData>
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            ))}
                          </CustomizeTableCell>
                          <CustomizeTableCell textAlign="center">
                            {item.new_value.map((value, i) => (
                              <TableRow key={i}>
                                <TableCell>
                                  <Tooltip
                                    title={value?.relevancy_score}
                                    arrow
                                    placement="top"
                                  >
                                    <EllipseData width="auto">
                                      {value?.relevancy_score}
                                    </EllipseData>
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            ))}
                          </CustomizeTableCell>
                          <CustomizeTableCell textAlign="center">
                            {item.new_value.map((value, i) => (
                              <CustomizeTableRow
                                key={i}
                                display="grid"
                                marginBottom="10px"
                              >
                                <CustomizeTableCell>
                                  <Tooltip
                                    title={value?.skill_availability_status}
                                    arrow
                                    placement="top"
                                  >
                                    <EllipseData width="auto">
                                      {value?.skill_availability_status}
                                    </EllipseData>
                                  </Tooltip>
                                </CustomizeTableCell>
                              </CustomizeTableRow>
                            ))}
                          </CustomizeTableCell>
                        </TableRow>
                      </React.Fragment>
                    </TableBody>
                  ) : (
                    <TableBody>
                      <TableRow>
                        <TableCell
                          component="th"
                          sx={{ fontWeight: "800 !important" }}
                        >
                          Old Value
                        </TableCell>

                        {item.field !== "candidates" &&
                          typeof item?.old_value === "object" &&
                          !Array.isArray(item?.old_value) &&
                          item?.old_value !== null &&
                          Object.keys(item?.old_value).map((key) => {
                            if (key === "id" || key === "job_info") return null;

                            return (
                              <TableCell
                                key={item.old_value[key]}
                                className={item.old_value[key]}
                              >
                                {item.old_value[key] || "-"}
                              </TableCell>
                            );
                          })}
                        {item.field !== "candidates" &&
                          Array.isArray(item?.old_value) &&
                          item?.old_value.map((list, index) => {
                            if (list?.content === undefined) return null;
                            return (
                              <TableCell>
                                <DialogTableList
                                  gap="10px"
                                  display="flex"
                                  flexWrap="wrap"
                                >
                                  {list?.content?.map((data) => (
                                    <DialogTableList
                                      border="1px solid"
                                      padding="0px 3px"
                                      borderRadius="4px"
                                    >
                                      {data}
                                    </DialogTableList>
                                  ))}
                                </DialogTableList>
                              </TableCell>
                            );
                          })}

                        {item.field !== "candidates" &&
                          (item.field === "shift" ||
                            item.field === "location_targets") &&
                          Array.isArray(item?.old_value) && (
                            <TableCell>
                              <DialogTableList
                                gap="10px"
                                display="flex"
                                flexWrap="wrap"
                              >
                                {item?.old_value.map((list, index) => {
                                  return (
                                    <DialogTableList
                                      border="1px solid"
                                      padding="0px 3px"
                                      borderRadius="4px"
                                    >
                                      {list}
                                    </DialogTableList>
                                  );
                                })}
                              </DialogTableList>
                            </TableCell>
                          )}

                        {item.field !== "candidates" &&
                          typeof item?.old_value !== "object" &&
                          !Array.isArray(item?.old_value) &&
                          (item.field[0] === "order" ||
                          item.field === "rescore" ? (
                            item.candidates.map((candidate) => (
                              <CustomizeTableCell textAlign="center">
                                {candidate?.old_value}
                              </CustomizeTableCell>
                            ))
                          ) : (
                            <TableCell>
                              {typeof item?.old_value === "boolean"
                                ? item?.old_value
                                  ? "True"
                                  : "False"
                                : item?.old_value || "-"}
                            </TableCell>
                          ))}
                        {item.field === "candidates" && (
                          <TableCell>
                            {item?.old_value?.length == 0
                              ? "-"
                              : item?.old_value}
                          </TableCell>
                        )}
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "800 !important" }}>
                          New Value
                        </TableCell>
                        {item.field !== "candidates" &&
                          typeof item?.new_value === "object" &&
                          !Array.isArray(item?.new_value) &&
                          item?.new_value !== null &&
                          Object.keys(item?.new_value).map((key) => {
                            if (key === "id" || key === "job_info") return null;

                            return (
                              <TableCell key={item.new_value[key]}>
                                {item.new_value[key] || "-"}
                              </TableCell>
                            );
                          })}
                        {item.field !== "candidates" &&
                          Array.isArray(item?.new_value) &&
                          item?.new_value.map((list, index) => {
                            if (list?.content === undefined) return null;
                            return (
                              <TableCell>
                                <DialogTableList
                                  gap="10px"
                                  display="flex"
                                  flexWrap="wrap"
                                >
                                  {list?.content?.map((data) => (
                                    <DialogTableList
                                      border="1px solid"
                                      padding="0px 3px"
                                      borderRadius="4px"
                                    >
                                      {data}
                                    </DialogTableList>
                                  ))}
                                </DialogTableList>
                              </TableCell>
                            );
                          })}

                        {item.field !== "candidates" &&
                          (item.field === "shift" ||
                            item.field === "location_targets") &&
                          Array.isArray(item?.new_value) && (
                            <TableCell>
                              <DialogTableList
                                gap="10px"
                                display="flex"
                                flexWrap="wrap"
                              >
                                {item?.new_value.map((list, index) => {
                                  return (
                                    <DialogTableList
                                      border="1px solid"
                                      padding="0px 3px"
                                      borderRadius="4px"
                                    >
                                      {list}
                                    </DialogTableList>
                                  );
                                })}
                              </DialogTableList>
                            </TableCell>
                          )}

                        {item.field !== "candidates" &&
                          typeof item?.new_value !== "object" &&
                          !Array.isArray(item?.new_value) &&
                          (item.field[0] === "order" ||
                          item.field === "rescore" ? (
                            item.candidates.map((candidate) => (
                              <CustomizeTableCell textAlign="center">
                                {candidate?.new_value}
                              </CustomizeTableCell>
                            ))
                          ) : (
                            <TableCell>
                              {typeof item?.new_value === "boolean"
                                ? item?.new_value
                                  ? "True"
                                  : "False"
                                : item?.new_value || "-"}
                            </TableCell>
                          ))}
                        {item.field === "candidates" && (
                          <TableCell>
                            {item?.new_value?.length == 0
                              ? "-"
                              : item.new_value}
                          </TableCell>
                        )}
                      </TableRow>
                    </TableBody>
                  )}
                </CustomTable>
              </TableContainerBorder>
            </AccordionDetails>
          </CustomAccordion>
        );
      })}
    </Dialog>
  );
};
export default SummaryInteractionDialog;

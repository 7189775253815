import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  styled,
} from "@mui/material";
import React from "react";

const FormControlField = styled(FormControl)(
  ({
    marginTop,
    InputPadding,
    borderRadius,
    sizeMedium,
    removeAnimation,
    customStyle,
  }) => ({
    marginTop: marginTop || "8px",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D0D5DD",
      borderRadius: borderRadius || "8px",
    },
    "& .MuiInputLabel-formControl": {
      fontFamily: "Inter",
    },
    "& .MuiSelect-nativeInput": {
      color: "#667085",
      fontFamily: "Inter",
    },
    "& #demo-simple-select": {
      padding: InputPadding || "12px",
    },
    "& label.MuiInputLabel-sizeMedium": {
      top: sizeMedium || "-6px",
    },
    "& label.MuiInputLabel-shrink": {
      top: "0px",
    },
    "& .MuiFormHelperText-root": {
      fontWeight: 600,
      margin: "3px 0px",
    },
    ...(removeAnimation && {
      "& .MuiFormLabel-filled": {
        display: "none",
      },
    }),
    ...customStyle,
  })
);
const DropDownSelect = ({
  data,
  title,
  value,
  handleChange,
  marginTop,
  InputPadding,
  borderRadius,
  sizeMedium,
  error,
  helperText,
  valueChange,
  CustomMenuItem,
  removeAnimation,
  customStyle,
  disabled,
}) => {
  return (
    <FormControlField
      fullWidth
      marginTop={marginTop}
      InputPadding={InputPadding}
      borderRadius={borderRadius}
      sizeMedium={sizeMedium}
      error={error}
      removeAnimation={removeAnimation}
      customStyle={customStyle}
    >
      <InputLabel id="demo-simple-select-label">{title}</InputLabel>
      <Select
        disabled={disabled}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
        label={removeAnimation && value ? "" : title}
        onChange={handleChange}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 210,
            },
          },
        }}
      >
        {data &&
          data.map((item, index) =>
            valueChange ? (
              CustomMenuItem(item, index)
            ) : (
              <MenuItem value={item?.name} key={index}>
                {item?.name}
              </MenuItem>
            )
          )}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControlField>
  );
};

export default DropDownSelect;

function JobEnrichmentIco({ color }) {
  return (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.3545 7.41792H20.6455M8.96936 2L6.93873 7.41792L11 17.8023L15.0612 7.41792L13.0306 2M11.624 17.5878L20.7198 7.88039C20.874 7.71586 20.9511 7.6336 20.9805 7.54178C21.0065 7.46082 21.0065 7.37502 20.9805 7.29406C20.9511 7.20223 20.874 7.11998 20.7198 6.95546L16.3201 2.25992C16.2306 2.16438 16.1859 2.11661 16.131 2.08227C16.0823 2.05184 16.0281 2.02924 15.9707 2.0155C15.9059 2 15.836 2 15.6961 2H6.30385C6.164 2 6.09409 2 6.02929 2.0155C5.97189 2.02924 5.91764 2.05184 5.86901 2.08227C5.81415 2.11661 5.76938 2.16438 5.67986 2.25992L1.28016 6.95546C1.12601 7.11997 1.04893 7.20223 1.01947 7.29406C0.99351 7.37502 0.99351 7.46082 1.01947 7.54177C1.04893 7.6336 1.12601 7.71586 1.28016 7.88039L10.376 17.5878C10.5903 17.8165 10.6974 17.9309 10.8257 17.9726C10.9382 18.0091 11.0617 18.0091 11.1743 17.9726C11.3025 17.9309 11.4097 17.8165 11.624 17.5878Z"
        stroke="#667085"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default JobEnrichmentIco;

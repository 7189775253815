import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { Grid2, TableCell, TableRow, Typography } from "@mui/material";
import React from "react";
import CommonButton from "../../../../component/Button";
import {
  CommonGrid2,
  DotColor,
  StatusChange,
} from "../../../common-components/StyledComponents";
import { SelectCreateCard } from "../../style";
import JobTable from "../../Table";
import CommonTable from "../../../candidates/Table";

const SelectCreationModel = ({
  selectCreateData,
  headerData,
  handleContinue,
  createJobList,
  sortItem,
  handleTableBodyData,
  handlePageChange,
}) => {
  return (
    <Grid2 container spacing={3}>
      {selectCreateData.map((item) => (
        <CommonGrid2
          item
          size={{ xs: 6, md: 6, sm: 12 }}
          flexDirection={"column"}
          display={"flex"}
          gap={"1rem"}
        >
          <SelectCreateCard>
            <Typography variant="h5">{item.name}</Typography>
            <Typography variant="p" fontSize={"15px"} fontWeight={500}>
              {item.description}
            </Typography>
            <CommonButton
              variant="outlined"
              color="#344054"
              border="#D0D5DD"
              fontWeight="600"
              padding={"8px 20px"}
              value="Continue"
              width="max-content"
              boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
              onClick={() => handleContinue(item)}
              disabled={item.disabled}
            />
          </SelectCreateCard>
        </CommonGrid2>
      ))}
      <CommonTable
        data={createJobList.data}
        columns={headerData}
        sortedColumns={sortItem}
        onSortChange={(col) => console.log("Sort by", col)}
        handleTableBodyData={handleTableBodyData}
        paginationProps={{
          count: createJobList.totalPage,
          page: createJobList.currentPage,
          onPageChange: (page) => handlePageChange(page),
        }}
        withPagination={true}
        loading={createJobList.loading}
      />
    </Grid2>
  );
};
export default SelectCreationModel;

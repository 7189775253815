import { Switch, Tooltip, Typography } from "@mui/material";
import { FastField, Field } from "formik";
import React from "react";
import "tippy.js/dist/tippy.css";
import CommonButton from "../../../../component/Button";
import { CommonGrid2 } from "../../../common-components/StyledComponents";
import SkillTable from "./SkillTable";

const Skills = ({
  alignment,
  handleAddNewSkill,
  jobModifyData,
  handleSkillToogleChange,
  handleSkillTextChange,
  isLoading,
  highPriority,
  lowPriority,
  mediumPriority,
  recommended,
  headerData,
  handleDeleteSkillRow,
}) => {
  const skillSections = [
    {
      title: "Highest Priority (Core)",
      fieldName: "high_priority",
      rowData: highPriority,
      scoreFieldName: "score_hard_skills",
    },
    {
      title: "High Priority (Complementary)",
      fieldName: "low_priority",
      rowData: lowPriority,
      scoreFieldName: "score_soft_skills",
    },
    {
      title: "Recommended based on Historical/Empirical data",
      fieldName: "recommended",
      rowData: recommended,
      scoreFieldName: "score_soft_skills",
    },
    {
      title: "Medium Priority",
      fieldName: "medium_priority",
      rowData: mediumPriority,
      scoreFieldName: "score_soft_skills",
    },
  ];

  return (
    <>
      {alignment === "Edit" && (
        <Field name="skillData">
          {({ form }) => {
            return (
              <Tooltip
                className={form?.errors?.["checkcondition"] && "Mui-error"}
                open={form?.errors?.["checkcondition"] ? true : false}
                title={form?.errors?.["checkcondition"]}
                arrow
                zIndex={9}
                placement="right"
                PopperProps={{
                  sx: {
                    zIndex: 1300,
                  },
                }}
                componentsProps={{
                  tooltip: {
                    sx: {
                      backgroundColor: "red",
                      "& .MuiTooltip-arrow": {
                        "&::before": {
                          backgroundColor: "red",
                        },
                      },
                    },
                  },
                }}
              >
                <CommonGrid2 width={"max-content"}>
                  <CommonButton
                    variant="outlined"
                    color="#344054"
                    border="#D0D5DD"
                    padding="9px 20px"
                    fontWeight="600"
                    value="Add New"
                    onClick={() => handleAddNewSkill(form)}
                  />
                </CommonGrid2>
              </Tooltip>
            );
          }}
        </Field>
      )}
      <CommonGrid2
        container
        flexDirection="row"
        columnSpacing={2}
        rowSpacing={4}
        marginTop={alignment === "Edit" ? "2rem" : "0px"}
      >
        {skillSections.map(({ title, fieldName, rowData, scoreFieldName }) => {
          return (
            <Field>
              {({ form }) =>
                (alignment === "Edit"
                  ? form?.values?.["skillData"]?.[fieldName]?.length !== 0
                  : rowData?.length !== 0) && (
                  <CommonGrid2 size={6}>
                    <CommonGrid2
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography variant="h5" fontWeight={500}>
                        {title}
                      </Typography>
                      {alignment === "Edit" && (
                        <Field name={scoreFieldName}>
                          {({ field, form }) => (
                            <Switch
                              checked={form.values[scoreFieldName]}
                              onChange={(event) =>
                                form.setFieldValue(
                                  scoreFieldName,
                                  event.target.checked
                                )
                              }
                            />
                          )}
                        </Field>
                      )}
                    </CommonGrid2>
                    <SkillTable
                      rowData={
                        alignment === "Edit"
                          ? form?.values?.["skillData"]?.[fieldName]
                          : rowData
                      }
                      name={fieldName}
                      jobModifyData={jobModifyData}
                      handleSkillToogleChange={handleSkillToogleChange}
                      headerData={headerData}
                      handleDeleteSkillRow={handleDeleteSkillRow}
                      handleSkillTextChange={handleSkillTextChange}
                      alignment={alignment}
                      isLoading={isLoading}
                      form={form}
                    />
                  </CommonGrid2>
                )
              }
            </Field>
          );
        })}
      </CommonGrid2>
    </>
  );
};

export default Skills;

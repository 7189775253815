import { Grid2, Skeleton, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";
import CommonTextField from "../../../../component/Input";
import DropDownSelect from "../../../../component/Select";
import {
  CommonGrid2,
  StyledFlexColumnView,
} from "../../../common-components/StyledComponents";
import FileUpload from "../../../quick-match/components/FileUpload";
import CommonInputJd from "./CommonInputJd";
import { CommonTippy } from "../../style";
import { StyledLinearContainer } from "../../../quick-match/components/StyledComponent";
import CommonLinearProgress from "../../../../component/Linearprogress";

function InputJobData({
  inputJdView,
  handleButtonCommon,
  handleFileUpload,
  enrichFile,
  buttonLoading,
  handleInputJobDeleteFile,
  handleInputJobDragOver,
  handleInputJobDragLeave,
  handleInputJobDrop,
  inputJobDrag,
  list,
  titleInitialValues,
  JDInitialValues,
  validationSchemaTitle,
  handleTitleSubmit,
  validationSchemaJD,
  handleJDSubmit,
}) {
  return (
    <>
      {inputJdView === 1 ? (
        <CommonInputJd
          title={"Enrich Job Post with Job Code/VMS ID"}
          buttonName={"Import Job Post"}
          loading={buttonLoading}
          disabled={enrichFile?.length === 0}
          handleButtonCommon={() => handleButtonCommon(inputJdView)}
          marginTop="-1.5rem"
        >
          <FileUpload
            onDragOver={handleInputJobDragOver}
            onDragLeave={handleInputJobDragLeave}
            onDrop={handleInputJobDrop}
            onChange={handleFileUpload}
            isDragging={inputJobDrag}
            files={enrichFile}
            handleDeleteFile={handleInputJobDeleteFile}
            disabled={buttonLoading}
          />
        </CommonInputJd>
      ) : null}

      {inputJdView === 2 ? (
        <Formik
          initialValues={JDInitialValues}
          onSubmit={handleJDSubmit}
          validationSchema={validationSchemaJD}
        >
          {({ values, handleChange, setFieldValue, errors, touched }) => {
            return (
              <Form>
                <CommonInputJd
                  title={"Create Job Post with Talairo Helix"}
                  buttonName={"Generate Job Post"}
                  disabled={list.disabled || list.loading}
                  loading={list.buttonLoading}
                >
                  <Grid2 container spacing={2}>
                    <Grid2 size={12}>
                      <CommonGrid2
                        display={"flex"}
                        gap={list.loading ? "0px" : "10px"}
                        flexDirection={"column"}
                      >
                        <Typography
                          fontWeight={500}
                          fontSize={"16px"}
                          color={"#344054"}
                        >
                          {"Industry"}
                        </Typography>
                        {list.loading ? (
                          <Skeleton height={50} />
                        ) : (
                          <DropDownSelect
                            value={values["industry"]}
                            marginTop="0px"
                            title={"Select Industry"}
                            removeAnimation={true}
                            data={list?.industry}
                            handleChange={(event) => {
                              setFieldValue("industry", event.target.value);
                            }}
                          />
                        )}
                      </CommonGrid2>
                    </Grid2>
                    <Grid2 size={12}>
                      <CommonGrid2
                        display={"flex"}
                        gap={list.loading ? "0px" : "10px"}
                        flexDirection={"column"}
                      >
                        <Typography
                          fontWeight={500}
                          fontSize={"16px"}
                          color={"#344054"}
                        >
                          {"Location"}
                        </Typography>
                        {list.loading ? (
                          <Skeleton height={50} />
                        ) : (
                          <CommonTextField
                            value={values["location"]}
                            placeholder="Enter a Location"
                            padding="11px"
                            placeholderFontSize={"15px"}
                            onChange={(event) => {
                              setFieldValue("location", event.target.value);
                            }}
                          />
                        )}
                      </CommonGrid2>
                    </Grid2>

                    <Grid2 size={12}>
                      <CommonGrid2
                        display={"flex"}
                        gap={list.loading ? "0px" : "10px"}
                        flexDirection={"column"}
                      >
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: "16px",
                            color: "#344054",
                          }}
                        >
                          {"Occupational Classification"}
                        </Typography>
                        {list.loading ? (
                          <Skeleton height={50} />
                        ) : (
                          <DropDownSelect
                            value={values["occupational_classification"]}
                            marginTop="0px"
                            title={"Occupational Classification"}
                            removeAnimation={true}
                            data={list?.classification}
                            handleChange={(event) => {
                              setFieldValue(
                                "occupational_classification",
                                event.target.value
                              );
                            }}
                          />
                        )}
                      </CommonGrid2>
                    </Grid2>

                    <Grid2 size={12}>
                      <CommonGrid2
                        display={"flex"}
                        gap={list.loading ? "0px" : "10px"}
                        flexDirection={"column"}
                      >
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: "16px",
                            color: "#344054",
                          }}
                        >
                          {"Job Description"}
                        </Typography>
                        {list.loading ? (
                          <Skeleton height={50} />
                        ) : (
                          <CommonTextField
                            value={values["sample_jd_text"]}
                            error={Boolean(
                              touched["sample_jd_text"] &&
                                errors["sample_jd_text"]
                            )}
                            helperText={
                              touched["sample_jd_text"] &&
                              errors["sample_jd_text"]
                            }
                            placeholder="Enter Job Description"
                            padding="0px"
                            placeholderFontSize={"15px"}
                            multiline
                            rows={4}
                            fontSize={"15px"}
                            onChange={(event) => {
                              setFieldValue(
                                "sample_jd_text",
                                event.target.value
                              );
                            }}
                          />
                        )}
                      </CommonGrid2>
                    </Grid2>
                    {list.progress >= 0 && (
                      <Grid2
                        size={12}
                        marginBottom={"-1rem"}
                        marginTop={"0.5rem"}
                      >
                        <CommonTippy
                          content={list.message}
                          visible={list.visible}
                          interactive={true}
                          trigger="click"
                          hideOnClick={false}
                          zIndex={2}
                          color="red"
                        >
                          <StyledLinearContainer>
                            <CommonLinearProgress
                              variant={
                                list.progress && !list.visible
                                  ? "buffer"
                                  : "determinate"
                              }
                              value={list.progress}
                              valueBuffer={list.buffer}
                              height={"8px"}
                              borderRadius={"2px"}
                              backgroundColor={"#E0E0E0"}
                              barbackgroundColor={
                                list.visible ? "red" : "#7F56D9"
                              }
                              dashedmarginTop={"2px"}
                              dashedbackgroundImage={
                                list.visible
                                  ? "radial-gradient(rgb(255 46 0) 0%, rgb(255 0 0) 16%, #00000000 42%)"
                                  : "radial-gradient(rgb(82 0 255) 0%, rgb(82 0 255) 16%, #00000000 42%)"
                              }
                            />

                            <Typography
                              color="#344054"
                              fontSize={"14px"}
                              fontWeight={500}
                            >
                              {list.progress}%
                            </Typography>
                          </StyledLinearContainer>
                        </CommonTippy>
                      </Grid2>
                    )}
                  </Grid2>
                </CommonInputJd>
              </Form>
            );
          }}
        </Formik>
      ) : null}

      {inputJdView === 3 ? (
        <Formik
          initialValues={titleInitialValues}
          onSubmit={handleTitleSubmit}
          validationSchema={validationSchemaTitle}
        >
          {({ values, handleChange, setFieldValue, errors, touched }) => {
            return (
              <Form>
                <CommonInputJd
                  title={"Generate Job Post"}
                  buttonName={"Generate Job Post"}
                  disabled={list.disabled || list.loading}
                  loading={list.buttonLoading}
                >
                  <Grid2 container spacing={2}>
                    <Grid2 size={12}>
                      <StyledFlexColumnView gap={list.loading ? "0px" : "10px"}>
                        <Typography
                          fontWeight={500}
                          fontSize={"16px"}
                          color={"#344054"}
                        >
                          {"Job Title"}
                        </Typography>
                        {list.loading ? (
                          <Skeleton height={50} />
                        ) : (
                          <CommonTextField
                            placeholder="Enter Job Title"
                            padding="11px"
                            placeholderFontSize={"15px"}
                            error={Boolean(touched["title"] && errors["title"])}
                            helperText={touched["title"] && errors["title"]}
                            value={values["title"]}
                            onChange={(event) => {
                              setFieldValue("title", event.target.value);
                            }}
                          />
                        )}
                      </StyledFlexColumnView>
                    </Grid2>

                    <Grid2 size={12}>
                      <StyledFlexColumnView gap={list.loading ? "0px" : "10px"}>
                        <Typography
                          fontWeight={500}
                          fontSize={"16px"}
                          color={"#344054"}
                        >
                          {"Industry"}
                        </Typography>
                        {list.loading ? (
                          <Skeleton height={50} />
                        ) : (
                          <DropDownSelect
                            marginTop="0px"
                            title={"Select Industry"}
                            removeAnimation={true}
                            data={list?.industry}
                            error={Boolean(
                              touched["industry"] && errors["industry"]
                            )}
                            helperText={
                              touched["industry"] && errors["industry"]
                            }
                            value={values["industry"]}
                            handleChange={(event) => {
                              setFieldValue("industry", event.target.value);
                            }}
                          />
                        )}
                      </StyledFlexColumnView>
                    </Grid2>

                    <Grid2 size={12}>
                      <StyledFlexColumnView gap={list.loading ? "0px" : "10px"}>
                        <Typography
                          fontWeight={500}
                          fontSize={"16px"}
                          color={"#344054"}
                        >
                          {"Company"}
                        </Typography>
                        {list.loading ? (
                          <Skeleton height={50} />
                        ) : (
                          <CommonTextField
                            placeholder="Enter Company"
                            padding="11px"
                            placeholderFontSize={"15px"}
                            error={Boolean(
                              touched["company"] && errors["company"]
                            )}
                            helperText={touched["company"] && errors["company"]}
                            value={values["company"]}
                            onChange={(event) => {
                              setFieldValue("company", event.target.value);
                            }}
                          />
                        )}
                      </StyledFlexColumnView>
                    </Grid2>
                  </Grid2>
                </CommonInputJd>
              </Form>
            );
          }}
        </Formik>
      ) : null}

      {inputJdView === 4 || inputJdView === 5 ? (
        <CommonInputJd
          title={"Create Job Post using Resume"}
          buttonName={"Convert Resume into Job Post"}
        >
          <StyledFlexColumnView>
            <Typography fontWeight={500} fontSize={"16px"} color={"#344054"}>
              {"Resume"}
            </Typography>
            <CommonTextField
              readOnly={true}
              handleFileChange={""}
              value={""}
              padding="12px"
              placeholder={"Choose a file"}
            />
          </StyledFlexColumnView>
        </CommonInputJd>
      ) : null}
    </>
  );
}

export default InputJobData;

import React from "react";
import CommonCard from "../../common-card.js/index.js";
import EditIndex from "../../common-card.js/Edit-Index.js";

const SecondaryInformation = ({
  SecondaryViewData,
  isLoading,
  alignment,
  marginTop,
  viewMarginTop,
}) => {
  return (
    <>
      {alignment === "View" ? (
        <CommonCard
          viewData={SecondaryViewData}
          loading={isLoading}
          viewMarginTop={viewMarginTop}
        />
      ) : (
        <EditIndex
          marginTop={marginTop}
          editData={SecondaryViewData}
          loading={isLoading}
        />
      )}
    </>
  );
};
export default SecondaryInformation;

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2,
  Typography,
} from "@mui/material";
import React from "react";
import * as Yup from "yup";
import CommonButton from "../../../../component/Button";
import XCloseIco from "../../../../assets/icons/XCloseIco";
import { Field, Form, Formik } from "formik";
import CommonTextField from "../../../../component/Input";
import { CommonGrid2 } from "../../../common-components/StyledComponents";

const AddSeededDialog = ({
  open,
  handleDialogClose,
  handleSubmit,
  loading,
}) => {
  const validationSchema = Yup.object({
    question: Yup.string()
      .required("Question is required")
      .matches(/^[^\s].*$/, "Question cannot start with spaces")
      .test(
        "no-whitespace",
        "Question cannot be just whitespace",
        (value) => value && value.trim().length > 0
      ),
  });

  const initialValues = {
    question: "",
  };

  return (
    <Dialog
      fullWidth
      maxWidth={"sm"}
      open={open}
      onClose={handleDialogClose}
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "12px",
          background: "#FFF",
          boxShadow:
            "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
        },
      }}
    >
      <DialogActions>
        <Button onClick={handleDialogClose}>
          <XCloseIco />
        </Button>
      </DialogActions>
      <DialogTitle>
        <Typography variant="h3">{"Pre-Screen Questions"}</Typography>
      </DialogTitle>

      <DialogContent>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,

            setFieldValue,
          }) => {
            return (
              <Form>
                <Grid2 spacing={2} container>
                  <CommonGrid2 size={12}>
                    <Grid2 marginBottom={"5px"}>
                      <Typography
                        variant="p"
                        color={
                          errors.question && touched.question
                            ? "error"
                            : "#344054"
                        }
                        fontSize={14}
                        fontFamily={"Inter"}
                        fontWeight={500}
                      >
                        Question
                      </Typography>
                    </Grid2>

                    <Field
                      name="question"
                      component={CommonTextField}
                      padding={"10px"}
                      top="10px"
                      border={
                        errors.question &&
                        touched.question &&
                        "1px solid #d32f2f"
                      }
                      placeholder="Enter Question"
                      placeholderFontSize="14px"
                      value={values.question}
                      onChange={(e) => {
                        setFieldValue("question", e.target.value);
                      }}
                    />
                    {errors.question && touched.question && (
                      <Typography color="error" variant="body2">
                        {errors.question}
                      </Typography>
                    )}
                  </CommonGrid2>

                  <Grid2 size={12} marginTop={"0.5rem"}>
                    <CommonButton
                      variant="contained"
                      color="white"
                      background="#7F56D9"
                      fontWeight="600"
                      width={"100%"}
                      padding={"10px 14px"}
                      value="Add Question"
                      type="submit"
                      disabled={loading}
                      loading={loading}
                    />
                  </Grid2>
                </Grid2>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default AddSeededDialog;

import React from "react";
import CommonCard from "../../common-card.js";
import EditIndex from "../../common-card.js/Edit-Index.js";
import { Grid2, Typography } from "@mui/material";

function InterviewSettings({
  InterviewSettingData,
  isLoading,
  alignment,
  rowSize,
}) {
  return (
    <Grid2 size={12}>
      <Typography variant="h5" fontWeight={500} margin={"2rem 0px 1.5rem"}>
        Interview Settings
      </Typography>

      {alignment === "View" ? (
        <CommonCard
          viewData={InterviewSettingData}
          loading={isLoading}
          rowSize={rowSize}
        />
      ) : (
        <EditIndex
          editData={InterviewSettingData}
          loading={isLoading}
          rowSize={rowSize}
        />
      )}
    </Grid2>
  );
}

export default InterviewSettings;

import React from "react";
import EditIndex from "../../common-card.js/Edit-Index.js";
import CommonCard from "../../common-card.js/index.js";

const PrimaryInformation = ({
  primaryViewData,
  alignment,
  isLoading,
  menuItemData,
}) => {
  return (
    <>
      {alignment === "View" ? (
        <CommonCard viewData={primaryViewData} loading={isLoading} />
      ) : (
        <EditIndex
          editData={primaryViewData}
          menuItemData={menuItemData}
          loading={isLoading}
        />
      )}
    </>
  );
};
export default PrimaryInformation;

import { Avatar, Box, Divider, Grid2, Stack, Typography } from "@mui/material";
import React from "react";
import CommonButton from "../../../../component/Button";
import { HandleSkeletion } from "../../../../utils/constants";
import {
  CommonGrid2,
  StyledFlexRowView,
} from "../../../common-components/StyledComponents";

function Profile({
  handleScrollToError,
  errors,
  coverSheet,
  loading,
  submitLoading,
  view_resume,
}) {
  return (
    <Grid2 container spacing={4}>
      <Grid2 size={12}>
        <Divider />
      </Grid2>

      <CommonGrid2
        size={12}
        display={"flex"}
        justifyContent={"space-between"}
        gap={"10px"}
        alignItems={"center"}
      >
        <CommonGrid2
          size={{
            xs: 12,
            md: view_resume !== "true" ? 9.5 : 12,
            sm: view_resume !== "true" ? 8 : 12,
          }}
        >
          <Stack direction="row" spacing={2} alignItems={"center"}>
            <Avatar
              alt={coverSheet?.personal_details?.candidate_name || "Avatar"}
              src={coverSheet?.personal_details?.avatar_url}
              sx={{
                width: 64,
                height: 64,
                backgroundColor: "#F2F4F7",
                color: "#667085",
                // border: "1px solid #D0D5DD",
                borderRadius: "unset",
                borderTopLeftRadius: "30px",
                borderTopRightRadius: "30px",
                borderBottomLeftRadius: "30px",
                borderBottomRightRadius: "30px",
              }}
            >
              {loading ? (
                <HandleSkeletion height={35} width={30} />
              ) : (
                !coverSheet?.personal_details?.avatar_url &&
                coverSheet?.personal_details?.candidate_name?.[0]?.toUpperCase()
              )}
            </Avatar>
            <Box width={"100%"}>
              <StyledFlexRowView>
                {loading ? (
                  <HandleSkeletion height={45} width={200} />
                ) : (
                  <Typography fontSize={"24px"} fontWeight={600}>
                    {coverSheet?.personal_details?.first_name}
                  </Typography>
                )}
              </StyledFlexRowView>
              {/* {loading ? (
                <HandleSkeletion height={50} width={"100%"} />
              ) : (
                <Typography color={"#475467"} textAlign={"justify"}>
                  {coverSheet?.personal_details?.candidate_bio}
                </Typography>
              )} */}
            </Box>
          </Stack>
        </CommonGrid2>
        {view_resume !== "true" && (
          <CommonGrid2 size={{ xs: 12, md: 2.5, sm: 4 }} textAlign={"end"}>
            <CommonButton
              variant="contained"
              color="white"
              background="#7F56D9"
              fontWeight="600"
              padding={"10px 15px"}
              value="Submit Changes"
              type="submit"
              width={"max-content"}
              disabled={loading || submitLoading}
              loading={submitLoading}
              onClick={() => {
                setTimeout(() => {
                  handleScrollToError(errors);
                }, 0);
              }}
            />
          </CommonGrid2>
        )}
      </CommonGrid2>
      <Grid2 size={12}>
        <Divider />
      </Grid2>
    </Grid2>
  );
}

export default Profile;

import { Menu, styled } from "@mui/material";
import { ToastContainer } from "react-toastify";

export const NotificationRelative = styled("div")(() => ({
  position: "fixed",
  bottom: "2rem",
  padding: "3px",
  zIndex: 99,
  right: "2.2rem",
  borderRadius: "50%",
  background: "#fff",
  boxShadow: "1px 1px 5px 0px rgba(16, 24, 40, 0.43)",
  "& svg": {
    width: "25px",
    height: "25px",
    fill: "#7F56D9",
  },
}));

export const ArrowMenu = styled("div")(({}) => ({
  // "& .MuiPaper-root": {
  position: "relative",
  width: "max-content",
  right: "1.5rem",
  margin: "0px 0px 0px auto",
  bottom: "6rem",
  borderRadius: "10px",
  boxShadow: "1px 1px 5px 0px rgba(16, 24, 40, 0.43)",
  overflow: "visible",
  padding: "10px",
  gap: "10px",
  background: "#fff",
  zIndex: 999,
  "&::before": {
    content: '""',
    position: "absolute",
    bottom: "-8px",
    right: "8%",
    width: 0,
    height: 0,
    borderLeft: "8px solid transparent",
    borderRight: "8px solid transparent",
    borderTop: "8px solid #fff",
    filter: "drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2))",
  },
  // },
}));

export const StyleToastContainer = styled(ToastContainer)(() => ({
  "& .Toastify__toast": {
    padding: "25px 10px 0px",
    justifyContent: "center",
  },
}));

import { Navigate, Route, Routes } from "react-router-dom";
import AuthLayout from "../layout/AuthLayout";
import PortalLayout from "../layout/PortalLayout";
import AuthLogin from "../modules/auth/login";
import Candidates from "../modules/candidates";
import CandidatePerspective from "../modules/candidates/candidate-perspective";
import CandidateView from "../modules/candidates/candidate-view";
import Client from "../modules/client";
import Jobs from "../modules/jobs";
import CreateJob from "../modules/jobs/create-job";
import JobsBoard from "../modules/jobs/jobs-board";
import JobsCandidate from "../modules/jobs/jobs-candidate";
import JobsCandidateCompare from "../modules/jobs/jobs-candidate-compare";
import JobsSummary from "../modules/jobs/jobs-summary";
import JobsView from "../modules/jobs/jobs-view";
import Horizon from "../modules/horizon";
import QuickMatch from "../modules/quick-match";
import ResumeIntelligence from "../modules/resume-intelligence";
import CreateCandidateResume from "../modules/resume-intelligence/create-candidate-resume";
import Settings from "../modules/settings";
import AddGroup from "../modules/settings/components/AddGroup";
import ChangePassword from "../modules/settings/components/ChangePassword";
import ChangeUsername from "../modules/settings/components/ChangeUsername";

const MainRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<PortalLayout />}>
        <Route index element={<Navigate to="/auth/login" />} />
        <Route path="/horizon" element={<Horizon />} />
        <Route path="/reusme-intelligence" element={<ResumeIntelligence />} />
        <Route
          path="/reusme-intelligence/create-candidate-resume"
          element={<CreateCandidateResume />}
        />
        <Route path="/jobs" element={<Jobs />} />
        <Route path="/jobs/jobs-view" element={<JobsView />} />
        <Route path="/jobs/create-jobs" element={<CreateJob />} />
        <Route path="/jobs/jobs-summary" element={<JobsSummary />} />
        <Route path="/jobs/jobs-candidate" element={<JobsCandidate />} />
        <Route
          path="/jobs/candidate/compare/:firstResumeId/:secondResumeId"
          element={<JobsCandidateCompare />}
        />
        <Route path="/user-management" element={<Settings />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/change-username" element={<ChangeUsername />} />
        <Route path="/add-group" element={<AddGroup />} />
        <Route path="/candidates" element={<Candidates />} />
        <Route
          path="/candidates/candidate-perspective"
          element={<CandidatePerspective />}
        />
        <Route path="/jobs/jobs-board" element={<JobsBoard />} />
        <Route path="/candidates/candidate-view" element={<CandidateView />} />
        <Route path="/tools/quick-match" element={<QuickMatch />} />
        <Route path="/client" element={<Client />} />
        <Route path="*" element={<> Not Found</>} />
      </Route>

      <Route path="/auth" element={<AuthLayout />}>
        <Route path="/auth/login" element={<AuthLogin />} />
      </Route>
    </Routes>
  );
};

export default MainRoutes;

function AmountIco() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1_10142)">
                <mask id="mask0_1_10142" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                    <path d="M24 0H0V24H24V0Z" fill="white" />
                </mask>
                <g mask="url(#mask0_1_10142)">
                    <path d="M20 7H5V16H20V7Z" stroke="#A9B4BE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M20 10C19.606 10 19.2159 9.92241 18.852 9.77164C18.488 9.62088 18.1573 9.3999 17.8787 9.12132C17.6001 8.84274 17.3791 8.51203 17.2284 8.14805C17.0776 7.78407 17 7.39397 17 7H20V10Z" stroke="#A9B4BE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M17 16C17 15.2043 17.3161 14.4413 17.8787 13.8787C18.4413 13.3161 19.2043 13 20 13V16H17Z" stroke="#A9B4BE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M5 13C5.79565 13 6.55871 13.3161 7.12132 13.8787C7.68393 14.4413 8 15.2043 8 16H5V13Z" stroke="#A9B4BE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M8 7C8 7.79565 7.68393 8.55871 7.12132 9.12132C6.55871 9.68393 5.79565 10 5 10V7H8Z" stroke="#A9B4BE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M12.5 13C13.3284 13 14 12.3284 14 11.5C14 10.6716 13.3284 10 12.5 10C11.6716 10 11 10.6716 11 11.5C11 12.3284 11.6716 13 12.5 13Z" stroke="#A9B4BE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_1_10142">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default AmountIco;

import { Menu, styled, TableCell, TableRow } from "@mui/material";
import Tippy from "@tippyjs/react";

export const PrimaryDiv = styled("div")`
  border: 1px solid #eaecf0;
  border-radius: 10px;
  padding: 12px 15px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  height: 100%;
`;

export const SecondaryShift = styled("div")`
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
`;

export const CustomizeTableCell = styled(TableCell)(
  ({
    fontWeight,
    display,
    justifyContent,
    gap,
    alignItems,
    textAlign,
    verticalAlign,
    width,
  }) => ({
    fontWeight: `${fontWeight} !important`,
    display: display,
    justifyContent: justifyContent,
    gap: gap,
    alignItems: alignItems,
    textAlign: textAlign,
    width: width,
    verticalAlign: verticalAlign,
  })
);

export const CustomizeTableRow = styled(TableRow)(
  ({ display, justifyContent, marginBottom, alignItems, textAlign }) => ({
    display: display,
    justifyContent: justifyContent,
    marginBottom: marginBottom,
    alignItems: alignItems,
    textAlign: textAlign,
  })
);

export const SkillCriticality = styled("div")`
  width: 100%;
  gap: 10px;
  display: flex;
  align-items: center;
`;

export const SelectCreateCard = styled("div")`
  border: 1px solid #eaecf0;
  border-radius: 15px;
  padding: 1.2rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
`;

export const CustomTooltipField = styled("div")(
  ({ fontSize, padding, size }) => ({
    position: "absolute",
    top: "-40px",
    left: "50%",
    transform: "translateX(-50%)",
    backgroundColor: "red",
    color: "#fff",
    padding: padding || "5px",
    fontSize: fontSize || "12px",
    borderRadius: "4px",
    whiteSpace: "nowrap",
    zIndex: 1000,
    "&::before": {
      content: '" "',
      position: "absolute",
      bottom: `-${size - 1}px`,
      left: "50%",
      transform: "translateX(-50%)",
      width: "0",
      height: "0",
      borderLeft: `${size}px solid transparent`,
      borderRight: `${size}px solid transparent`,
      borderTop: `${size}px solid red`,
    },
  })
);

export const CommonTippy = styled(Tippy)(
  ({ color, fontWeight, fontSize, textAlign }) => ({
    backgroundColor: `${color} !important`,
    fontWeight: fontWeight,
    textAlign: textAlign,
    fontSize: `${fontSize} !important`,
    "& .tippy-arrow": {
      color: `${color}`,
    },
  })
);

export const ErrorContent = styled("div")(({ fontSize, margin }) => ({
  margin: margin || "0px",
  fontSize: fontSize || "12px",
  fontWeight: "600",
  color: "#d32f2f",
}));
export const TooltipParagraph = styled("p")(
  ({
    color,
    fontWeight,
    textAlign,
    fontSize,
    display,
    justifyContent,
    gap,
    flexDirection,
  }) => ({
    margin: "0px",
    fontSize: fontSize,
    fontWeight: fontWeight,
    textAlign: textAlign,
    color: color,
    justifyContent: justifyContent,
    display: display,
    flexDirection: flexDirection,
    gap: gap,
  })
);

export const EllipseData = styled("span")(({ width }) => ({
  whiteSpace: "nowrap",
  width: width,
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "inline-block",
}));

export const ToogleParentContainer = styled("div")(() => ({
  border: "1px solid #EAECF0",
  backgroundColor: "#EAECF0",
  padding: "4px",
  borderRadius: "5px",
}));

export const ChatBotRelative = styled("div")(({}) => ({
  position: "fixed",
  bottom: "1rem",
  zIndex: 999,
  right: "1.5rem",
}));

export const CustomizeMenu = styled(Menu)(({}) => ({
  "& .MuiPaper-root": {
    marginTop: "5px",
    borderRadius: "5px",
    boxShadow: "0px 1px 5px 0px rgb(116 58 235 / 43%)",
    border: "1px solid rgb(127 86 217 / 51%)",
  },
}));

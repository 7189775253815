import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import GoogleLogo from "../../../assets/images/google-logo.png";
import Logo from "../../../assets/images/logo.png";
import CommonButton from "../../../component/Button";
import {
  StyledFlexColumnView,
  StyledFlexRowView,
} from "../../common-components/StyledComponents";
import { LoginCall } from "../../../redux/action/Login";
import SnackBar from "../../../component/SnackBar";

const validationSchema = Yup.object({
  email: Yup.string().required("Email is required"),
  password: Yup.string().required("Password is required"),
  // rememberMe: Yup.boolean().oneOf([true], "You must agree to remember me."),
});

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: "",
    severity: "",
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      rememberMe: false,
    },
    validationSchema,
    onSubmit: async (values, form) => {
      setButtonLoading(true);
      let payload = {
        username: values.email,
        password: values.password,
      };
      try {
        const data = await LoginCall(payload);
        window.localStorage.setItem("credential", JSON.stringify(data));
        setSnackbarState({
          open: true,
          severity: "success",
          message: "Login Successfull",
        });
        setButtonLoading(false);
        navigate("/horizon");
      } catch (error) {
        setButtonLoading(false);
        setSnackbarState({
          open: true,
          severity: "error",
          message: error?.message || "Something went wrong",
        });
      }
      // if (values.email !== "talario@gmail.com") {
      //   form.setFieldError("email", "Enter a valid email address");
      // }
      // if (values.password !== "talario@123") {
      //   form.setFieldError("password", "The password is incorrect");
      // } else {
      //   // const data =await Login()
      //   navigate("/dashboard");
      // }
    },
  });

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("credential"));
    if (data !== null) {
      navigate("/dashboard");
    }
  }, []);

  const handleShowPasswordToggle = () => setShowPassword((prev) => !prev);

  const renderTextField = (name, label, type = "text", placeholder) => (
    <StyledFlexColumnView gap="8px">
      <Typography
        color={
          formik.touched[name] && formik.errors[name] ? "#d32f2f" : "#5B738B"
        }
      >
        {label}
      </Typography>
      <div>
        <TextField
          size="small"
          type={
            type === "password" ? (showPassword ? "text" : "password") : "text"
          }
          fullWidth
          placeholder={placeholder}
          value={formik.values[name]}
          onChange={(e) => formik.setFieldValue(name, e.target.value)}
          error={formik.touched[name] && Boolean(formik.errors[name])}
          InputProps={{
            endAdornment: name === "password" && (
              <IconButton onClick={handleShowPasswordToggle} edge="end">
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            ),
          }}
          sx={{
            backgroundColor: "#EBF1F4",
            "& .Mui-error": { border: "2px solid #d32f2f" },
            "& fieldset": { border: "none" },
            borderRadius: "5px",
            height: "40px",
          }}
        />
        {formik.touched[name] && formik.errors[name] && (
          <Typography
            color="error"
            fontSize="11px"
            marginTop="5px"
            fontWeight={600}
          >
            {formik.errors[name]}
          </Typography>
        )}
      </div>
    </StyledFlexColumnView>
  );

  const handleCloseValidation = () => {
    setSnackbarState((prev) => ({
      ...prev,
      open: false,
    }));
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          height="100%"
          padding="40px"
          gap="12px"
        >
          <Box display="flex" justifyContent="center" alignItems="center">
            <img src={Logo} width="142px" height="32px" alt="Logo" />
          </Box>
          <Typography
            textAlign="center"
            fontWeight={700}
            fontSize="32px"
            color="#223548"
          >
            Welcome Back!
          </Typography>
          <Typography textAlign="center" color="#5B738B">
            Login to your account
          </Typography>

          {renderTextField(
            "email",
            "User name or Email ID",
            "text",
            "Enter user name or Email ID"
          )}
          {renderTextField(
            "password",
            "Password",
            "password",
            "Enter your Password"
          )}

          <div>
            <FormControlLabel
              sx={{
                color:
                  formik.touched.rememberMe &&
                  formik.errors.rememberMe &&
                  "#d32f2f",
              }}
              control={
                <Checkbox
                  checked={formik.values.rememberMe}
                  onChange={(e) =>
                    formik.setFieldValue("rememberMe", e.target.checked)
                  }
                />
              }
              label="Remember me"
            />
            {formik.touched.rememberMe && formik.errors.rememberMe && (
              <Typography
                color="error"
                fontSize="11px"
                marginTop="-0.5rem"
                fontWeight={600}
              >
                {formik.errors.rememberMe}
              </Typography>
            )}
          </div>

          <CommonButton
            type="submit"
            value="Login"
            padding="10px 16px"
            borderRadius="6px"
            loading={buttonLoading}
            disabled={buttonLoading}
          />

          <Divider>
            <Typography color={"#8396A8"}>or</Typography>
          </Divider>

          <Button
            size="small"
            variant="outlined"
            fullWidth
            sx={{
              borderColor: "#D5DADD",
              color: "#1A2733",
              fontWeight: 600,
            }}
            startIcon={<img src={GoogleLogo} alt="Google Logo" />}
          >
            Login with Google
          </Button>

          <StyledFlexRowView sx={{ justifyContent: "center" }}>
            <Typography
              variant="caption"
              color="text.secondary"
              sx={{ cursor: "pointer" }}
            >
              Forgot Password?
            </Typography>
            <Typography
              variant="caption"
              color="primary"
              sx={{ cursor: "pointer" }}
            >
              Reset Password
            </Typography>
          </StyledFlexRowView>
          {/* <StyledFlexRowView sx={{ justifyContent: "center" }}>
        <Typography
          variant="caption"
          color="text.secondary"
          sx={{ cursor: "pointer" }}
        >
          {"Don’t have an account ?"}
        </Typography>
        <Typography
          variant="caption"
          color="primary"
          sx={{ cursor: "pointer" }}
        >
          {"Sign up for Free"}
        </Typography>
      </StyledFlexRowView> */}
        </Box>
      </form>
      <SnackBar
        snackbarState={snackbarState}
        handleCloseValidation={handleCloseValidation}
      />
    </>
  );
};

export default Login;

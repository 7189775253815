import React from "react";
import CommonButton from "../../../component/Button";
import { Dialog, ListItem, Typography } from "@mui/material";
import { CommonGrid2 } from "../../common-components/StyledComponents";
import { CustomList } from "./StyledComponent";
import ArrowRightRoundedIcon from "@mui/icons-material/ArrowRightRounded";

const ErrorDialog = ({ handleWrongClose, uploadJobDataList, wrongClose }) => {
  return (
    <Dialog open={wrongClose} fullWidth maxWidth={"xs"}>
      <CommonGrid2
        container
        flexDirection={"column"}
        spacing={2}
        padding={"1.5rem 2rem"}
        gap={"1rem"}
        alignItems={"center"}
      >
        {Array.isArray(uploadJobDataList) && uploadJobDataList.length !== 0 ? (
          <>
            <Typography variant="h2" textAlign={"center"}>
              This Files are corrupted
            </Typography>
            <CustomList color="red" fontWeight={700}>
              {uploadJobDataList.map((item) => (
                <>
                  {item.error_message !== "" && (
                    <ListItem>
                      <ArrowRightRoundedIcon />
                      {item.resume_file_name}
                    </ListItem>
                  )}
                </>
              ))}
            </CustomList>
          </>
        ) : (
          <>
            <Typography variant="h2" textAlign={"center"}>
              Something went wrong
            </Typography>
            <Typography variant="p" fontSize={"15px"} fontWeight={600}>
              Delete all the files and Upload
            </Typography>
          </>
        )}
        <CommonButton
          marginTop={"1rem"}
          variant="contained"
          color="white"
          padding={"8px 2rem"}
          background="red"
          fontWeight="700"
          value="Close"
          fontSize="15px"
          onClick={() => handleWrongClose()}
        />
      </CommonGrid2>
    </Dialog>
  );
};
export default ErrorDialog;

import styled from "@emotion/styled";
import { Paper } from "@mui/material";

export const CustomPaper = styled(Paper)(
  ({
    marginBottom,
    padding,
    width,
    borderRadius,
    border,
    display,
    alignItems,
    boxShadow,
    flexDirection,
    background,
    gap,
  }) => ({
    boxShadow: boxShadow || "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
    borderRadius: borderRadius || "12px",
    border: border || "1px solid #EAECF0 !important",
    marginBottom: marginBottom,
    padding: padding,
    width: width,
    display: display,
    alignItems: alignItems,
    flexDirection: flexDirection,
    gap: gap,
    background: background,
  })
);

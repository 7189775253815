import SearchIcon from "@mui/icons-material/Search";
import { Box, Card, Chip, Grid2 } from "@mui/material";
import * as React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useLocation } from "react-router-dom";
import AmountIco from "../../../assets/icons/AmountIco";
import LocationIco from "../../../assets/icons/LocationIco";
import ReLocationIco from "../../../assets/icons/ReLocationIco";
import StarIco from "../../../assets/icons/StarIco";
import ShortlistedImage from "../../../assets/images/Shortlisted.png";
import SubtractImage from "../../../assets/images/Subtract.png";
import TotalApplicantsImage from "../../../assets/images/TotalApplicants.png";
import Breadcrumbs from "../../../component/Breadcrumbs";
import CommonButton from "../../../component/Button";
import CommonTextField from "../../../component/Input";
import { StyledFlexColumnView, StyledFlexRowView, SubTitleTypo, TitleTypo } from "../../common-components/StyledComponents";

export default function JobsBoard() {
    const query = new URLSearchParams(useLocation().search);
    const breadCrumbData = [
        {
            title: "Jobs",
            navigate: "/jobs",
        },
        {
            title: query.get("title"),
            active: true,
        },
    ];
    const gridData = [
        { title: "Client", contentKey: "Talario" },
        { title: "Job", contentKey: query.get("title") },
        { title: "Openings", contentKey: "1" },
        { title: "Start Date", contentKey: "12 / 12 / 2024" },
    ];
    const initialData = {
        totalApplicants: {
            title: 'Total Applicants',
            backgroundImage: <img src={TotalApplicantsImage} alt="Total Applicants" style={{ width: '100%', height: 'auto' }} />,
            // backgroundImage: <TotalApplicantsIco />,
            items: [
                { id: '1', title: 'Jane Cooper', para: 'Sr. Java Architect - Citizen - Fortune 50', relocation: true, progress: 96, fromAmount: "$200/hr", toAmount: "$400,000 K", location: "San Ramon, CA" },
                { id: '2', title: 'Wade Warren', para: 'Sr. Java Architect - Citizen - Fortune 50', relocation: true, progress: 60, fromAmount: "$200/hr", toAmount: "$400,000 K", location: "San Ramon, CA" },
                { id: '3', title: 'Brooklyn Simmons', para: 'Sr. Java Architect - Citizen - Fortune 50', relocation: false, progress: 25, fromAmount: "$200/hr", toAmount: "$400,000 K", location: "San Ramon, CA" },
                { id: '4', title: 'Leslie Alexander', para: 'Sr. Java Architect - Citizen - Fortune 50', relocation: true, progress: 70, fromAmount: "$200/hr", toAmount: "$400,000 K", location: "San Ramon, CA" },
            ],
        },
        shortlisted: {
            title: 'Shortlisted',
            backgroundImage: <img src={ShortlistedImage} alt="Total Applicants" style={{ width: '100%', height: 'auto' }} />,
            items: [
                { id: '6', title: 'Wade Warren', para: 'Sr. Java Architect - Citizen - Fortune 50', relocation: true, progress: 60, fromAmount: "$200/hr", toAmount: "$400,000 K", location: "San Ramon, CA" },
            ],
        },
        chatbot: {
            title: 'Chatbot',
            backgroundImage: <img src={ShortlistedImage} alt="Total Applicants" style={{ width: '100%', height: 'auto' }} />,
            items: [
                { id: '7', title: 'Darren Rashkin', para: 'Sr. Java Architect - Citizen - Fortune 50', relocation: true, progress: 96, fromAmount: "$200/hr", toAmount: "$400,000 K", location: "San Ramon, CA" },
                { id: '8', title: 'Leslie Alexander', para: 'Sr. Java Architect - Citizen - Fortune 50', relocation: true, progress: 70, fromAmount: "$200/hr", toAmount: "$400,000 K", location: "San Ramon, CA" },
                { id: '9', title: 'Guy Hawkins', para: 'Sr. Java Architect - Citizen - Fortune 50', relocation: true, progress: 70, fromAmount: "$200/hr", toAmount: "$400,000 K", location: "San Ramon, CA" },
                { id: '10', title: 'Robert Fox', para: 'Sr. Java Architect - Citizen - Fortune 50', relocation: true, progress: 70, fromAmount: "$200/hr", toAmount: "$400,000 K", location: "San Ramon, CA" },
            ],
        },
        submitted: {
            title: 'Submitted',
            backgroundImage: <img src={ShortlistedImage} alt="Total Applicants" style={{ width: '100%', height: 'auto' }} />,
            items: [
                { id: '11', title: 'Kristin Watson', para: 'Sr. Java Architect - Citizen - Fortune 50', relocation: true, progress: 70, fromAmount: "$200/hr", toAmount: "$400,000 K", location: "San Ramon, CA" },
                { id: '12', title: 'Cody Fisher', para: 'Sr. Java Architect - Citizen - Fortune 50', relocation: true, progress: 70, fromAmount: "$200/hr", toAmount: "$400,000 K", location: "San Ramon, CA" },
                { id: '13', title: 'Savannah Nguyen', para: 'Sr. Java Architect - Citizen - Fortune 50', relocation: true, progress: 70, fromAmount: "$200/hr", toAmount: "$400,000 K", location: "San Ramon, CA" },

            ],
        },
        interview1: {
            title: 'Interview 1',
            backgroundImage: <img src={ShortlistedImage} alt="Total Applicants" style={{ width: '100%', height: 'auto' }} />,
            items: [
                { id: '14', title: 'Launch product', para: 'Sr. Java Architect - Citizen - Fortune 50', relocation: true, progress: 70, fromAmount: "$200/hr", toAmount: "$400,000 K", location: "San Ramon, CA" },
            ],
        },
        interview2: {
            title: 'Interview 2',
            backgroundImage: <img src={ShortlistedImage} alt="Total Applicants" style={{ width: '100%', height: 'auto' }} />,
            items: [
                { id: '15', title: 'Launch product', para: 'Sr. Java Architect - Citizen - Fortune 50', relocation: true, progress: 70, fromAmount: "$200/hr", toAmount: "$400,000 K", location: "San Ramon, CA" },
            ],
        },
        contract: {
            title: 'Contract',
            backgroundImage: <img src={SubtractImage} alt="Total Applicants" style={{ width: '100%', height: 'auto' }} />,
            items: [
                { id: '16', title: 'Launch product', para: 'Sr. Java Architect - Citizen - Fortune 50', relocation: true, progress: 70, fromAmount: "$200/hr", toAmount: "$400,000 K", location: "San Ramon, CA" },
            ],
        },
    };
    const getChipColor = (progress) => {
        if (progress >= 70) return "#DBFAE6";
        if (progress >= 30) return "#FEEFC6";
        return "#FFEAF4";
    };
    const getStarColor = (progress) => {
        if (progress >= 70) return "#079455";
        if (progress >= 30) return "#DC6803";
        return "#A30E0E";
    };

    const [data, setData] = React.useState(initialData);
    const handleDragEnd = (result) => {
        const { source, destination } = result;
        if (!destination) return;
        const sourceList = Array.from(data[source.droppableId].items);
        const [movedItem] = sourceList.splice(source.index, 1);
        const destinationList = Array.from(data[destination.droppableId].items);
        destinationList.splice(destination.index, 0, movedItem);
        setData({
            ...data,
            [source.droppableId]: {
                ...data[source.droppableId],
                items: sourceList,
            },
            [destination.droppableId]: {
                ...data[destination.droppableId],
                items: destinationList,
            },
        });
    };

    return (
        <>
            <Grid2 container direction="row" spacing={3} justifyContent={"space-between"}>
                <Grid2 size={6} display={"flex"} alignItems={"center"}>
                    <Breadcrumbs breadCrumbData={breadCrumbData} />
                </Grid2>
                <Grid2
                    size
                    xs={6}
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                    sx={{ gap: 2 }}
                >
                    <CommonTextField padding={"10px 14px"} placeholder="Search" startIcon={<SearchIcon />} />
                    <CommonButton
                        variant="contained"
                        color="white"
                        background="#7F56D9"
                        fontWeight="600"
                        value="Filters"
                        width={100}
                        padding={"10px 14px"}
                    />
                </Grid2>
            </Grid2>
            <Box padding={"24px 0px"} >
                <Grid2 container border={"1px solid #EAECF0"} borderRadius={"5px"} padding={"10px 12px"} >
                    {gridData.map((item) => (
                        <Grid2 size={1.7} key={item.id}>
                            <StyledFlexColumnView gap={"6px"}>
                                {item.title && <TitleTypo style={{ fontSize: "12px", color: "#475467", fontWeight: 500 }}>{item.title}</TitleTypo>}
                                {item.contentKey && (
                                    <SubTitleTypo style={{ fontSize: "14px", color: "#101828", fontWeight: 700 }}>{item.contentKey}</SubTitleTypo>
                                )}
                            </StyledFlexColumnView>
                        </Grid2>
                    ))}
                </Grid2 >
            </Box >
            <DragDropContext onDragEnd={handleDragEnd}>
                <Box sx={{ display: 'flex', }}>
                    {Object.keys(data).map((listId) => (
                        <Droppable key={listId} droppableId={listId}>
                            {(provided) => (
                                <Box
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    sx={{
                                        width: 300,
                                    }}
                                >
                                    <Card
                                        sx={{
                                            position: 'relative',
                                            marginBottom: '16px',
                                            padding: '20px',
                                            backgroundColor: 'transparent',
                                            boxShadow: 'unset',
                                            borderRadius: '2px',
                                        }}
                                    >
                                        <Box sx={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            zIndex: -1,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',

                                        }}>
                                            {data[listId].backgroundImage}
                                        </Box>
                                        <TitleTypo style={{ fontSize: "12px", color: "#475467", fontWeight: 500, lineHeight: "20px" }}>
                                            {`${data[listId].title}`}
                                        </TitleTypo>
                                        <TitleTypo style={{ fontSize: "16px", color: "#344054", fontWeight: 700, padding: "4px 0px" }}>
                                            {`${data[listId].items.length}`}<span style={{ fontSize: "12px", color: "#A9B4BE", fontWeight: 700, padding: "4px 0px" }}> / 20</span>
                                        </TitleTypo>
                                    </Card>
                                    <Box
                                        sx={{
                                            maxHeight: {
                                                xs: 'none',
                                                sm: 'none',
                                                md: '600px',
                                                lg: '600px',
                                                xl: '600px',
                                            },
                                            overflowY: 'hidden',  // Initially hide the scrollbar
                                            height: {
                                                lg: 'calc(100vh - 100px)',
                                            },
                                            '&:hover': {
                                                overflowY: 'auto',  // Show scrollbar on hover
                                            },
                                            '&::-webkit-scrollbar': {
                                                width: '6px',

                                            },
                                            '&::-webkit-scrollbar-track': {
                                                backgroundColor: '#f0f0f0',
                                            },
                                            '&::-webkit-scrollbar-thumb': {
                                                backgroundColor: '#A9B4BE',
                                                borderRadius: '4px',
                                            },
                                            marginLeft: data[listId].backgroundImage ? '4px' : '0',
                                        }}
                                    >
                                        {data[listId].items.map((card, index) => (
                                            <Draggable key={card.id} draggableId={card.id} index={index}>
                                                {(provided) => (
                                                    <Card
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        sx={{
                                                            marginBottom: '8px',
                                                            padding: '12px',
                                                            backgroundColor: '#fff',
                                                            boxShadow: '0px 0px 9.6px 0px #00000012',
                                                            borderRadius: '5px',
                                                            width: "95%",
                                                            border: "1px solid #EAECF0"
                                                        }}
                                                    >
                                                        <TitleTypo style={{ fontSize: "14px", color: "#344054", fontWeight: 600, lineHeight: "20px", paddingBottom: "4px" }}>{card.title}</TitleTypo>
                                                        <TitleTypo style={{ fontSize: "12px", color: "#5B738B", fontWeight: 400, lineHeight: "20px" }}>{card.para}</TitleTypo>
                                                        <TitleTypo style={{ fontSize: "12px", color: "#7F56D9", fontWeight: 600, lineHeight: "15px", paddingTop: "4px" }}>{"See More"}</TitleTypo>
                                                        <StyledFlexRowView style={{ padding: "14px 0px 8px 0px" }}>
                                                            <AmountIco />
                                                            <StyledFlexColumnView style={{ gap: "3px" }}>
                                                                <TitleTypo style={{ fontSize: "12px", color: "#344054", fontWeight: 500, lineHeight: "15px" }}>{card.fromAmount}</TitleTypo>
                                                                <TitleTypo style={{ fontSize: "12px", color: "#344054", fontWeight: 500, lineHeight: "15px" }}>{card.toAmount}</TitleTypo>
                                                            </StyledFlexColumnView>
                                                        </StyledFlexRowView>
                                                        <StyledFlexRowView>
                                                            <LocationIco />
                                                            <TitleTypo style={{ fontSize: "12px", color: "#344054", fontWeight: 500, lineHeight: "15px" }}>{card.location}</TitleTypo>
                                                        </StyledFlexRowView>
                                                        <StyledFlexRowView style={{ padding: "14px 0px 8px 0px" }}>
                                                            <ReLocationIco relocation={card.relocation} />
                                                            <Chip
                                                                label={`${card.progress}%`}
                                                                size="small"
                                                                icon={<StarIco color={getStarColor(card.progress)} />}
                                                                sx={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    justifyContent: "flex-start",
                                                                    padding: "4px 6px",
                                                                    gap: "6px",
                                                                    borderRadius: "5px",
                                                                    width: "fit-content",
                                                                    backgroundColor: getChipColor(card.progress),
                                                                    color: card.progress >= 70 ? '#079455' : card.progress >= 30 ? '#DC6803' : '#A30E0E',
                                                                    fontWeight: 600,
                                                                    '& .MuiChip-icon': {
                                                                        marginRight: '6px',
                                                                    },
                                                                    '& .MuiChip-label': {
                                                                        display: 'flex',
                                                                    },
                                                                }}
                                                            />
                                                        </StyledFlexRowView>
                                                    </Card>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </Box>
                                </Box>
                            )}
                        </Droppable>
                    ))}
                </Box>
            </DragDropContext >
        </>
    );
}

function ReLocationIco({ relocation = false }) {
    const iconColor = relocation ? "#079455" : "#A30E0E";
    const backgroundColor = relocation ? "#DBFAE6" : "#FFEAF4";

    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill={backgroundColor} xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="24" rx="5" fill={backgroundColor} />
            <path d="M10.37 17.8435V17.8872L10.37 19.1255L10.37 19.1256M10.37 17.8435L11.2512 19.5312C11.1722 19.6044 11.0735 19.6531 10.9672 19.6711C10.861 19.6891 10.7518 19.6758 10.653 19.6328C10.5542 19.5898 10.4701 19.5189 10.411 19.4288C10.3518 19.3387 10.3202 19.2334 10.32 19.1256H10.37M10.37 17.8435L10.3267 17.8376M10.37 17.8435L10.3267 17.8376M10.37 19.1256C10.3702 19.2236 10.399 19.3194 10.4528 19.4014C10.5066 19.4833 10.5831 19.5478 10.673 19.587C10.7629 19.6261 10.8622 19.6382 10.9589 19.6218C11.0555 19.6054 11.1452 19.5612 11.2171 19.4946M10.37 19.1256L11.2171 19.4946M11.2171 19.4946C11.2172 19.4946 11.2172 19.4945 11.2172 19.4945L13.077 17.7571L11.2171 19.4946ZM10.3267 17.8376C8.59698 17.6046 7.10577 17.0173 6.04825 16.2015C4.99086 15.3858 4.37001 14.3443 4.37001 13.2C4.37001 11.785 5.32753 10.4672 7.0141 9.57725C7.22671 9.47051 7.47268 9.45126 7.69932 9.52366C7.92611 9.5961 8.11548 9.75454 8.2268 9.96499C8.33813 10.1754 8.36255 10.4211 8.29482 10.6494C8.22714 10.8774 8.07286 11.0699 7.86501 11.1856C6.81193 11.74 6.19001 12.4728 6.19001 13.2C6.19001 13.7857 6.60849 14.3868 7.32528 14.8874C8.04399 15.3894 9.0715 15.7972 10.3121 15.995L10.37 16.0042M10.3267 17.8376L10.37 16.0042M10.37 16.0042V15.9456M10.37 16.0042V15.9456M10.37 15.9456V14.9522C10.3706 14.8544 10.3997 14.7588 10.4537 14.6773C10.5076 14.5956 10.5842 14.5315 10.674 14.4926C10.7638 14.4537 10.863 14.4418 10.9594 14.4583C11.0559 14.4748 11.1454 14.519 11.2172 14.5854C11.2172 14.5855 11.2172 14.5855 11.2172 14.5855L13.077 16.3229L10.37 15.9456ZM13.307 17.4308C13.2535 17.5542 13.1752 17.6652 13.0771 17.7571V16.3229C13.1752 16.4148 13.2535 16.5258 13.307 16.6492C13.3605 16.7725 13.3881 16.9055 13.3881 17.04C13.3881 17.1745 13.3605 17.3075 13.307 17.4308Z" fill={iconColor} stroke={iconColor} stroke-width="0.1" />
            <path d="M16.9845 9.5741L16.9845 9.57411L16.9854 9.57459C18.6723 10.467 19.63 11.7848 19.63 13.2C19.63 14.8319 18.3486 16.3278 16.1862 17.1952L16.1861 17.1952C16.0751 17.2401 15.9563 17.2626 15.8367 17.2615C15.717 17.2604 15.5986 17.2358 15.4885 17.189C15.3783 17.1422 15.2784 17.0742 15.1945 16.9888C15.1106 16.9034 15.0444 16.8023 14.9996 16.6913C14.9548 16.5803 14.9322 16.4615 14.9333 16.3418C14.9344 16.2221 14.959 16.1038 15.0058 15.9936C15.0526 15.8835 15.1206 15.7836 15.206 15.6997C15.2914 15.6158 15.3925 15.5496 15.5034 15.5048C16.2099 15.2234 16.7849 14.8638 17.1841 14.4673C17.583 14.0711 17.81 13.6343 17.81 13.2C17.81 12.4703 17.1879 11.7398 16.1346 11.183L16.1346 11.183L16.1337 11.1825C16.0255 11.1281 15.9292 11.0527 15.8506 10.9606C15.7719 10.8686 15.7124 10.7617 15.6756 10.6464C15.6387 10.531 15.6253 10.4095 15.636 10.2889C15.6467 10.1683 15.6814 10.051 15.738 9.94398C15.7947 9.83694 15.8721 9.74228 15.9657 9.66554C16.0594 9.58881 16.1675 9.53154 16.2835 9.49709C16.3996 9.46264 16.5214 9.45171 16.6418 9.46494C16.7622 9.47816 16.8787 9.51527 16.9845 9.5741Z" fill={iconColor} stroke={iconColor} stroke-width="0.1" />
            <path d="M12.9432 10.8504C13.8312 9.8208 14.88 8.5536 14.88 7.2M12.9432 10.8504L12.9054 10.8177C12.7929 10.9475 12.6539 11.0515 12.4977 11.1229C12.3415 11.1942 12.1717 11.2312 12 11.2312C11.8283 11.2312 11.6585 11.1942 11.5023 11.1229C11.3461 11.0516 11.2071 10.9475 11.0946 10.8177C10.6505 10.304 10.1689 9.73266 9.79814 9.12166C9.42733 8.5106 9.17 7.86398 9.17 7.2L9.17 7.19997C9.16959 6.46547 9.45476 5.75958 9.96526 5.23149C10.4756 4.70359 11.171 4.39469 11.9048 4.37H12H12.0952C12.8289 4.39469 13.5244 4.70359 14.0347 5.23149C14.5452 5.75958 14.8304 6.46547 14.83 7.19997V7.2C14.83 7.86398 14.5727 8.50999 14.2019 9.12075C13.8311 9.73147 13.3494 10.3028 12.9053 10.8177L12.9432 10.8504ZM12.9432 10.8504C12.826 10.9856 12.6812 11.0941 12.5185 11.1684C12.3557 11.2427 12.1789 11.2812 12 11.2812C11.8211 11.2812 11.6443 11.2427 11.4815 11.1684C11.3188 11.0941 11.1739 10.9856 11.0568 10.8504C10.1688 9.8232 9.12 8.5536 9.12 7.2H14.88M14.88 7.2C14.8804 6.45252 14.5902 5.73416 14.0707 5.19674C13.5512 4.65931 12.8431 4.34492 12.096 4.32L14.88 7.2ZM12.8398 7.76113C12.9508 7.59503 13.01 7.39976 13.01 7.2C13.01 6.93213 12.9036 6.67523 12.7142 6.48582C12.5248 6.29641 12.2679 6.19 12 6.19C11.8002 6.19 11.605 6.24924 11.4389 6.36022C11.2728 6.4712 11.1433 6.62894 11.0669 6.81349C10.9904 6.99804 10.9704 7.20112 11.0094 7.39704C11.0484 7.59296 11.1446 7.77293 11.2858 7.91418C11.4271 8.05543 11.607 8.15162 11.803 8.19059C11.9989 8.22956 12.202 8.20956 12.3865 8.13312C12.5711 8.05667 12.7288 7.92722 12.8398 7.76113Z" fill={iconColor} stroke={iconColor} stroke-width="0.1" />
        </svg>
    );
}
export default ReLocationIco;
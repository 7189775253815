import { URL } from "../../api/Api";
import { QUICKMATCH } from "../Action";
import { catchError, GET, getToken } from "../axios/Axios";

export const UploadJob = async (formData, getName, status) => {
  try {
    const token = await getToken();
    const response = await fetch(
      getName === "NextUpload"
        ? `${URL}/intake/jobdata/?status=${status}`
        : `${URL}/matchengine/match/resume/jd`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }
    );

    if (response.status === 401 || response.status === 403) {
      window.localStorage.clear();
      window.sessionStorage.clear();
      window.location.href = "/auth/login";
      return;
    } else if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response;
  } catch (error) {
    if (error.message.includes("401") || error.message.includes("403")) {
      window.localStorage.clear();
      window.sessionStorage.clear();
      window.location.href = "/auth/login";
    }
    throw error;
  }
};

export const ReviewMatchInformation = (job_id) => {
  return async (dispatch) => {
    try {
      const response = await GET(`${URL}/intake/match-resumes/${job_id}`, {});
      dispatch({ type: QUICKMATCH.reviewMatch, data: response.data });
      return response.data;
    } catch (error) {
      throw catchError(error);
    }
  };
};

import { styled, ToggleButton, ToggleButtonGroup } from "@mui/material";
import React from "react";

const CustomToogle = styled(ToggleButtonGroup)(({ gap, width }) => ({
  gap: gap,
  width: width,
}));

const Button = styled(ToggleButton)(
  ({
    border,
    isFirst,
    isLast,
    borderRadius,
    padding,
    fontSize,
    color,
    backgroundColor,
    width,
  }) => ({
    ...(isLast && {
      borderTopRightRadius: `${borderRadius} !important`,
      borderBottomRightRadius: `${borderRadius} !important`,
    }),
    ...(isFirst && {
      borderTopLeftRadius: `${borderRadius} !important`,
      borderBottomLeftRadius: `${borderRadius} !important`,
    }),
    width: width,
    border: `${border}`,
    marginLeft: "0px !important",
    fontWeight: "600",
    gap: "20px !important",
    fontSize: fontSize,
    padding: padding,
    borderRadius: borderRadius,
    color: color,
    backgroundColor: backgroundColor,
  })
);
const ToogleButton = ({
  alignment,
  handleChange,
  ToogleData,
  border,
  color,
  backgroundColor,
  borderRadius,
  gap,
  padding,
  fontSize,
  activeColor,
  activeBackgroundColor,
  disabled,
  width,
}) => {
  return (
    <CustomToogle
      color={"primary"}
      value={alignment}
      exclusive
      gap={gap}
      width={width}
      onChange={handleChange}
      aria-label="Platform"
    >
      {ToogleData.map((item, index) => (
        <Button
          gap={gap}
          borderRadius={borderRadius}
          key={index}
          value={item.value}
          border={border}
          padding={padding}
          fontSize={fontSize}
          isFirst={index === 0}
          color={color}
          width={width}
          isLast={ToogleData.length - 1 === index}
          backgroundColor={backgroundColor}
          disabled={disabled}
          sx={{
            "&.Mui-selected": {
              backgroundColor: `${activeBackgroundColor} !important`,
              color: activeColor,
            },
          }}
        >
          {item.name}
        </Button>
      ))}
    </CustomToogle>
  );
};
export default ToogleButton;

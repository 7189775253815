import {
  Box,
  Grid2,
  Rating,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import {
  StyledTableBodyTableCell,
  StyledTableTitleTableCell,
  TableContainerBorder,
} from "../../../common-components/StyledComponents";
import { HandleSkeletion } from "../../../../utils/constants";

function StrengthWeak({ size, matchedData, loading }) {
  const renderSkills = (skills, title) => (
    <TableContainerBorder sx={{ marginTop: "0px !important" }}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableTitleTableCell sx={{ width: "50%" }}>
              {title}
            </StyledTableTitleTableCell>
            <StyledTableTitleTableCell sx={{ width: "50%" }} align="center">
              {"Rating"}
            </StyledTableTitleTableCell>
          </TableRow>
        </TableHead>
        {loading ? (
          <>
            {Array.from({ length: 3 }).map((_, index) => (
              <TableRow>
                {Array.from({ length: 2 }).map((_, index) => (
                  <TableCell key={index}>
                    <HandleSkeletion height={30} />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </>
        ) : (
          <TableBody>
            {skills?.map((item, index) => (
              <Tooltip
                key={index}
                title={item?.justification || "No justification provided"}
                placement="top"
                arrow
              >
                <TableRow hover>
                  <StyledTableBodyTableCell>
                    {item?.category}
                  </StyledTableBodyTableCell>
                  <TableCell align="right">
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-end"
                      sx={{ gap: "10px", marginRight: "20px !important" }}
                    >
                      <Rating
                        value={item?.rating}
                        precision={0.5}
                        readOnly
                        sx={{
                          color: "#FFA500",
                          fontSize: "24px",
                          mr: 1,
                        }}
                      />
                      <Box sx={{ width: "40px" }}>
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: "16px",
                            color: "#101828",
                          }}
                        >
                          {item?.score.toFixed(2)}%
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
              </Tooltip>
            ))}
          </TableBody>
        )}
      </Table>
    </TableContainerBorder>
  );
  return (
    <Grid2 container spacing={4} justifyContent="center" marginTop={"0.5rem"}>
      <Grid2 size={size}>
        {renderSkills(matchedData?.insights?.strengths, "Strength")}
      </Grid2>
      <Grid2 size={size}>
        {renderSkills(matchedData?.insights?.weaknesses, "Weakness")}
      </Grid2>
    </Grid2>
  );
}

export default StrengthWeak;

import { COVER } from "../Action";

const intialState = {
  coverSheetGet: {},
};
const CoverSheetReducer = (state = intialState, action) => {
  switch (action.type) {
    case COVER.coverGet:
      return { ...state, coverSheetGet: action.data };
    default:
      return state;
  }
};
export default CoverSheetReducer;

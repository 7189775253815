import { Divider, IconButton, Link, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import ChatBotIco from "../../assets/icons/ChatBotIco";
import ChatBotInputIco from "../../assets/icons/ChatBotInputIco";
import Ellipse from "../../assets/icons/Ellipse";
import CommonTextField from "../../component/Input";
import {
  CandidateChatBot,
  CandidateChatBotGetCall,
  ChatBotCall,
  ChatBotGetCall,
} from "../../redux/action/ChatBot";
import {
  CommonGrid2,
  CustomTooltip,
} from "../common-components/StyledComponents";
import IcanioIco from "../../assets/icons/IcanioIco";
import {
  ChatBotFooter,
  CustomDiv,
  InnerContainer,
  WholePageEllipse,
  WholePageOpacity,
} from "./style";
import ArrowCircleDownRoundedIcon from "@mui/icons-material/ArrowCircleDownRounded";
import ChatBotIcon from "../../assets/images/ChatBotIcon.svg";
import Haley from "../../assets/images/Haley.svg";

const ChatBot = ({ jobId, iconVisible, handleClick, resumeIntelligence }) => {
  const [chatBotData, setChatBotData] = useState({
    message: [
      { sender: "bot", text: "Hi, I am Haley. How can I assist you today?" },
    ],
    value: "",
    loader: false,
    pageLoader: false,
  });
  const dispatch = useDispatch();
  const messagesEndRef = useRef(null);
  const [isBottomVisible, setIsBottomVisible] = useState(true);

  const handleIconClick = () => {
    handleSendMessage();
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleFetchData = async () => {
    setChatBotData((prev) => ({
      ...prev,
      pageLoader: true,
    }));
    try {
      const data = resumeIntelligence
        ? await CandidateChatBotGetCall(jobId)
        : await ChatBotGetCall(jobId);

      const messages = [];

      data?.data.forEach((entry) => {
        if (entry.user) {
          const userMessage = {
            sender: "user",
            text: entry.user,
          };
          messages.push(userMessage);
        }

        if (entry.assistant) {
          const botMessage = {
            sender: "bot",
            text: entry.assistant,
          };
          messages.push(botMessage);
        }
      });

      setChatBotData((prev) => ({
        ...prev,
        message: [...prev.message, ...messages],
      }));
      setIsBottomVisible(false);
      setChatBotData((prev) => ({
        ...prev,
        pageLoader: false,
      }));
    } catch (error) {
      setChatBotData((prev) => ({
        ...prev,
        pageLoader: false,
      }));
    }
  };
  useEffect(() => {
    handleFetchData();
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [chatBotData.message]);

  const handleSendMessage = async () => {
    if (!chatBotData.value.trim()) return;
    let payload;
    if (resumeIntelligence) {
      payload = {
        query: chatBotData.value,
        filter_criteria: {
          type: resumeIntelligence ? "resume_data" : "match_data",
          resume_data_id: jobId,
        },
      };
    } else {
      payload = {
        query: chatBotData.value,
        filter_criteria: {
          type: resumeIntelligence ? "resume_data" : "match_data",
          job_data_id: jobId,
        },
      };
    }

    setChatBotData((prev) => ({
      ...prev,
      message: [...prev.message, { sender: "user", text: chatBotData.value }],

      loader: true,
      value: "",
    }));

    try {
      const data = resumeIntelligence
        ? await dispatch(CandidateChatBot(payload))
        : await dispatch(ChatBotCall(payload));
      const botResponse = {
        sender: "bot",
        text: data?.data?.response,
      };
      setChatBotData((prev) => ({
        ...prev,
        message: [...prev.message, botResponse],

        loader: false,
      }));
    } catch (error) {
      const botResponse = {
        sender: "error",
        text: error?.data?.message,
      };
      setChatBotData((prev) => ({
        ...prev,
        message: [...prev.message, botResponse],

        loader: false,
        value: "",
      }));
    }
  };

  const handleChange = (event) => {
    setChatBotData((prev) => ({
      ...prev,
      value: event.target.value,
    }));
    if (event.key === "Enter") {
      handleSendMessage();
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsBottomVisible(entry.isIntersecting);
      },
      { threshold: 1.0 }
    );

    if (messagesEndRef.current) {
      observer.observe(messagesEndRef.current);
    }

    return () => {
      if (messagesEndRef.current) {
        observer.unobserve(messagesEndRef.current);
      }
    };
  });

  return iconVisible ? (
    <CustomTooltip
      title={
        <CustomDiv>
          <Typography color="#0070F2" fontWeight={600} fontSize={"16px"}>
            Hi, {JSON.parse(localStorage.getItem("credential"))?.username}
          </Typography>
          <Typography color="#000" fontSize={"14px"} marginTop={"5px"}>
            My name is Haley. I am an here to help you surf this site, Ask me
            anything you want to know
          </Typography>
          <Typography
            color="#000"
            fontSize={"10px"}
            fontWeight={500}
            textAlign={"end"}
          >
            Click on the icon to start Chatting
          </Typography>
        </CustomDiv>
      }
      arrowHeight={{
        width: "23px",
        height: "16px",
        marginBottom: "-1rem !important",
      }}
      boxShadow={"0px 1px 2px 0px rgba(16, 24, 40, 0.05)"}
      beforeArrowHeight={{
        width: "23px",
        height: "16px",
      }}
      borderRadius={"10px"}
      backgroundColor="#fff"
      placement="top-end"
      border={"2px solid #D5DADD"}
    >
      <IconButton onClick={() => handleClick(false)} style={{ padding: "0px" }}>
        <img src={ChatBotIcon} />
      </IconButton>
    </CustomTooltip>
  ) : (
    <InnerContainer right="5rem">
      {chatBotData.pageLoader && (
        <CustomDiv>
          <WholePageEllipse>
            <Ellipse />
          </WholePageEllipse>
          <WholePageOpacity></WholePageOpacity>
        </CustomDiv>
      )}
      <CommonGrid2
        overflowY="scroll"
        padding="12px 5px 12px 12px"
        height="380px"
        scrollStyle={true}
      >
        {chatBotData.message.map((msg, index) => (
          <div
            key={index}
            style={{
              textAlign: msg.sender === "user" ? "right" : "left",
              margin: "10px 0",
            }}
          >
            <Typography
              fontSize={"13px"}
              textAlign={"left"}
              style={{
                display: "inline-block",
                padding: "5px 10px",
                backgroundColor:
                  msg.sender === "user"
                    ? "#7F56D9"
                    : msg.sender === "error"
                    ? "red"
                    : "#fff",
                color:
                  msg.sender === "user" || msg.sender === "error"
                    ? "#fff"
                    : "#000",
                borderRadius:
                  msg.sender === "user" ? "8px 8px 1px 8px" : "8px 8px 8px 1px",
                maxWidth: msg.sender === "user" ? "70%" : "80%",
                wordBreak: "break-word",
                boxShadow: "1px 1px 5px 1px rgb(16 24 40 / 11%)",
              }}
            >
              {msg.text}
            </Typography>
          </div>
        ))}

        {chatBotData.loader && (
          <CustomDiv position={"relative"}>
            <Ellipse />
          </CustomDiv>
        )}
        <div ref={messagesEndRef} />
      </CommonGrid2>
      {!isBottomVisible && (
        <CustomDiv position={"relative"}>
          <IconButton
            onClick={scrollToBottom}
            style={{
              color: "#7F56D9",
              position: "absolute",
              left: "46.25%",
              background: "#fff",
              borderRadius: "50%",
              top: "-25px",
              zIndex: 999,
              padding: "0px",
            }}
          >
            <ArrowCircleDownRoundedIcon style={{ fontSize: "25px" }} />
          </IconButton>
        </CustomDiv>
      )}

      <CustomDiv margin={"10px 10px 5px 10px"}>
        <CommonTextField
          datePickerCalender
          backgroundColor="#fff"
          padding={"10px"}
          value={chatBotData.value}
          fontSize="14px"
          placeholder={"Ask Haley anything.."}
          calenderIcon={<ChatBotInputIco />}
          handleIconClick={handleIconClick}
          placeholderFontSize="14px"
          onChange={handleChange}
          disabled={chatBotData.loader}
        />
        <IconButton
          onClick={() => handleClick(true)}
          sx={{
            position: "absolute",
            bottom: "0rem",
            right: "-4.5rem",
            padding: "0px",
            "&:hover": {
              padding: "0px",
              boxShadow: "none",
              backgroundColor: "transparent !important",
            },
          }}
        >
          <img src={Haley} />
        </IconButton>
      </CustomDiv>

      <CommonGrid2
        display={"flex"}
        gap={"10px"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <ChatBotFooter>Powered by</ChatBotFooter>
        <IcanioIco />
      </CommonGrid2>
    </InnerContainer>
  );
};
export default ChatBot;

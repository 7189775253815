function HorizonIco({ color }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="3"
        y="14"
        width="18"
        height="7"
        rx="2"
        stroke="#667085"
        stroke-width="2"
      />
      <rect
        x="3"
        y="3"
        width="7"
        height="7"
        rx="2"
        stroke="#667085"
        stroke-width="2"
      />
      <rect
        x="14"
        y="3"
        width="7"
        height="7"
        rx="2"
        stroke="#667085"
        stroke-width="2"
      />
    </svg>
  );
}

export default HorizonIco;

import { URL } from "../../api/Api";
import { CANDIDATE } from "../Action";
import { catchError, GET, POST } from "../axios/Axios";

export const SearchAndListOfResumes = async (payload, params) => {
  try {
    const response = await POST(`${URL}/resume/search/filter`, payload, params);

    return response.data;
  } catch (error) {
    throw catchError(error);
  }
};

export const CandidateViewCall = (job_id) => {
  return async (dispatch) => {
    try {
      const response = await GET(`${URL}/resume/resumedata/${job_id}`, {});
      dispatch({ type: CANDIDATE.candidateView, data: response.data });

      return response.data;
    } catch (error) {
      throw catchError(error);
    }
  };
};

export default function HelixIco({ color }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_746_41351)">
        <path
          d="M9.37378 20.4072C9.61637 20.1082 9.55825 19.6903 9.27897 19.4252L4.58838 14.973C4.21363 14.6173 4.26951 14.0031 4.73241 13.7735C4.87051 13.7051 5.00867 13.6409 5.14713 13.581C5.48971 13.4329 5.88304 13.5222 6.15375 13.7791L9.72017 17.1643C10.1591 17.581 10.8673 17.4091 10.9515 16.8098C10.9866 16.5603 10.8933 16.2928 10.7106 16.1193L8.13492 13.6741C7.82655 13.3814 7.99169 12.8726 8.41615 12.8475C9.03765 12.8111 9.67738 12.8196 10.3359 12.8568C10.6149 12.8726 10.8451 12.6352 10.8095 12.358C10.7909 12.2127 10.7725 12.0648 10.7545 11.9145C10.6948 11.4154 10.2905 11.0213 9.78809 11.0076C8.30239 10.9666 6.80267 11.0837 5.31574 11.5585C4.57346 11.7964 3.83538 12.1223 3.11172 12.5546C2.3892 12.9862 1.67907 13.5258 0.986134 14.1835L0.715054 14.4408C0.326305 14.8097 0.32623 15.4291 0.714888 15.7982C1.07601 16.141 1.6424 16.141 2.00354 15.7982L2.34538 15.4736C2.53848 15.2903 2.83994 15.2978 3.03304 15.4811L8.28379 20.4653C8.59576 20.7614 9.10278 20.7412 9.37378 20.4072Z"
          fill="#667085"
        />
        <path
          d="M12.64 9.23648C12.606 7.96022 12.7084 6.77072 13.0696 5.62669C13.2509 5.0535 13.4968 4.49058 13.8307 3.92573C14.1643 3.36127 14.5858 2.79609 15.1211 2.22595L15.4323 1.89464C15.7514 1.55487 15.7515 1.02553 15.4325 0.685659C15.0837 0.31416 14.4938 0.314082 14.1449 0.685488L13.9096 0.936094C13.0861 1.81308 12.4626 2.71847 12.0077 3.6443C11.3236 5.03386 11.0265 6.46242 10.9463 7.87575C10.8651 9.29142 10.9912 10.698 11.1496 12.1088C11.2533 13.0285 11.3371 13.9135 11.36 14.7636C11.394 16.0398 11.2919 17.2293 10.9304 18.3734C10.7493 18.9462 10.503 19.5102 10.1695 20.0743C9.83591 20.6384 9.41406 21.2033 8.87892 21.7733L8.56725 22.1054C8.24833 22.4452 8.24836 22.9744 8.56732 23.3141C8.91613 23.6857 9.50619 23.6857 9.855 23.3141L10.0902 23.0636C10.914 22.1866 11.5373 21.2812 11.9926 20.3558C12.6764 18.9662 12.9736 17.538 13.0537 16.1239C13.1346 14.7086 13.0086 13.3016 12.8504 11.8909C12.7465 10.9711 12.6627 10.0866 12.64 9.23648Z"
          fill="#667085"
        />
        <path
          d="M23.285 8.20115C22.9238 7.85841 22.3576 7.85842 21.9965 8.20117L21.6547 8.52555C21.4615 8.70885 21.1606 8.70182 20.9674 8.51848L15.7157 3.5341C15.404 3.23832 14.898 3.25817 14.6272 3.59179C14.3846 3.8908 14.442 4.30941 14.7213 4.57453L19.4126 9.02763C19.7864 9.38246 19.7296 9.99709 19.2676 10.2256C19.1299 10.2943 18.992 10.3584 18.8538 10.4181C18.5107 10.5663 18.1166 10.477 17.8455 10.2197L14.2798 6.83508C13.8409 6.41846 13.1329 6.59051 13.0486 7.18978C13.0135 7.43942 13.1068 7.70703 13.2896 7.88057L15.8656 10.3255C16.1743 10.6184 16.0089 11.1264 15.5841 11.152C14.9619 11.1881 14.3218 11.1808 13.662 11.1435C13.3843 11.1279 13.1551 11.3652 13.1907 11.6411C13.2092 11.7861 13.2276 11.9336 13.2455 12.0834C13.3055 12.5837 13.7097 12.9785 14.2134 12.9929C15.6983 13.0324 17.1973 12.9149 18.6842 12.4405C19.4283 12.2037 20.1651 11.8775 20.8884 11.4448C21.6109 11.0129 22.3214 10.4734 23.014 9.81602L23.2851 9.5586C23.6738 9.18955 23.6737 8.57011 23.285 8.20115Z"
          fill="#667085"
        />
      </g>
      <defs>
        <clipPath id="clip0_746_41351">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

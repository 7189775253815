import AddIcon from "@mui/icons-material/Add";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import TabContext from "@mui/lab/TabContext";
import { tabsClasses } from "@mui/material";
import Tab from "@mui/material/Tab";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  DownloadJd,
  getJdFileList,
  getJobDataDetail,
  getMatchedJdResumeData,
} from "../../../api/Api";
import CandidateHub from "../../../assets/icons/CandidateHub";
import FileExtensionIco from "../../../assets/icons/FileExtensionIco";
import Breadcrumbs from "../../../component/Breadcrumbs";
import CommonButton from "../../../component/Button";
import SnackBar from "../../../component/SnackBar";
import { JobJobFormikData, JobJobSubmit } from "../../../redux/action/Job";
import { UploadJob } from "../../../redux/action/QuickMatch";
import { GET, GETDOWNLOAD } from "../../../redux/axios/Axios";
import { HandleSkeletion } from "../../../utils/constants";
import {
  CommonGrid2,
  TabPanelView,
  TabViewSection,
} from "../../common-components/StyledComponents";
import AddMoreCandidate from "../../quick-match/components/AddMoreCandidate";
import DownloadJD from "../job-information/download-jd";
import JobInformation from "../job-information/index";
import JobsCandidate from "../jobs-candidate";
import DialogCandidateHub from "../jobs-candidate/components/DialogCandidateHub";
import JobsSummary from "../jobs-summary";
import ChatBot from "../../chat-bot";
import { ChatBotRelative } from "../style";

export default function JobsView() {
  const query = new URLSearchParams(useLocation().search);
  const jobId = query.get("id");
  const [value, setValue] = React.useState("0");
  const [jobData, setJobData] = React.useState({});
  const [openDownloadJdDialog, setOpenDownloadJdDialog] = React.useState(false);
  const [jdFileList, setJdFileList] = React.useState([]);
  const [jdFileListLoader, setJdFileListLoader] = React.useState(false);
  const [oneTimeCall, setOneTimeCall] = React.useState(false);
  const dispatch = useDispatch();
  const resumeTabIndex = value - 2;
  const [resumeTab, setResumeTab] = React.useState([]);

  const resumeId = resumeTab[value - 2]?.id;
  const filteredResumeTab = resumeTab.filter((item) => item.id !== resumeId);
  const [matchedData, setMatchedData] = React.useState({});
  // const matchedData=resumeTab[resumeTabIndex]?.details
  const [resumeLoader, setResumeLoader] = React.useState(false);
  const valueLocation = useLocation().search;
  const navigate = useNavigate();

  const [files, setFiles] = React.useState([]);

  const [isLoading, setIsLoading] = React.useState(false);
  const [wrongClose, setWrongClose] = React.useState(false);
  const [tableLoader, setTableLoader] = React.useState(false);

  const [addMoreCandidateDialogOpen, setAddMoreCandidateDialogOpen] =
    React.useState(false);
  const [isAddMoreCandidateDragging, setIsAddMoreCandidateDragging] =
    React.useState(false);
  const [addMoreCandidateFiles, setAddMoreCandidateFiles] = React.useState([]);
  const [snackbarState, setSnackbarState] = React.useState({
    open: false,
    message: "",
    severity: "error",
  });
  const [saveEditData, setSaveEditData] = React.useState({
    open: false,
    prevData: "",
    leaveLoading: false,
    disabled: false,
    saveLoading: false,
    view: false,
  });

  const JobJobEditData = useSelector((state) => state.jobs.jobJobEditData);
  const tabData = ["Summary", "Job"];
  const [iconVisible, setIconVisible] = React.useState(true);

  const handleChange = (event, newValue) => {
    if (
      JSON.stringify(JobJobEditData.formikData) !==
      JSON.stringify(JobJobEditData.jobData)
    ) {
      setSaveEditData((prev) => ({
        ...prev,
        open: true,
        prevData: newValue,
        view: false,
      }));
    } else {
      setValue(newValue);
      const index = newValue - 2;
      const isMatchedData = resumeTab[newValue - 2]?.details;
      const resumeId = resumeTab[newValue - 2]?.id;
      const urlParams = new URLSearchParams(valueLocation);
      urlParams.delete("value");
      urlParams.append("value", newValue);
      const newUrl = `/jobs/jobs-view?${urlParams.toString()}`;
      navigate(newUrl);
      if (newValue && parseInt(newValue) >= 2) {
        fetchResumeData(resumeId, index);
        // getCandidateNotesDetatil(resumeId);
      }
    }
  };

  const handleSaveCandidate = () => {
    setSaveEditData({
      open: false,
      prevData: "",
      leaveLoading: false,
      disabled: false,
      saveLoading: false,
      view: false,
    });
  };

  const handleClick = () => {
    setSaveEditData((prev) => ({
      ...prev,
      open: true,
      prevData: "1",
      view: false,
    }));
  };
  const breadCrumbData = [
    {
      title: "Jobs",
      navigate: "/jobs",
      ...(JSON.stringify(JobJobEditData.formikData) !==
        JSON.stringify(JobJobEditData.jobData) && { onClick: handleClick }),
    },
    {
      title: query.get("title"),
      active: true,
    },
  ];

  const fetchJobDataDetail = async () => {
    if (jobId) {
      setTableLoader(true);
      try {
        const res = await GET(getJobDataDetail(jobId));
        setJobData(res?.data);
        setResumeTab(res?.data?.resumes || []);
        setOneTimeCall(true);
        setTableLoader(false);
      } catch (error) {
        setTableLoader(false);
        console.error("Error fetching job data:", error);
      }
    } else {
      console.error("Job ID is not provided in the URL.");
    }
  };

  React.useEffect(() => {
    const queryValue = query?.get("value");

    if (queryValue >= "2" && oneTimeCall && resumeTab.length !== 0) {
      const data = resumeTab[queryValue - 2]?.id;
      const index = queryValue - 2;
      setValue(queryValue);
      fetchResumeData(data, index);
      setOneTimeCall(false);
    } else if (queryValue === "1") {
      setValue(queryValue);
    }
  }, [resumeTab, oneTimeCall]);

  const fetchResumeData = async (resumeId, index, resumeObj, paramLabel) => {
    setResumeLoader(true);

    try {
      const data = await GET(getMatchedJdResumeData(resumeId), paramLabel);
      let arr = [...resumeTab];
      let detailObj = {
        ...data?.data,
        candidate_name: data?.data?.resume_info?.candidate_name,
        pay_rate: data?.data?.resume_info?.pay_rate,
        location: data?.data?.resume_info?.location,
        inferred_title: data?.data?.resume_info?.inferred_title,
        visa_info: data?.data?.resume_info?.visa_info,
        website: data?.data?.resume_info?.website,
        email: data?.data?.resume_info?.email,
        overall_score: data?.data?.quick_match_score?.overall_score,
        results: data?.data?.results || [],
        skill_calculation: data?.data?.skill_calculation,
        error_message: data?.data?.error_message,
        resume_id: data?.data?.resume_id,
        // file_name: data?.resume_file_name,
        spider_graph_results: data?.data?.spider_graph_results,
        file_name: data?.data?.resume_file_name,
        insights: data?.data?.insights,
        feedback: data?.data?.feedback,
        match_summary: data?.data?.match_summary,
        experience: data?.data?.experience,
        coversheet_added: data?.data?.coversheet_added,
        data: {
          personal_details: {
            candidate_name: data?.data?.resume_info?.candidate_name,
            inferred_title: data?.data?.resume_info?.inferred_title,
          },
        },
      };
      if (arr[index]) {
        arr[index] = {
          ...arr[index],
          details: detailObj,
        };
      } else if (resumeObj) {
        arr.push({
          ...resumeObj,
          details: detailObj,
        });
      }
      setResumeTab(arr);
      setMatchedData(arr[index]?.details);

      setResumeLoader(false);
    } catch (error) {
      setSnackbarState({
        open: true,
        severity: "error",
        message: error?.response?.data?.message,
      });
      setResumeLoader(false);
    }
  };

  React.useEffect(() => {
    fetchJobDataDetail();
  }, [jobId]);

  //dame notes

  const handleCloseDownloadJdDialog = () => {
    setOpenDownloadJdDialog(false);
  };

  const handleDownloadJd = (row) => {
    if (row.id) {
      GETDOWNLOAD(DownloadJd(row.id), {
        params: { type: "match_resume" },
        responseType: "blob",
      })
        .then((response) => {
          const contentType = response.headers["content-type"];
          const extension =
            contentType === "application/pdf"
              ? "pdf"
              : contentType ===
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              ? "docx"
              : "doc";

          const fileName = `${row?.file_name}.${extension}`;

          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: contentType })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
        })
        .catch((error) => {
          if (error.response && error.response.data instanceof Blob) {
            const reader = new FileReader();
            reader.onload = () => {
              const responseText = reader.result;
              try {
                const json = JSON.parse(responseText);
                const errorMessage =
                  json.error || "An unexpected error occurred.";
                setSnackbarState({
                  open: true,
                  severity: "error",
                  message: errorMessage,
                });
              } catch (e) {
                setSnackbarState({
                  open: true,
                  severity: "error",
                  message:
                    "An unexpected error occurred while parsing the error message.",
                });
              }
            };
            reader.readAsText(error.response.data);
          } else {
            setSnackbarState({
              open: true,
              severity: "error",
              message: "An unexpected error occurred.",
            });
          }
        });
    } else {
      setSnackbarState({
        open: true,
        severity: "error",
        message: "JD was not parsed successfully. Unable to download.",
      });
    }
  };

  const fetchJdFileList = async () => {
    setJdFileListLoader(true);
    if (jobId) {
      try {
        const res = await GET(getJdFileList(jobId));
        if (res?.success) {
          setJdFileList(res?.data);
          setJdFileListLoader(false);
        }
      } catch (error) {
        setJdFileListLoader(false);
      }
    }
  };

  const handleDownloadJdOpen = () => {
    setOpenDownloadJdDialog(true);
    fetchJdFileList();
  };
  //add more candidate functionality

  const allowedTypes = [
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ];

  const commonFileValidationCheck = (
    commonFiles,
    allowedTypes,
    setCommonFiles,
    existingFiles
  ) => {
    const validFiles = [];
    const invalidFiles = [];

    commonFiles.forEach((file) => {
      if (allowedTypes.includes(file.type)) {
        validFiles.push({
          file,
          name: file.name,
          size: `${(file.size / 1024).toFixed(1)} KB`,
          type: file.type,
          progress: 0,
          buffer: 0,
          visible: false,
          delete: true,
          message: "",
        });
      } else {
        invalidFiles.push(file.name);
      }
    });

    const mergedFiles = [
      ...existingFiles,
      ...validFiles.filter(
        (newFile) =>
          !existingFiles.some(
            (existingFile) => existingFile.name === newFile.name
          )
      ),
    ];
    setCommonFiles(mergedFiles);

    if (invalidFiles.length > 0) {
      setSnackbarState({
        open: true,
        message: `Unsupported file types: ${invalidFiles.join(", ")}`,
        severity: "error",
      });
    }
  };

  const handleAddMoreCandidateDialogClose = () => {
    setAddMoreCandidateDialogOpen(false);
    setAddMoreCandidateFiles([]);
  };

  const handleAddMoreCandidateOpen = () => {
    if (
      JSON.stringify(JobJobEditData.formikData) !==
      JSON.stringify(JobJobEditData.jobData)
    ) {
      handleClick();
    } else setAddMoreCandidateDialogOpen(true);
  };

  const handleAddMoreCandidateFileUpload = (event) => {
    const selectedFiles = Array.from(event.target.files);
    commonFileValidationCheck(
      selectedFiles,
      allowedTypes,
      setAddMoreCandidateFiles,
      addMoreCandidateFiles
    );
  };

  const handleAddMoreCandidateDeleteFile = (index) => {
    setAddMoreCandidateFiles((prevFiles) =>
      prevFiles.filter((_, i) => i !== index)
    );
  };

  const handleAddMoreCandidateDragOver = (event) => {
    event.preventDefault();
    setIsAddMoreCandidateDragging(true);
  };

  const handleAddMoreCandidateDragLeave = () => {
    setIsAddMoreCandidateDragging(false);
  };

  const handleAddMoreCandidateDrop = (event) => {
    event.preventDefault();
    setIsLoading(false);
    setIsAddMoreCandidateDragging(false);

    const droppedFiles = Array.from(event.dataTransfer.files);
    commonFileValidationCheck(
      droppedFiles,
      allowedTypes,
      setAddMoreCandidateFiles,
      addMoreCandidateFiles
    );
  };

  const processJsonResponse = (
    jsonResponse,
    getName,
    setCandidateFiles,
    getAddMore
  ) => {
    const { success, data, message } = jsonResponse;
    if (success && data?.percent !== undefined) {
      setCandidateFiles((prevFiles) =>
        prevFiles.map((f) =>
          f.name === data.current_resume
            ? {
                ...f,
                progress: data.percent,
                buffer: data.buffer || data.percent,
                visible: false,
                delete: false,
              }
            : f
        )
      );
    } else if (getAddMore === "Add-more-Candidate" && data?.length !== 0) {
      setAddMoreCandidateDialogOpen(false);
      setAddMoreCandidateFiles([]);
      if (Array.isArray(data) && data.length > 0 && data[0]?.jd_info) {
        const jdInfo = data[0]?.jd_info;
        let responseId = data[0]?.id;
        let arr = [...resumeTab];
        let resumeObj = {
          id: responseId,
          name: data[0]?.resume_info?.candidate_name
            ? data[0]?.resume_info?.candidate_name
            : "",
          score: data[0]?.quick_match_score?.overall_score
            ? data[0]?.quick_match_score?.overall_score
            : data[0]?.score,
          file_name: data[0]?.resume_file_name,
        };
        arr.push(resumeObj);
        let currentResumeIndex = arr.length + 1;
        setResumeTab(arr);
        fetchResumeData(responseId, arr.length - 1, resumeObj);
        setValue(currentResumeIndex?.toString());
        const urlParams = new URLSearchParams(valueLocation);
        urlParams.delete("value");
        urlParams.append("value", currentResumeIndex);

        const newUrl = `/jobs/jobs-view?${urlParams.toString()}`;
        navigate(newUrl);
      }
    } else if (!success) {
      setWrongClose(true);
      setCandidateFiles((prevFiles) =>
        prevFiles.map((f) =>
          f.name === data.current_resume || data.length == 0
            ? {
                ...f,
                visible: true,
                delete: true,
                message: message,
              }
            : { ...f, delete: true }
        )
      );
    }
  };

  const uploadFile = async (getName, setCandidateFiles, getAddMore) => {
    setIsLoading(true);

    setCandidateFiles((prevFiles) =>
      prevFiles.map((f) => ({
        ...f,
        delete: false,
      }))
    );

    const formData = new FormData();
    if (getAddMore === "Add-more-Candidate") {
      addMoreCandidateFiles.forEach((fileObj, index) => {
        formData.append("resume_file", fileObj.file);
      });
      formData.append("id", jobId);

      try {
        const response = await UploadJob(formData, getName, "Active");
        const reader = response.body.getReader();
        const decoder = new TextDecoder("utf-8");
        let buffer = "";

        while (true) {
          const { value, done } = await reader.read();
          if (done) break;
          const chunk = decoder.decode(value, { stream: true });
          buffer += chunk;
          let boundaryIndex;
          while ((boundaryIndex = buffer.indexOf("}\n")) !== -1) {
            const jsonString = buffer.slice(0, boundaryIndex + 1);
            buffer = buffer.slice(boundaryIndex + 1);
            const jsonResponse = JSON.parse(jsonString);
            try {
              processJsonResponse(
                jsonResponse,
                getName,
                setCandidateFiles,
                getAddMore
              );
            } catch (e) {
              if (getName === "NextUpload") {
                setFiles((prevFiles) =>
                  prevFiles.map((f) => ({
                    ...f,
                    visible: true,
                    delete: true,
                    message: jsonResponse.message,
                  }))
                );
                setWrongClose(true);
              } else {
                setSnackbarState({
                  open: true,
                  message: "Error parsing JSON",
                  severity: "error",
                });
                setCandidateFiles((prevFiles) =>
                  prevFiles.map((f) =>
                    f.name === jsonResponse.data.current_resume
                      ? {
                          ...f,
                          visible: true,
                          delete: true,
                        }
                      : { ...f, delete: true }
                  )
                );
              }
            }
          }
        }

        if (buffer) {
          const jsonResponse = JSON.parse(buffer);
          try {
            processJsonResponse(
              jsonResponse,
              getName,
              setCandidateFiles,
              getAddMore
            );
          } catch (e) {
            setSnackbarState({
              open: true,
              message: "Error parsing final JSON chunk",
              severity: "error",
            });
            setCandidateFiles((prevFiles) =>
              prevFiles.map((f) =>
                f.name === jsonResponse.data.current_resume
                  ? {
                      ...f,
                      visible: true,
                      delete: true,
                    }
                  : { ...f, delete: true }
              )
            );
          }
        }
        setIsLoading(false);
      } catch (error) {
        setSnackbarState({
          open: true,
          message: "Error during the request",
          severity: "error",
        });
        setCandidateFiles((prevFiles) =>
          prevFiles.map((f) => ({
            ...f,
            visible: true,
            delete: true,
            message: "Error during the request",
          }))
        );

        setIsLoading(false);
      }
    }
  };

  const handleAddMoreCandidateClick = async () => {
    if (addMoreCandidateFiles.length == 0) {
      setSnackbarState({
        open: true,
        message: "Upload the Files",
        severity: "error",
      });
    } else {
      uploadFile(
        "Upload-Candidate",
        setAddMoreCandidateFiles,
        "Add-more-Candidate"
      );
    }
  };

  const handleCloseValidation = () => {
    setSnackbarState((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const handleLeave = async () => {
    setSaveEditData((prev) => ({
      ...prev,
      disabled: true,
      leaveLoading: true,
    }));
    await dispatch(
      JobJobFormikData(JobJobEditData.jobData, JobJobEditData.jobData)
    );
    try {
      await new Promise((resolve) => setTimeout(resolve, 1000));

      setSaveEditData((prev) => ({
        ...prev,
        disabled: false,
        leaveLoading: false,
        open: false,
        view: prev.prevData === "1" ? true : false,
      }));

      callBack(saveEditData.prevData);
    } catch (error) {}
  };

  const handleSave = async () => {
    setSaveEditData((prev) => ({
      ...prev,
      disabled: true,
      saveLoading: true,
    }));

    const values = JobJobEditData.formikData;
    const result = (valueData) => {
      if (!valueData) return [];

      const processedData = valueData.reduce((acc, { name, content }) => {
        const existing = acc.find((item) => item.name === name);
        if (existing) {
          existing.content.push(content);
        } else {
          acc.push({ name, content: [content] });
        }
        return acc;
      }, []);

      const extracted = processedData.find((item) => item.name === "extracted");
      const inferred = processedData.find((item) => item.name === "inferred");
      const userAdded = processedData.find(
        (item) => item.name === "user_added"
      );

      const resultData = [];
      if (!extracted) {
        resultData.push({ name: "extracted", content: [] });
      } else {
        resultData.push(extracted);
      }
      if (!inferred) {
        resultData.push({ name: "inferred", content: [] });
      } else {
        resultData.push(inferred);
      }
      const otherData = processedData.filter(
        (item) =>
          item.name !== "extracted" &&
          item.name !== "inferred" &&
          item.name !== "user_added"
      );
      resultData.push(...otherData);
      if (!userAdded) {
        resultData.push({ name: "user_added", content: [] });
      } else {
        resultData.push(userAdded);
      }
      return resultData;
    };

    const data = {
      ...JobJobEditData,
      ...values,
      start_date: values.Date_Range.start_date,
      end_date: values.Date_Range.end_date,
      employer_targets: result(values.employer_targets),
      certificates: result(values.certificates),
      languages: result(values.languages),
      shift: values.shift.map((item) => item.content),
      location_targets: result(values.location_targets),
      high_priority: values.skillData.high_priority,
      low_priority: values.skillData.low_priority,
      medium_priority: values.skillData.medium_priority,
      recommended: values.skillData.recommended,
    };

    try {
      dispatch(JobJobFormikData(values, values));
      const jobEdit = await JobJobSubmit(values.id, data);
      setSnackbarState({
        open: true,
        severity: "success",
        message: jobEdit.message,
      });
      setSaveEditData((prev) => ({
        ...prev,
        disabled: false,
        saveLoading: false,
        open: false,
        view: prev.prevData === "1" ? true : false,
      }));
      callBack(saveEditData.prevData);
    } catch (error) {
      dispatch(JobJobFormikData(JobJobEditData.jobData, values));
      setSnackbarState({
        open: true,
        severity: "error",
        message: error.message,
      });
      setSaveEditData((prev) => ({
        ...prev,
        disabled: false,
        saveLoading: false,
        view: false,
      }));
    }
  };

  const callBack = (newValue) => {
    setValue(newValue);
    const index = newValue - 2;
    const resumeId = resumeTab[newValue - 2]?.id;
    const urlParams = new URLSearchParams(valueLocation);
    urlParams.delete("value");
    urlParams.append("value", newValue);
    const newUrl = `/jobs/jobs-view?${urlParams.toString()}`;
    navigate(newUrl);
    if (newValue && parseInt(newValue) >= 2) {
      fetchResumeData(resumeId, index);
    }
  };
  const Footer = () => (
    <CommonGrid2 display={"flex"} gap={"1rem"}>
      <CommonButton
        value={"Leave"}
        variant="outlined"
        color="#344054"
        width={"100%"}
        padding={"10px"}
        marginTop={"2rem"}
        fontWeight={"700"}
        fontSize={"15px"}
        border={"#D0D5DD"}
        onClick={handleLeave}
        disabled={saveEditData.disabled}
        loading={saveEditData.leaveLoading}
      />
      <CommonButton
        value={"Save"}
        width={"100%"}
        padding={"10px"}
        marginTop={"2rem"}
        fontWeight={"700"}
        fontSize={"15px"}
        disabled={saveEditData.disabled}
        loading={saveEditData.saveLoading}
        onClick={handleSave}
      />
    </CommonGrid2>
  );

  const handleChatBotClick = (value) => {
    setIconVisible(value);
  };

  return (
    <>
      <Breadcrumbs breadCrumbData={breadCrumbData} />

      <TabContext value={value}>
        <CommonGrid2 container spacing={2} alignItems="center" marginTop={2.5}>
          <CommonGrid2 item size={{ xs: 12, md: 8, sm: 6 }}>
            <TabViewSection
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              // aria-label="scrollable auto tabs example"
              aria-label="visible arrows tabs example"
              sx={{
                [`& .${tabsClasses.scrollButtons}`]: {
                  "&.Mui-disabled": { opacity: 0.3 },
                },
              }}
            >
              {tabData.map((item, index) => (
                <Tab label={item} value={index.toString()} />
              ))}

              {resumeTab?.map((elt, index) => {
                return tableLoader ? (
                  <HandleSkeletion
                    width="100px"
                    height="45px"
                    marginRight={"1rem"}
                  />
                ) : (
                  <Tab
                    label={`#${index + 1} ${
                      elt?.name ? elt?.name : elt?.file_name
                    } - ${elt?.score}%`}
                    value={(index + tabData.length).toString()} // Ensure unique values
                    key={index + tabData.length}
                    sx={{
                      textTransform: "none",
                      "&.Mui-selected": {
                        color: "black",
                        fontWeight: "bold",
                      },
                    }}
                  />
                );
              })}
            </TabViewSection>
          </CommonGrid2>

          <CommonGrid2
            item
            size={{ xs: 12, md: 4, sm: 6 }}
            gap="1rem"
            justifyContent="end"
            display="flex"
          >
            <CommonButton
              variant="outlined"
              color="#344054"
              border="#D0D5DD"
              fontWeight="600"
              value={"Ask AI"}
              disabled={!iconVisible}
              onClick={() => handleChatBotClick(false)}
              padding="8px 15px"
            />
            <CommonButton
              variant="outlined"
              color="#344054"
              border="#D0D5DD"
              fontWeight="600"
              value={<VisibilityOutlinedIcon />}
              padding="8px 0px"
            />

            <CommonButton
              variant="outlined"
              color="#344054"
              border="#D0D5DD"
              fontWeight="600"
              value={<AddIcon />}
              padding="8px 0px"
              onClick={handleAddMoreCandidateOpen}
            />
          </CommonGrid2>
        </CommonGrid2>

        <TabPanelView value="0" padding="2rem 0px 0px">
          <JobsSummary fetchData={fetchJobDataDetail} />
        </TabPanelView>

        <TabPanelView value="1" padding="3rem 0px 0px">
          <JobInformation
            jobData={jobData}
            jobId={jobId}
            handleDownloadJdOpen={handleDownloadJdOpen}
            fetchData={fetchJobDataDetail}
            handleClick={handleClick}
            saveEditData={saveEditData}
            resumeTab={resumeTab}
          />
        </TabPanelView>

        {resumeTab.map((elt, index) => (
          <TabPanelView
            value={(index + tabData.length).toString()}
            padding="3rem 0px 0px"
            key={index + tabData.length}
          >
            <JobsCandidate
              matchedData={matchedData}
              resumeId={resumeId}
              resumeLoader={resumeLoader}
              filteredResumeTab={filteredResumeTab}
              fetchResumeData={fetchResumeData}
            />
          </TabPanelView>
        ))}
      </TabContext>

      {/* Download JD Dailog */}
      <DownloadJD
        openDownloadJdDialog={openDownloadJdDialog}
        handleCloseDownloadJdDialog={handleCloseDownloadJdDialog}
        jdFileList={jdFileList}
        handleDownloadJd={handleDownloadJd}
        jdFileListLoader={jdFileListLoader}
      />

      {/* Add Candidate Resume */}
      <AddMoreCandidate
        addMoreCandidateDialogOpen={addMoreCandidateDialogOpen}
        handleAddMoreCandidateDialogClose={handleAddMoreCandidateDialogClose}
        handleAddMoreCandidateDragOver={handleAddMoreCandidateDragOver}
        handleAddMoreCandidateDragLeave={handleAddMoreCandidateDragLeave}
        handleAddMoreCandidateDrop={handleAddMoreCandidateDrop}
        handleAddMoreCandidateFileUpload={handleAddMoreCandidateFileUpload}
        addMoreCandidateFiles={addMoreCandidateFiles}
        isAddMoreCandidateDragging={isAddMoreCandidateDragging}
        handleAddMoreCandidateDeleteFile={handleAddMoreCandidateDeleteFile}
        handleAddMoreCandidateClick={handleAddMoreCandidateClick}
        isLoading={isLoading}
        isJem={false}
        // disabled={addMoreCandidateFiles?.length >= 1}
        disabled={isLoading}
        buttonDisabled={isLoading}
      />

      <SnackBar
        snackbarState={snackbarState}
        handleCloseValidation={handleCloseValidation}
      />
      <DialogCandidateHub
        open={saveEditData.open}
        icon={<CandidateHub margin={"10px 0px 0px 10px"} />}
        title="Save to JEM"
        handleClose={handleSaveCandidate}
        footer={<Footer />}
        titlepadding={"0px 0px 16px"}
        content={
          "You have unsaved changes. If you leave this page, your changes will be lost. Are you sure you want to proceed?"
        }
      />
      <ChatBotRelative>
        <ChatBot
          jobId={jobId}
          iconVisible={iconVisible}
          handleClick={handleChatBotClick}
        />
      </ChatBotRelative>
    </>
  );
}

import { styled } from "@mui/material";

export const SelectCreateCard = styled("div")(
  ({ gap = "1rem", padding = "1rem 1rem", minHeight }) => ({
    border: "1px solid #EAECF0",
    borderRadius: "10px",
    padding: padding,
    display: "flex",
    flexDirection: "column",
    gap: gap,
    boxShadow: " 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
    minHeight: minHeight,
    justifyContent: "space-between",
    "&:hover": {
      transform: "scale(1.04)",
      cursor: "pointer",
      border: "1px solid #7F56D9",
      boxShadow: "1px 1px 10px 0px rgba(0, 0, 0, 0.12)",
    },
  })
);

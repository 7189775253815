import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import DotsVerticalIco from "../../../../assets/icons/DotsVerticalIco";
import CommonButton from "../../../../component/Button";
import { HandleSkeletion } from "../../../../utils/constants";
import {
  StyledFlexRowView,
  StyledTableBodyTableCell,
  StyledTableTitleTableCell,
  TableContainerBorder,
} from "../../../common-components/StyledComponents";

function ExecutiveBio({ data, isLoading }) {
  console.log(data, "data");
  const formattedData =
    data &&
    Object?.keys(data)?.length !== 0 &&
    Object?.entries(data)?.map(([key, value]) => ({
      title: key,
      list: value,
    }));

  const formatTitle = (title) => {
    return title
      .replace(/_/g, " ")
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };
  return (
    <TableContainerBorder sx={{ marginTop: "0px !important" }}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableTitleTableCell sx={{ width: "300px" }}>
              {"Category"}
            </StyledTableTitleTableCell>

            <StyledTableTitleTableCell>{"Details"}</StyledTableTitleTableCell>
            <StyledTableTitleTableCell>{""}</StyledTableTitleTableCell>
          </TableRow>
        </TableHead>
        {isLoading ? (
          <>
            {Array.from({ length: 4 }).map((_, index) => (
              <TableRow>
                {Array.from({ length: 3 }).map((_, index) => (
                  <TableCell key={index}>
                    <HandleSkeletion height={30} />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </>
        ) : (
          <TableBody>
            {formattedData &&
              formattedData?.length !== 0 &&
              formattedData.map((row, index) => (
                <TableRow key={index}>
                  <StyledTableBodyTableCell fontSize="15px">
                    {formatTitle(row.title)}
                  </StyledTableBodyTableCell>
                  <TableCell>
                    <StyledFlexRowView>
                      {Array.isArray(row.list) ? (
                        <ul style={{ padding: "0px" }}>
                          {row?.list.map((item, idx) => (
                            <li key={idx}>{item}</li>
                          ))}
                        </ul>
                      ) : (
                        <span>{row?.list}</span>
                      )}
                    </StyledFlexRowView>
                  </TableCell>
                  <TableCell>
                    <CommonButton
                      disabled={true}
                      value={<DotsVerticalIco />}
                      background={"transparent"}
                      padding={"0px"}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        )}
      </Table>
    </TableContainerBorder>
  );
}

export default ExecutiveBio;

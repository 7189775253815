import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import ExperienceIcon from "../../../../assets/images/experienceIcon.png";
import CommonButton from "../../../../component/Button";
import CommonTextField from "../../../../component/Input";
import {
  CommonGrid2,
  StyledDialog,
} from "../../../common-components/StyledComponents";
import DateRangePickerComp from "../../../jobs/common-card.js/DatePicker";

const AddExperience = ({
  open,
  onClose,
  elcExperience,
  title,
  handlExperienceSubmit,
}) => {
  const initialValues = {
    position: elcExperience?.position || "",
    company: elcExperience?.company || "",
    Date_range: {
      start_date:
        elcExperience?.start_date === ""
          ? elcExperience?.end_date
          : elcExperience?.start_date || "",
      end_date:
        elcExperience?.end_date === ""
          ? elcExperience?.start_date
          : elcExperience?.end_date || "",
    },
    index: elcExperience?.index,
  };

  const validationSchema = Yup.object({
    position: Yup.string().required("Position is required"),
    company: Yup.string().required("Company is required"),
    Date_range: Yup.object({
      start_date: Yup.date()
        .nullable()
        .typeError("Start date must be a valid date"),
      end_date: Yup.date()
        .nullable()
        .typeError("End date must be a valid date"),
    })
      .test(
        "at-least-one-date",
        "Date is required",
        (value) => !!(value?.start_date || value?.end_date)
      )
      .required("Start and End dates are required"),
  });

  const handleSubmit = (values) => {
    handlExperienceSubmit(title, values);
  };

  return (
    <StyledDialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <img
        src={ExperienceIcon}
        alt="Experience"
        style={{ position: "absolute", padding: "16px", width: "90px" }}
      />
      <DialogActions>
        <Button onClick={onClose}>
          <CloseIcon />
        </Button>
      </DialogActions>
      <DialogTitle>
        <Typography variant="h3" marginTop={"10px"}>
          {elcExperience.button === "Update"
            ? title.replace("Add", "Update")
            : title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue, errors, touched }) => {
            return (
              <Form>
                <Grid2 container direction="column" spacing={3}>
                  <CommonGrid2
                    size={12}
                    gap={"5px"}
                    display={"flex"}
                    flexDirection={"column"}
                  >
                    <Typography
                      fontWeight={500}
                      fontSize={"14px"}
                      color={"#344054"}
                    >
                      {"Position Title"}
                    </Typography>
                    <CommonTextField
                      placeholder="Enter Position Title"
                      padding="10px"
                      placeholderFontSize={"15px"}
                      error={errors.position && touched.position}
                      value={values.position}
                      onChange={(e) => {
                        setFieldValue("position", e.target.value);
                      }}
                    />
                    {errors.position && touched.position && (
                      <Typography
                        color="error"
                        variant="body1"
                        marginTop={"-2px"}
                      >
                        {errors.position}
                      </Typography>
                    )}
                  </CommonGrid2>
                  <CommonGrid2
                    size={12}
                    gap={"5px"}
                    display={"flex"}
                    flexDirection={"column"}
                  >
                    <Typography
                      fontWeight={500}
                      fontSize={"14px"}
                      color={"#344054"}
                    >
                      {"Company Name"}
                    </Typography>
                    <CommonTextField
                      placeholder="Enter Company Name"
                      padding="10px"
                      placeholderFontSize={"15px"}
                      error={errors.company && touched.company}
                      value={values.company}
                      onChange={(e) => {
                        setFieldValue("company", e.target.value);
                      }}
                    />
                    {errors.company && touched.company && (
                      <Typography
                        color="error"
                        variant="body1"
                        marginTop={"-2px"}
                      >
                        {errors.company}
                      </Typography>
                    )}
                  </CommonGrid2>

                  {/* <Grid2 size={12}>
            <StyledFlexColumnView>
              <Typography fontWeight={500} fontSize={"14px"} color={"#344054"}>
                {"Company Avatar"}
              </Typography>
              <CommonTextField
                readOnly={true}
                handleFileChange={handleFileChange}
                value={fileName}
                padding="10px"
              />
            </StyledFlexColumnView>
          </Grid2> */}
                  <CommonGrid2
                    size={12}
                    gap={"5px"}
                    display={"flex"}
                    flexDirection={"column"}
                  >
                    <Typography
                      fontWeight={500}
                      fontSize={"14px"}
                      color={"#344054"}
                    >
                      {"Date"}
                    </Typography>
                    <DateRangePickerComp
                      startDateProp={values.Date_range.start_date}
                      endDateProp={values.Date_range.end_date}
                      handleChange={(start_date, end_date) => {
                        setFieldValue("Date_range", {
                          start_date: start_date,
                          end_date: end_date,
                        });
                      }}
                      error={
                        touched?.Date_range?.start_date &&
                        touched?.Date_range?.end_date &&
                        errors?.Date_range
                      }
                    />
                    {touched?.Date_range?.start_date &&
                      touched?.Date_range?.end_date &&
                      errors?.Date_range && (
                        <Typography
                          color="error"
                          variant="body1"
                          marginTop={"-2px"}
                        >
                          {errors?.Date_range}
                        </Typography>
                      )}
                  </CommonGrid2>

                  <CommonButton
                    variant="outlined"
                    color="#344054"
                    border="#D0D5DD"
                    fontWeight="600"
                    value={elcExperience.button}
                    padding={"10px"}
                    type="submit"
                    loading={elcExperience.loading}
                    disabled={elcExperience.loading}
                  />
                </Grid2>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </StyledDialog>
  );
};

export default AddExperience;

export default function PopupTickIco({ margin }) {
  return (
    <svg
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ margin: margin }}
    >
      <g filter="url(#filter0_d_701_33708)">
        <path
          d="M2 11C2 5.47715 6.47715 1 12 1H40C45.5229 1 50 5.47715 50 11V39C50 44.5229 45.5228 49 40 49H12C6.47715 49 2 44.5228 2 39V11Z"
          fill="#D5FFE9"
          shape-rendering="crispEdges"
        />
        <path
          d="M35.7234 16.4591C35.3574 16.0918 34.7573 16.0918 34.3913 16.4591L23.311 27.5367L17.6046 21.7914C17.2387 21.4227 16.6426 21.4227 16.2739 21.7914L14.2745 23.7895C13.9085 24.1541 13.9085 24.7542 14.2745 25.1215L22.6396 33.5418C23.0055 33.9064 23.6016 33.9064 23.9716 33.5418L37.7215 19.7906C38.0928 19.4233 38.0928 18.8218 37.7215 18.4531L35.7234 16.4591Z"
          fill="#47CD89"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_701_33708"
          x="0"
          y="0"
          width="52"
          height="52"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_701_33708"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_701_33708"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

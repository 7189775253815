import { Grid2, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SnackBar from "../../component/SnackBar";
import { HarizonAnalytics } from "../../redux/action/Harizon";
import { StyledSpaceBetweenBox } from "../quick-match/components/StyledComponent";
import AnalyticsCard from "./components/AnalyticsCard";

const Dashboard = () => {
  const harizonAnalytics = useSelector(
    (state) => state.harizon.harizonAnalytics
  );
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarState, setSnackbarState] = React.useState({
    open: false,
    message: "",
    severity: "error",
  });
  const analytics = [
    {
      name: "JEM",
    },
    {
      name: "ICE",
    },
    {
      name: "DAME",
    },
    {
      name: "QM",
    },
    {
      name: "Time Saved",
    },
    {
      name: "ROI",
    },
  ];

  useEffect(() => {
    fetchAnalytics();
  }, []);

  const fetchAnalytics = async () => {
    try {
      setIsLoading(true);
      const data = await dispatch(HarizonAnalytics());
      console.log(data, "sfjksfnj");
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setSnackbarState({
        open: true,
        severity: "error",
        message: error.message,
      });
    }
  };

  const mergedAnalyticsData = analytics.map((item) => {
    const found =
      Object.keys(harizonAnalytics).length !== 0 &&
      harizonAnalytics.find(
        (data) => data.name.toLowerCase() === item.name.toLowerCase()
      );
    return {
      ...item,
      value: found ? found.value : 0,
    };
  });

  const handleCloseValidation = () => {
    setSnackbarState((prev) => ({
      ...prev,
      open: false,
    }));
  };

  return (
    <Grid2 container spacing={3}>
      <Grid2 item size={{ xs: 12 }} paddingBottom={3}>
        <StyledSpaceBetweenBox>
          <Typography variant="h2">Dashboard</Typography>
        </StyledSpaceBetweenBox>
      </Grid2>
      <Grid2 item size={{ xs: 12 }}>
        <AnalyticsCard
          harizonAnalytics={mergedAnalyticsData}
          isLoading={isLoading}
        />
      </Grid2>
      <SnackBar
        snackbarState={snackbarState}
        handleCloseValidation={handleCloseValidation}
      />
    </Grid2>
  );
};
export default Dashboard;

export default function IcanioIco() {
  return (
    <svg
      width="48"
      height="11"
      viewBox="0 0 48 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M17.7283 0L19.6372 3.16442H15.8195L17.7283 0Z" fill="#0D2457" />
      <path
        d="M15.8241 3.16455L17.7329 6.32898H13.9152L15.8241 3.16455Z"
        fill="#0069BB"
      />
      <path
        d="M19.6382 3.16455L21.547 6.32898H17.7294L19.6382 3.16455Z"
        fill="#0069BB"
      />
      <path
        d="M21.5047 6.29126L23.4135 9.45568H19.5959L21.5047 6.29126Z"
        fill="#19BAD8"
      />
      <path
        d="M13.9197 6.28796L15.8287 9.45238H12.0109L13.9197 6.28796Z"
        fill="#19BAD8"
      />
      <path
        d="M17.7283 6.32458L15.8195 3.16016H19.6372L17.7283 6.32458Z"
        fill="#004D9F"
      />
      <path
        d="M15.8286 9.45019L13.9197 6.28577H17.7375L15.8286 9.45019Z"
        fill="#008E87"
      />
      <path
        d="M19.6004 9.45019L17.6916 6.28577H21.5094L19.6004 9.45019Z"
        fill="#008E87"
      />
      <path
        d="M0 9.92789V0.363912H1.03308V9.92789H0ZM7.76762 9.12179C8.30429 9.12179 8.82306 9.00793 9.32396 8.78022C9.82484 8.55249 10.2452 8.22914 10.5851 7.81015L11.256 8.50695C10.7998 9.00793 10.2676 9.39504 9.65937 9.66829C9.05115 9.94155 8.41162 10.0782 7.74079 10.0782C7.06996 10.0782 6.43938 9.95067 5.84904 9.69562C5.26765 9.43148 4.76228 9.0808 4.33296 8.64358C3.91257 8.19726 3.57715 7.67352 3.32671 7.07235C3.0852 6.4712 2.96446 5.82903 2.96446 5.14589C2.96446 4.46275 3.0852 3.8206 3.32671 3.21943C3.57715 2.60916 3.91703 2.08543 4.34637 1.64821C4.7757 1.20189 5.28107 0.851222 5.86245 0.596171C6.44384 0.341134 7.06996 0.213623 7.74079 0.213623C8.4295 0.213623 9.08245 0.350248 9.69962 0.623498C10.3168 0.896763 10.8445 1.28388 11.2828 1.78485L10.5985 2.50897C10.2497 2.08998 9.82037 1.76207 9.31054 1.52525C8.80071 1.28842 8.27746 1.17001 7.74079 1.17001C7.21307 1.17001 6.72112 1.27476 6.26496 1.48426C5.80878 1.68465 5.41523 1.96247 5.08428 2.31769C4.75335 2.67292 4.48949 3.09647 4.2927 3.58833C4.10487 4.07109 4.01096 4.59028 4.01096 5.14589C4.01096 5.70152 4.10487 6.22526 4.2927 6.71712C4.48949 7.19988 4.75335 7.61887 5.08428 7.9741C5.41523 8.32934 5.80878 8.6117 6.26496 8.82119C6.73007 9.02159 7.23095 9.12179 7.76762 9.12179Z"
        fill="#00506A"
      />
      <path
        d="M25.4177 0.363912H26.4909L31.9918 8.28834L31.9649 0.363912H32.9444V9.92789H32.0052L26.3701 1.82583L26.397 9.92789H25.4177V0.363912ZM35.3091 9.92789V0.363912H36.3423V9.92789H35.3091ZM47.625 5.14589C47.625 5.82903 47.5042 6.47575 47.2627 7.08602C47.0212 7.68719 46.6904 8.21092 46.2699 8.65724C45.8495 9.09446 45.3532 9.44058 44.7807 9.69562C44.2171 9.95067 43.609 10.0782 42.9561 10.0782C42.3121 10.0782 41.7038 9.95067 41.1313 9.69562C40.5588 9.44058 40.0625 9.09446 39.6421 8.65724C39.2217 8.21092 38.8908 7.68719 38.6493 7.08602C38.4078 6.47575 38.287 5.82903 38.287 5.14589C38.287 4.46275 38.4078 3.8206 38.6493 3.21943C38.8908 2.60916 39.2217 2.08543 39.6421 1.64821C40.0625 1.20189 40.5588 0.851222 41.1313 0.596171C41.7038 0.341134 42.3121 0.213623 42.9561 0.213623C43.609 0.213623 44.2171 0.341134 44.7807 0.596171C45.3532 0.851222 45.8495 1.20189 46.2699 1.64821C46.6904 2.08543 47.0212 2.60916 47.2627 3.21943C47.5042 3.8206 47.625 4.46275 47.625 5.14589ZM46.5785 5.14589C46.5785 4.59028 46.4846 4.07109 46.2967 3.58833C46.109 3.09647 45.854 2.67292 45.532 2.31769C45.21 1.96247 44.8254 1.68465 44.3782 1.48426C43.9399 1.27476 43.4658 1.17001 42.9561 1.17001C42.4462 1.17001 41.9677 1.27476 41.5205 1.48426C41.0822 1.68465 40.7021 1.96247 40.38 2.31769C40.058 2.67292 39.803 3.09647 39.6153 3.58833C39.4274 4.07109 39.3336 4.59028 39.3336 5.14589C39.3336 5.70152 39.4274 6.22526 39.6153 6.71712C39.803 7.19988 40.058 7.61887 40.38 7.9741C40.7021 8.32934 41.0822 8.6117 41.5205 8.82119C41.9677 9.02159 42.4462 9.12179 42.9561 9.12179C43.4658 9.12179 43.9399 9.02159 44.3782 8.82119C44.8254 8.6117 45.21 8.32934 45.532 7.9741C45.854 7.61887 46.109 7.19988 46.2967 6.71712C46.4846 6.22526 46.5785 5.70152 46.5785 5.14589Z"
        fill="#00506A"
      />
    </svg>
  );
}

export default function ErrorCodeIco() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 10C0 4.47715 4.47715 0 10 0H38C43.5229 0 48 4.47715 48 10V38C48 43.5229 43.5228 48 38 48H10C4.47715 48 0 43.5228 0 38V10Z"
        fill="#FFEAF4"
      />
      <path
        d="M26.8712 13.1375C26.7855 13.0495 26.6681 13 26.5453 13H21.4545C21.3317 13 21.2142 13.0498 21.1286 13.1375C21.0427 13.2252 20.9968 13.3443 21.0002 13.467L21.382 27.2834C21.3885 27.5293 21.5901 27.725 21.8362 27.725H26.1645C26.4106 27.725 26.6124 27.5293 26.6187 27.283L26.9998 13.467C27.003 13.3445 26.9569 13.2254 26.8712 13.1375Z"
        fill="#D80027"
      />
      <path
        d="M26.1554 30.0518H21.8451C21.5942 30.0518 21.3906 30.2554 21.3906 30.5063V34.4923C21.3906 34.7431 21.5942 34.9468 21.8451 34.9468H26.1559C26.4067 34.9468 26.6104 34.7431 26.6104 34.4923V30.5063C26.6099 30.2554 26.4067 30.0518 26.1554 30.0518Z"
        fill="#D80027"
      />
    </svg>
  );
}

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  AccordionDetails,
  AccordionSummary,
  Grid2,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import CommonButton from "../../../component/Button";
import SnackBar from "../../../component/SnackBar";
import { JobJobLock, JobJobShare, JobSummary } from "../../../redux/action/Job";
import {
  CommonGrid2,
  CustomAccordion,
  StyledFlexRowView,
} from "../../common-components/StyledComponents";
import ShareQuickMatch from "../../quick-match/components/ShareQuickMatch";
import { ErrorContent } from "../style";
import Interactions from "./components/Interactions";
import Results from "./components/Results";
import Skills from "./components/Skills";
import SummaryInteractionDialog from "./components/SummaryInteractionDialog";
import ChatBot from "../../chat-bot";

function JobsSummary({ fetchData }) {
  const SummaryJobData = useSelector((state) => state.jobs.jobSummary);
  const query = new URLSearchParams(useLocation().search);
  const jobId = query.get("id");
  const dispatch = useDispatch();
  const [tableLoader, setTableLoader] = useState(false);
  const resultHeaderData = ["Candidate", "Status", "Score", ""];
  const attributeHeaderData = ["Skill", "Experience", "Median Value"];
  const [interActionOpen, setInterActionOpen] = useState(false);
  const [interActionData, setInterActionData] = useState({});
  const interactionHeaderData = [
    "ID",
    "Type",
    "Action",
    "Result",
    "Date",
    "Made By",
    "",
  ];
  const [isLoading, setIsLoading] = useState(false);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [dragContent, setDragContent] = useState(
    SummaryJobData?.results?.candidates || []
  );

  const [lock, setLock] = useState({
    content: "UnLock",
    loading: false,
    error: false,
  });

  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: "",
    severity: "error",
  });

  const fetchSummaryDetails = async () => {
    try {
      setTableLoader(true);
      await dispatch(JobSummary(jobId));
      setTableLoader(false);
    } catch (error) {
      setSnackbarState({
        open: true,
        message: error?.data?.message || error?.message,
        severity: "error",
      });
      setTableLoader(false);
    }
  };

  useEffect(() => {
    fetchSummaryDetails();
  }, [jobId]);

  useEffect(() => {
    setDragContent(SummaryJobData?.results?.candidates);
  }, [SummaryJobData]);
  const handleCloseValidation = () => {
    setSnackbarState((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const handleInteractionAction = (data) => {
    setInterActionOpen(true);
    setInterActionData(data);
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination || destination.index === source.index) return;

    const reorderedCandidates = [...dragContent];
    const [removed] = reorderedCandidates.splice(source.index, 1);
    reorderedCandidates.splice(destination.index, 0, removed);

    setDragContent(reorderedCandidates);
  };

  const accordionDataView = [
    {
      title: (
        <StyledFlexRowView>
          Results
          <Typography fontSize={"20px"} fontWeight={600} color={"#667085"}>
            · {SummaryJobData?.results?.count}
          </Typography>
        </StyledFlexRowView>
      ),
      component: (
        <Results
          SummaryJobData={SummaryJobData}
          lock={lock}
          tableLoader={tableLoader}
          resultHeaderData={resultHeaderData}
          onDragEnd={onDragEnd}
          dragContent={dragContent}
        />
      ),
    },
    {
      title: "Skills",
      component: (
        <Skills
          attributeHeaderData={attributeHeaderData}
          SummaryJobData={SummaryJobData}
          tableLoader={tableLoader}
        />
      ),
    },
    {
      title: "Interactions",
      component: (
        <Interactions
          SummaryJobData={SummaryJobData}
          interactionHeaderData={interactionHeaderData}
          tableLoader={tableLoader}
          handleInteractionAction={handleInteractionAction}
        />
      ),
    },
  ];

  const handleShareDialogClose = () => {
    setShareDialogOpen(false);
  };

  const handleShareInvite = async (shareData) => {
    setIsLoading(true);
    try {
      let payload = {
        url: window.location.href,
        to_mail: shareData.map((item) => item.email),
        cc_mail: [],
      };
      const data = await JobJobShare(payload, "summary");
      setSnackbarState({
        open: true,
        message: data.message,
        severity: "success",
      });
      handleShareDialogClose();
      setIsLoading(false);
    } catch (error) {
      setSnackbarState({
        open: true,
        message: error.message,
        severity: "error",
      });
      setIsLoading(false);
    }
  };

  const handleShare = () => {
    setShareDialogOpen(true);
  };

  const handleLock = async (e) => {
    e.stopPropagation();
    if (lock.content === "UnLock") {
      setLock({
        content: "Lock",
        loading: false,
        error: false,
      });
    } else if (
      JSON.stringify(SummaryJobData?.results?.candidates) ==
      JSON.stringify(dragContent)
    ) {
      setLock((prev) => ({
        ...prev,

        error: true,
      }));
      setTimeout(() => {
        setLock((prev) => ({
          ...prev,
          error: false,
        }));
      }, [1500]);
    } else if (lock.content === "Lock") {
      setLock((prev) => ({
        ...prev,
        loading: true,
        error: false,
      }));

      try {
        const data = await JobJobLock(dragContent);
        setTableLoader(true);
        await dispatch(JobSummary(jobId));
        fetchData();
        setSnackbarState({
          open: true,
          message: data.message,
          severity: "success",
        });
        setLock({
          content: "UnLock",
          loading: false,
          error: false,
        });
        setTableLoader(false);
      } catch (error) {
        setSnackbarState({
          open: true,
          message: error?.data?.message || error?.message,
          severity: "error",
        });
        setLock({
          content: "Lock",
          loading: false,
          error: false,
        });
      }
    }
  };

  return (
    <Grid2 container spacing={3}>
      <Grid2 size={12}>
        {accordionDataView.map((item, index) => (
          <CustomAccordion
            ContentMargin={"0px !important"}
            paddingBottom={"1rem"}
            defaultExpanded
          >
            <AccordionSummary
              expandIcon={<KeyboardArrowUpIcon />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
            >
              <CommonGrid2
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
                size={12}
              >
                <Typography variant="h4" margin={"15px 0px"} fontWeight={600}>
                  {item.title}
                </Typography>
                {index == 0 && (
                  <CommonGrid2
                    display={"flex"}
                    alignItems={"end"}
                    flexDirection={"column"}
                    marginRight={"15px"}
                  >
                    <Tooltip
                      title="Kindly UnLock to order the content"
                      arrow
                      placement="top"
                    >
                      <div style={{ width: "max-content" }}>
                        <CommonButton
                          variant="outlined"
                          padding={"5px 10px"}
                          color="#344054"
                          border="#D0D5DD"
                          fontWeight="600"
                          value={lock.content}
                          height="max-content"
                          onClick={(e) => handleLock(e)}
                          loading={lock.loading}
                          disabled={lock.loading}
                          borderRadius={"7px"}
                        />
                      </div>
                    </Tooltip>
                    {lock.error && (
                      <ErrorContent>
                        Kindly change the order to update it
                      </ErrorContent>
                    )}
                  </CommonGrid2>
                )}
              </CommonGrid2>
            </AccordionSummary>
            <AccordionDetails>
              <Grid2 size={12}>{item.component}</Grid2>
            </AccordionDetails>
          </CustomAccordion>
        ))}
      </Grid2>

      <Grid2 size={12}>
        <CommonButton
          variant="outlined"
          padding={"6px 0"}
          color="#344054"
          border="#D0D5DD"
          fontWeight="600"
          value="Share"
          onClick={handleShare}
        />
      </Grid2>
      <SummaryInteractionDialog
        open={interActionOpen}
        handleClose={() => setInterActionOpen(false)}
        interActionData={interActionData}
      />
      <SnackBar
        snackbarState={snackbarState}
        handleCloseValidation={handleCloseValidation}
      />
      <ShareQuickMatch
        shareDialogOpen={shareDialogOpen}
        handleShareDialogClose={handleShareDialogClose}
        handleShareInvite={handleShareInvite}
        isLoading={isLoading}
      />
    </Grid2>
  );
}

export default JobsSummary;

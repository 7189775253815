import SearchIcon from "@mui/icons-material/Search";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Grid, TableCell, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GetJobList } from "../../api/Api";
import CommonButton from "../../component/Button";
import CommonTextField from "../../component/Input";
import { GET } from "../../redux/axios/Axios";
import CommonTable from "../candidates/Table";
import { DotColor, StatusChange } from "../common-components/StyledComponents";
import FilterDialog from "./FilterDialogBox";

export default function Jobs() {
  const navigate = useNavigate();
  const [filterPopup, setFilterPopup] = useState(false);
  const [titleValue, setTitleValue] = useState("");
  const [jobList, setJobList] = useState({
    data: [],
    currentPage: 1,
    totalPage: "",
    loading: false,
  });
  const limit = 10;
  const fetchJobsList = async (limit, offset) => {
    setJobList((prev) => ({
      ...prev,
      loading: true,
    }));
    try {
      const response = await GET(GetJobList(limit, offset, "Active"));
      const data = response.data;
      setJobList((prev) => ({
        ...prev,
        data: data?.results || [],
        totalPage: data?.total_pages,
        loading: false,
      }));
    } catch (err) {
      setJobList((prev) => ({
        ...prev,
        loading: false,
      }));
    }
  };

  useEffect(() => {
    fetchJobsList(limit, (jobList.currentPage - 1) * limit);
  }, [jobList.currentPage]);

  const headerData = [
    "Unique ID",
    "Req ID",
    "Title",
    "Status",
    "Hourly Rate",
    "Client",
    "Created",
    "",
  ];
  const sortItem = ["Req ID", "Status", "Hourly Rate", "Created"];

  const handleFilterClick = () => {
    setFilterPopup(true);
  };

  const handleClose = () => {
    setFilterPopup(false);
  };

  const DropDownData = [
    {
      id: 10,
      value: "Ten",
    },
    {
      id: 20,
      value: "Twenty",
    },
    {
      id: 30,
      value: "Thirty",
    },
  ];

  const handleTitleChange = (event) => {
    console.log(event, "saflkhsfkhshk");
    setTitleValue(event.target.value);
  };

  const handleIconClick = (data) => {
    navigate(`/jobs/jobs-view?id=${data.id}&title=${data.job_title}`);
  };
  const handleTitleClick = (data) => {
    navigate(`/jobs/jobs-board?id=${data.id}&title=${data.job_title}`);
  };

  const handleTableBodyData = (row, index) => {
    if (!row) return null;

    return (
      <>
        <TableCell>{row.unique_id || "-"}</TableCell>
        <TableCell>{row.req_id || "-"}</TableCell>
        <TableCell>{row.job_title || "-"}</TableCell>

        <TableCell>
          <StatusChange>
            <DotColor
              color={
                row.status === "Active"
                  ? "green"
                  : row.status === "Hold"
                  ? "orange"
                  : "#667085"
              }
            />
            {row.status || "-"}
          </StatusChange>
        </TableCell>
        <TableCell>{row.pay_rate || "-"}</TableCell>
        <TableCell>{row.client || "-"}</TableCell>
        <TableCell>
          {row.created_at ? new Date(row.created_at).toLocaleDateString() : "-"}
        </TableCell>
        <TableCell>
          <VisibilityOutlinedIcon
            onClick={() => handleIconClick(row)}
            style={{ cursor: "pointer" }}
          />
        </TableCell>
      </>
    );
  };

  return (
    <>
      <Grid container alignItems="center">
        <Grid item xs={6} display="flex" alignItems="center">
          <Typography variant="h2">Jobs</Typography>
        </Grid>

        <Grid
          item
          xs={6}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          sx={{ gap: 2 }}
        >
          <CommonTextField
            placeholder="Search"
            startIcon={<SearchIcon />}
            disabled={true}
          />
          <CommonButton
            variant="outlined"
            color="#344054"
            border="#D0D5DD"
            fontWeight="600"
            value="Filters"
            onClick={() => handleFilterClick()}
            disabled={true}
          />

          <CommonButton
            variant="contained"
            color="white"
            background="#7F56D9"
            fontWeight="600"
            value="Add New"
            onClick={() => navigate("/jobs/create-jobs")}
          />
        </Grid>
      </Grid>
      <CommonTable
        data={jobList.data}
        columns={headerData}
        sortedColumns={sortItem}
        onSortChange={(col) => console.log("Sort by", col)}
        handleTableBodyData={handleTableBodyData}
        paginationProps={{
          count: jobList.totalPage,
          page: jobList.currentPage,
          onPageChange: (page) =>
            setJobList((prev) => ({
              ...prev,
              currentPage: page,
            })),
        }}
        withPagination={true}
        loading={jobList.loading}
      />

      <FilterDialog
        handleTitleChange={handleTitleChange}
        DropDownData={DropDownData}
        titleValue={titleValue}
        handleClose={handleClose}
        filterPopup={filterPopup}
      />
    </>
  );
}

import { Divider, Grid2, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  addDameNotes,
  deleteDameNote,
  getCompareCandidates,
  getDameNotes,
  getMatchedJdlistResumeData,
  updateDameNote,
} from "../../../api/Api";
import Breadcrumbs from "../../../component/Breadcrumbs";
import { DELETE, GET, POST, PUT } from "../../../redux/axios/Axios";
import AddUpdateNote from "../../common-components/AddUpdateNote";
import CommonAccordion from "../../common-components/CommonAccordion";
import DeleteNote from "../../common-components/DeleteNote";
import Experience from "../jobs-candidate/components/Experience";
import MatchingSummary from "../jobs-candidate/components/MatchingSummary";
import Notes from "../jobs-candidate/components/Notes";
import StrengthWeak from "../jobs-candidate/components/StrengthWeak";
import Actions from "./components/Actions";
import CompareProfile from "./components/CompareProfile";

function JobsCandidateCompare() {
  const navigate = useNavigate();
  const param = useParams();
  const firstResumeId = param.firstResumeId;
  const secondResumeId = param.secondResumeId;
  const [pageLoader, setPageLoader] = useState(false);
  const [tableList, setTableList] = useState([]);
  const [listResumeData, setListResumeData] = useState([]);
  const query = new URLSearchParams(useLocation().search);
  const [noteList, setNoteList] = useState([
    { note: "", interaction: "Email", added_by: "username" },
  ]);

  const [openNoteDialog, setOpenNoteDialog] = useState(false);
  const [deleteNoteDialogOpen, setDeleteNoteDialogOpen] = useState(false);
  const [noteId, setNoteId] = useState("");
  const [notesTableList, setNotesTableList] = useState([]);
  const [addNoteLoading, setAddNoteLoading] = useState(false);
  const [notesTableListLoading, setNotesTableListLoading] = useState(false);
  const [currentResumeId, setCurrentResumeId] = useState(null);

  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: "",
    severity: "",
  });

  const fetchCandidateCompareList = async () => {
    try {
      const res = await GET(
        getCompareCandidates(firstResumeId, secondResumeId)
      );
      console.log(res?.data, "ress");
      setTableList(res?.data);
      setPageLoader(false);
    } catch (error) {
      setPageLoader(false);
    }
  };
  const getlistResumeData = async () => {
    try {
      const res = await GET(
        getMatchedJdlistResumeData(firstResumeId, secondResumeId)
      );
      setListResumeData(res?.data || []);
      fetchCandidateCompareList();
    } catch (error) {
      console.error("Error fetching resume data:", error);
    }
  };

  useEffect(() => {
    setPageLoader(true);
    getlistResumeData();
    fetchCandidateCompareList();
  }, [firstResumeId, secondResumeId]);

  const handleReplaceSecondaryId = (id) => {
    const currentUrl = window.location.pathname;
    const newUrl = currentUrl.replace(param.secondResumeId, id);
    navigate(newUrl); // Use React Router's `navigate` to update the route
  };

  const handleClickAddNote = (resumeId) => {
    setCurrentResumeId(resumeId);
    setOpenNoteDialog(true);
    setNoteId("");
    setNoteList([{ note: "", interaction: "Email", added_by: "username" }]);
  };

  const handleCloseNoteDialog = () => {
    setNoteId("");
    setOpenNoteDialog(false);
    setNoteList([{ note: "", interaction: "Email", added_by: "username" }]);
  };

  const handleDeleteNote = (row) => {
    setDeleteNoteDialogOpen(true);
    setNoteId(row?.id);
    setNoteList([
      {
        note: row?.note,
        interaction: row?.interaction,
        added_by: row?.added_by,
        created_at: row?.created_at,
      },
    ]);
  };

  const handleDeleteNoteDialogClose = () => {
    setDeleteNoteDialogOpen(false);
    setNoteId("");
    setNoteList([{ note: "", interaction: "Email", added_by: "username" }]);
  };

  const handleUpdateNote = (row) => {
    if (row?.id) {
      setNoteId(row?.id);
      setOpenNoteDialog(true);
      setNoteList([
        {
          note: row?.note,
          interaction: row?.interaction,
          added_by: row?.added_by,
        },
      ]);
    }
  };

  const handleCreateNote = async (resumeId) => {
    setAddNoteLoading(true);
    if (resumeId) {
      const payload = noteList;
      if (noteId) {
        const res = await PUT(updateDameNote(noteId), noteList[0]);
        if (res?.success) {
          console.log(res?.data?.success, "tr");
          setSnackbarState({
            open: true,
            severity: "success",
            message: res?.message,
          });
        } else {
        }
      } else {
        const res = await POST(addDameNotes(resumeId), payload);
        console.log(res?.data, "note");
        if (res?.success) {
          console.log(res?.data?.success, "tr");
          setSnackbarState({
            open: true,
            severity: "success",
            message: res?.message,
          });
        } else {
        }
      }
      setAddNoteLoading(false);
      await fetchNotesTableList(firstResumeId, secondResumeId);
      setOpenNoteDialog(false);
      setNoteList([{ note: "", interaction: "Email", added_by: "username" }]);
    }
  };

  const handleDeleteNoteById = async (row) => {
    const res = await DELETE(deleteDameNote(noteId));
    console.log(res?.data, "note");
    if (res?.success) {
      console.log(res?.data?.success, "tr");
      setSnackbarState({
        open: true,
        severity: "success",
        message: res?.message,
      });
      // setAddNoteLoading(false);
      await fetchNotesTableList(firstResumeId, secondResumeId);
      setNoteList([{ note: "", interaction: "Email", added_by: "username" }]);
      setDeleteNoteDialogOpen(false);
      setNoteId("");
    } else {
    }
  };

  const handleNoteTextChange = (index, e) => {
    const newNoteList = [...noteList];
    newNoteList[index].note = e.target.value;
    setNoteList(newNoteList);
  };

  const fetchNotesTableList = async (firstResumeId, secondResumeId) => {
    setNotesTableListLoading(true);
    try {
      const [firstNotes, secondNotes] = await Promise.all([
        GET(getDameNotes(firstResumeId)),
        GET(getDameNotes(secondResumeId)),
      ]);

      setNotesTableList([
        { resumeId: firstResumeId, notes: firstNotes?.data },
        { resumeId: secondResumeId, notes: secondNotes?.data },
      ]);
    } catch (error) {
      console.error("Error fetching notes:", error);
    } finally {
      setNotesTableListLoading(false);
    }
  };

  useEffect(() => {
    fetchNotesTableList(firstResumeId, secondResumeId);
  }, [firstResumeId, secondResumeId]);

  const breadCrumbData = [
    {
      title: "Jobs",
      navigate: "/jobs",
    },
    {
      title: query.get("title"),
      navigate: -1,
    },
    {
      title: "Compare",
      active: true,
    },
  ];
  return (
    <Grid2 container spacing={3}>
      <Grid2 size={12}>
        <Breadcrumbs breadCrumbData={breadCrumbData} />
      </Grid2>
      {pageLoader ? (
        Array.from(new Array(20)).map((el, ind) => (
          <Grid2 item size={4} key={`key-${ind}`}>
            <Skeleton
              key={`key-${ind.toString()}`}
              width={"100%"}
              height={100}
            />
          </Grid2>
        ))
      ) : (
        <>
          {tableList?.candidates?.map((item, index) => (
            <Grid2 size={6}>
              <CompareProfile
                matchedData={item}
                getlistResumeData={getlistResumeData}
                listResumeData={listResumeData}
                handleReplaceSecondaryId={(id) => handleReplaceSecondaryId(id)}
              />
            </Grid2>
          ))}

          <Grid2 size={12}>
            <CommonAccordion
              title={"Overall"}
              children={
                <Grid2 container spacing={3}>
                  {tableList?.candidates?.map((item, index) => (
                    <Grid2 size={6}>
                      <MatchingSummary
                        matchedData={item}
                        handleScoreChange={""}
                      />
                    </Grid2>
                  ))}
                </Grid2>
              }
            />
          </Grid2>

          <Grid2 size={12}>
            <CommonAccordion
              title={"Strengths"}
              children={
                <Grid2 container spacing={3}>
                  {tableList?.candidates?.map((item, index) => (
                    <Grid2 size={6}>
                      <StrengthWeak size={12} matchedData={item} />
                    </Grid2>
                  ))}
                </Grid2>
              }
            />
          </Grid2>

          <Grid2 size={12}>
            <CommonAccordion
              title={"Experience"}
              children={
                <Grid2 container spacing={3}>
                  {tableList?.candidates?.map((item, index) => (
                    <Grid2 size={6}>
                      <Experience matchedData={item?.experience} />
                    </Grid2>
                  ))}
                </Grid2>
              }
            />
          </Grid2>

          <Grid2 size={12}>
            <CommonAccordion
              title={"Notes"}
              children={
                <Grid2 container spacing={3}>
                  {notesTableList.map((item, index) => (
                    <Grid2 key={item.resumeId} size={6}>
                      <Notes
                        handleClickAddNote={() =>
                          handleClickAddNote(item.resumeId)
                        }
                        handleDeleteNoteClick={handleDeleteNote}
                        handleDeleteNoteDialogClose={
                          handleDeleteNoteDialogClose
                        }
                        handleUpdateNoteClick={handleUpdateNote}
                        notesTableList={item.notes}
                        notesTableListLoading={notesTableListLoading}
                      />
                    </Grid2>
                  ))}
                </Grid2>
              }
            />
          </Grid2>

          <Grid2 size={12}>
            <Divider />
          </Grid2>

          <Grid2 size={12}>
            <Grid2 container spacing={3}>
              {tableList?.candidates?.map((item, index) => (
                <Grid2 size={6}>
                  <Actions matchedData={item} />
                </Grid2>
              ))}
            </Grid2>
          </Grid2>
        </>
      )}
      <DeleteNote
        openNoteDialog={openNoteDialog}
        deleteNoteDialogOpen={deleteNoteDialogOpen}
        handleDeleteNoteDialogClose={handleDeleteNoteDialogClose}
        noteList={noteList}
        handleDeleteNoteById={handleDeleteNoteById}
      />
      <AddUpdateNote
        openNoteDialog={openNoteDialog}
        handleCloseNoteDialog={handleCloseNoteDialog}
        id={noteId}
        handleCreateNote={() => handleCreateNote(currentResumeId)}
        noteList={noteList}
        handleNoteTextChange={handleNoteTextChange}
        addNoteLoading={addNoteLoading}
      />
    </Grid2>
  );
}

export default JobsCandidateCompare;

export default function ChatBotIco() {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_1201_198)">
        <circle cx="30" cy="30" r="26" fill="white" />
      </g>
      <g clip-path="url(#clip0_1201_198)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M30 18C22.4929 18 16.7093 24.1546 16.6669 32.1552C16.6525 34.8764 17.2005 37.6267 19.3434 40.6667H21.0281C19.4503 38.9074 18.3724 36.6807 18.0183 34.2204C18.0037 34.1334 17.9979 34.0467 18.0003 33.9614C18.0167 33.3218 18.4929 32.7691 19.1474 32.6768L19.1537 32.676C25.2608 31.4243 29.5456 27.1469 31.4017 21.5784C31.6069 20.963 32.2173 20.5967 32.8375 20.6774C33.0189 20.6538 33.2083 20.6674 33.3948 20.7238C38.3819 22.2317 42 26.9151 42 32.4412C42 35.6032 40.8137 38.492 38.8636 40.6667H40.6256C42.2752 38.1036 43.3471 34.7675 43.3332 32.1552C43.2908 24.1546 37.5072 18 30 18ZM33.5032 23.5922C31.3215 29.0534 26.9584 33.3598 20.8981 35.0012C21.9993 39.0487 25.6458 42 29.9457 42C35.1105 42 39.3333 37.7404 39.3333 32.4412C39.3333 28.4354 36.916 25.0167 33.5032 23.5922ZM18.6669 43.3334H24.4648C26.1075 44.1855 27.9697 44.6667 29.9457 44.6667C31.9344 44.6667 33.8079 44.1794 35.4583 43.3171C35.5261 43.3278 35.5957 43.3334 35.6667 43.3334H41.3332C41.7651 43.3334 42.17 43.1243 42.4201 42.7723C44.5575 39.7642 46.0181 35.5855 45.9999 32.1411C45.9505 22.846 39.1415 15.3334 30 15.3334C20.8587 15.3334 14.0495 22.846 14.0003 32.1411C13.9824 35.5151 14.7351 39.0196 17.6043 42.8054C17.8563 43.138 18.2496 43.3334 18.6669 43.3334ZM27.3336 35.3331C26.4883 35.3336 25.8324 36.1595 26.04 36.99C26.6254 39.3315 28.6667 40.6667 30.0003 40.6667C31.2292 40.6667 33.27 39.3175 33.9604 36.9867C34.168 36.1563 33.5111 35.3336 32.6669 35.3331H27.3336ZM34 32C35.1045 32 36 31.1046 36 30C36 28.8955 35.1045 28 34 28C32.8955 28 32 28.8955 32 30C32 31.1046 32.8955 32 34 32Z"
          fill="#1B90FF"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1201_198"
          x="0"
          y="0"
          width="60"
          height="60"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1201_198"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1201_198"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_1201_198">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(14 14)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

import { Box, Grid2, Typography } from "@mui/material";
import React from "react";
import CommonButton from "../../../../component/Button";

function CommonInputJd({
  title,
  children,
  buttonName,
  handleButtonCommon,
  loading,
  disabled,
  marginTop,
}) {
  return (
    <Box
      sx={{
        width: {
          xs: "100%",
          sm: "80%",
          md: "55%",
        },
      }}
      margin={"auto"}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Grid2 container spacing={3}>
        <Grid2 size={12}>
          <Typography fontWeight={600} fontSize={"20px"} color={"#101828"}>
            {title}
          </Typography>
        </Grid2>

        <Grid2 size={12}>{children}</Grid2>

        <Grid2 size={12} marginTop={marginTop}>
          <CommonButton
            variant="contained"
            color="white"
            background="#7F56D9"
            fontWeight="600"
            width={"100%"}
            padding="10px 20px"
            value={buttonName}
            loading={loading}
            type="submit"
            disabled={loading || disabled}
            onClick={handleButtonCommon}
          />
        </Grid2>
      </Grid2>
    </Box>
  );
}

export default CommonInputJd;

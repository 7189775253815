import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import SearchIcon from "@mui/icons-material/Search";
import {
  Avatar,
  Checkbox,
  Grid,
  Grid2,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useState } from "react";
import BoxEditIco from "../../../assets/icons/BoxEditIco";
import FileAddIco from "../../../assets/icons/FileAddIco";
import ShareIco from "../../../assets/icons/ShareIco";
import Breadcrumbs from "../../../component/Breadcrumbs";
import CommonButton from "../../../component/Button";
import CommonTextField from "../../../component/Input";
import CustomMenuItem from "../../../component/Menu";
import {
  CommonGrid2,
  DotColor,
  StatusChange,
  StyledFlexRowView,
  TableContainerBorder,
} from "../../common-components/StyledComponents";
import ManagePermissions from "./ManagePermissions";
import RemoveMember from "./RemoveMember";

export default function AddGroup() {
  const [selectedRows, setSelectedRows] = useState(new Set());
  const [openRemoveMemberDialog, setRemoveMemberDialog] = useState(false);
  const [openManagePermissionsDialog, setManagePermissionsDialog] =
    useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClickDot = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleChangeRemoveMember = () => {
    setRemoveMemberDialog(true);
  };
  const handleCloseRemoveMember = () => {
    setRemoveMemberDialog(false);
  };
  const handleChangeManagePermission = () => {
    setManagePermissionsDialog(true);
  };
  const handleCloseManagePermission = () => {
    setManagePermissionsDialog(false);
  };

  const breadCrumbData = [
    {
      title: "Settings",
      navigate: "/user-management",
    },
    {
      title: "Group",
      active: true,
    },
  ];

  const menuData = [
    { title: "Share", image: <ShareIco /> },
    { title: "Add to an Active Job", image: <FileAddIco /> },
  ];

  const sortedData = [
    {
      id: 1,
      name: "Olivia Rhye",
      permissions: "Admin",
      position: "CEO",
      email: "olivia@mail.com",
      avatarUrl: "https://mui.com/static/images/avatar/1.jpg",
    },
    {
      id: 2,
      name: "Lana Steiner",
      permissions: "Manager",
      position: "Co-Founder",
      email: "lana@mail.com",
      avatarUrl: "https://mui.com/static/images/avatar/2.jpg",
    },
    {
      id: 3,
      name: "Kate Morrison",
      permissions: "Base",
      position: "Base",
      email: "kate@mail.com",
      avatarUrl: "https://mui.com/static/images/avatar/3.jpg",
    },
  ];

  const headerData = ["User", "Position", "Permissions", "Email Address", ""];

  const handleRowCheckboxChange = (event, rowId) => {
    const newSelectedRows = new Set(selectedRows);
    if (event.target.checked) {
      newSelectedRows.add(rowId);
    } else {
      newSelectedRows.delete(rowId);
    }
    setSelectedRows(newSelectedRows);
  };

  const handleSelectAllCheckboxChange = (event) => {
    if (event.target.checked) {
      const allRowIds = sortedData.map((row) => row.id);
      setSelectedRows(new Set(allRowIds));
    } else {
      setSelectedRows(new Set());
    }
  };

  return (
    <>
      <Breadcrumbs breadCrumbData={breadCrumbData} />
      <Grid2 container alignItems="center" marginTop={5}>
        <CommonGrid2
          item
          size={6}
          display="flex"
          alignItems="center"
          gap={"1rem"}
        >
          <Typography variant="h2" color="#101828">
            Group
          </Typography>
          <div
            style={{
              border: "1px solid #D0D5DD",
              borderRadius: "10px",
              color: "#344054",
              padding: "10px",
              display: "flex",
              boxShadow: "0px 1px 2px 0px #1018280D",
            }}
          >
            <BoxEditIco />
          </div>
        </CommonGrid2>

        <CommonGrid2
          item
          size={6}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          gap={"1rem"}
        >
          <CommonTextField placeholder="Search" startIcon={<SearchIcon />} />
          <CommonButton
            variant="outlined"
            color="#344054"
            border="#D0D5DD"
            fontWeight="600"
            value="Filters"
          />

          <CommonButton
            variant="contained"
            color="white"
            background="#7F56D9"
            fontWeight="600"
            value="Add Member"
          />
        </CommonGrid2>
      </Grid2>

      <TableContainerBorder>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  checked={selectedRows.size === sortedData.length}
                  indeterminate={
                    selectedRows.size > 0 &&
                    selectedRows.size < sortedData.length
                  }
                  onChange={handleSelectAllCheckboxChange}
                />
              </TableCell>
              {headerData.map((item, index) => (
                <TableCell key={index}>{item}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.map((row) => (
              <TableRow key={row.id}>
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    checked={selectedRows.has(row.id)}
                    onChange={(event) => handleRowCheckboxChange(event, row.id)}
                  />
                </TableCell>
                <TableCell>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item>
                      <Avatar alt={row.name} src={row.avatarUrl} />
                    </Grid>
                    <Grid item>{row.name}</Grid>
                  </Grid>
                </TableCell>
                <TableCell>{row.position}</TableCell>
                <TableCell>
                  <StatusChange>
                    <DotColor
                      color={
                        row.permissions === "Base"
                          ? "#667085"
                          : row.permissions === "Manager"
                          ? "orange"
                          : row.permissions === "Admin"
                          ? "green"
                          : "#667085"
                      }
                    />
                    {row.permissions}
                  </StatusChange>
                </TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell>
                  <MoreVertOutlinedIcon
                    style={{ cursor: "pointer" }}
                    onClick={(event) => handleClickDot(event)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainerBorder>

      <StyledFlexRowView style={{ padding: "32px 0px" }}>
        <CommonButton
          variant="outlined"
          color="#B42318"
          border="#FDA29B"
          fontWeight="600"
          value="Remove from Group"
          padding={"10px 16px"}
          disabled={selectedRows.size < 1}
          onClick={handleChangeRemoveMember}
        />
        <CommonButton
          variant="outlined"
          color="#344054"
          border="#D0D5DD"
          fontWeight="600"
          value="Change Permissions"
          padding={"10px 16px"}
          disabled={selectedRows.size < 1}
          onClick={handleChangeManagePermission}
        />
      </StyledFlexRowView>
      <RemoveMember
        open={openRemoveMemberDialog}
        onClose={handleCloseRemoveMember}
        onApply={() => {
          handleCloseRemoveMember();
        }}
        selectedUsers={sortedData.filter((row) => selectedRows.has(row.id))}
      />
      <ManagePermissions
        open={openManagePermissionsDialog}
        onClose={handleCloseManagePermission}
        onApply={() => {
          handleCloseManagePermission();
        }}
        selectedUsers={sortedData.filter((row) => selectedRows.has(row.id))}
      />

      <CustomMenuItem
        open={open}
        anchorEl={anchorEl}
        handleMenuClose={handleMenuClose}
        menuData={menuData}
      />
    </>
  );
}

import { styled } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

export const CustomDatePicker = styled(DatePicker)(({ error }) => ({
  "& input": {
    padding: "12px",
  },
  "& .MuiInputBase-root.MuiOutlinedInput-root": {
    borderRadius: "8px",
  },
  "& fieldset": {
    border: error ? "1px solid #d32f2f" : "1px solid #D0D5DD",
  },
  "& .MuiInputAdornment-root": {
    height: "100%",
    maxHeight: "none",
    borderLeft: "1px solid #D0D5DD",
  },
  "& .MuiIconButton-edgeEnd": {
    borderRadius: "0px",
  },
}));

export const CustomImg = styled("img")(({ width, height }) => ({
  width: width,
  height: height,
}));

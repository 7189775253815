import DeleteIcon from "@mui/icons-material/Delete";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import React from "react";
import CommonTextField from "../../../../component/Input";
import ToogleButton from "../../../../component/ToogleButton";
import { HandleSkeletion } from "../../../../utils/constants";
import {
  CommonGrid2,
  CustomTooltip,
  TableContainerBorder,
} from "../../../common-components/StyledComponents";
import { CustomizeTableCell, CustomTooltipField } from "../../style";
import JobPenIco from "../../../../assets/icons/JobPenIco";
import JobStarIco from "../../../../assets/icons/JobStarIco";
import JobPencilIco from "../../../../assets/icons/JobPencilIco";

const SkillTable = ({
  rowData,
  name,
  form,
  jobModifyData,
  handleSkillToogleChange,
  headerData,
  handleDeleteSkillRow,
  handleSkillTextChange,
  alignment,
  isLoading,
}) => {
  return (
    <TableContainerBorder
      marginTop="1rem"
      scrollStyle={true}
      height={rowData?.length >= 6 ? "400px" : "auto"}
    >
      <Table stickyHeader aria-label="sticky table" className="kathirvel">
        <TableHead>
          <TableRow>
            {headerData.map((item, index) => (
              <CustomizeTableCell
                key={index}
                textAlign={index === 2 && "center"}
                width={item.width}
              >
                {item.name}
              </CustomizeTableCell>
            ))}
          </TableRow>
        </TableHead>

        {isLoading ? (
          <TableBody>
            {Array.from({ length: 5 }).map((_, index) => (
              <TableRow>
                {Array.from({ length: 4 }).map((_, index) => (
                  <TableCell key={index}>
                    <HandleSkeletion height={30} />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        ) : (
          <TableBody>
            {rowData?.map((row, index) => (
              <TableRow key={index}>
                <CustomizeTableCell fontWeight={500} sx={{ width: "40%" }}>
                  {alignment === "Edit" ? (
                    <div style={{ position: "relative" }}>
                      <CommonTextField
                        padding="10px 0px 10px 10px"
                        width={"100%"}
                        label={"Skill"}
                        labelShrink={"10px"}
                        value={row?.skill}
                        fontSize={"14px"}
                        onChange={(event) =>
                          handleSkillTextChange(
                            event,
                            index,
                            form,
                            name,
                            "skill"
                          )
                        }
                        error={
                          form?.errors?.skillData?.[name]?.[index]?.["skill"] &&
                          form?.touched?.skillData?.[name]?.[index]?.["skill"]
                        }
                      />
                      {form?.errors?.skillData?.[name]?.[index]?.["skill"] &&
                        form?.touched?.skillData?.[name]?.[index]?.[
                          "skill"
                        ] && (
                          <CustomTooltipField size={7}>
                            {
                              form?.errors?.skillData?.[name]?.[index]?.[
                                "skill"
                              ]
                            }
                          </CustomTooltipField>
                        )}
                    </div>
                  ) : (
                    <Tooltip
                      disableHoverListener={!row?.about_the_skill}
                      title={
                        <span
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "center",
                          }}
                        >
                          {row?.about_the_skill}
                        </span>
                      }
                      arrow
                      placement="top"
                    >
                      {row?.skill}
                    </Tooltip>
                  )}
                </CustomizeTableCell>
                <TableCell sx={{ width: "40%" }}>
                  <ToogleButton
                    alignment={row?.expertise_level}
                    padding={"5px 10px"}
                    ToogleData={
                      row?.type === "relevant_hard_skill" ||
                      row?.type === "extracted_hard_skill" ||
                      row?.type === "user_added_hard_skill"
                        ? jobModifyData.criticalityHardData
                        : jobModifyData.criticalitySoftData
                    }
                    width={"max-content"}
                    handleChange={
                      alignment === "Edit"
                        ? (event, newAlignment) =>
                            handleSkillToogleChange(
                              event,
                              newAlignment,
                              index,
                              form,
                              name
                            )
                        : () => {}
                    }
                    gap={"10px"}
                    fontSize={"12px"}
                    border={"none !important"}
                    borderRadius={"5px !important"}
                    activeColor={"#FFFF"}
                    color={"#667085"}
                    activeBackgroundColor={
                      row?.expertise_level === "Familiar" ||
                      row?.expertise_level === "Novice"
                        ? "#A668F4"
                        : row?.expertise_level === "Expert"
                        ? "#3C097C"
                        : "#6510CF"
                    }
                    backgroundColor={"#EAECF0 !important"}
                    whiteSpace={"nowrap"}
                  />
                </TableCell>

                <CustomizeTableCell
                  fontWeight={500}
                  textAlign={"center"}
                  verticalAlign={"middle"}
                  width={"15%"}
                >
                  <CommonGrid2
                    display={"flex"}
                    gap={"10px"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    {row?.type === "extracted_soft_skill" ||
                    row?.type === "relevant_soft_skill" ? null : alignment ===
                      "Edit" ? (
                      <div style={{ position: "relative" }}>
                        <CommonTextField
                          padding="7px 0px 10px 10px"
                          fontSize={"15px"}
                          width={"20px"}
                          label={"Year"}
                          labelShrink={"10px"}
                          value={row?.["experience_in_yrs"]}
                          onChange={(event) =>
                            handleSkillTextChange(
                              event,
                              index,
                              form,
                              name,
                              "experience_in_yrs"
                            )
                          }
                          error={
                            form?.errors?.skillData?.[name]?.[index]?.[
                              "experience_in_yrs"
                            ] &&
                            form?.touched?.skillData?.[name]?.[index]?.[
                              "experience_in_yrs"
                            ]
                          }
                        />
                        {form?.errors?.skillData?.[name]?.[index]?.[
                          "experience_in_yrs"
                        ] &&
                          form?.touched?.skillData?.[name]?.[index]?.[
                            "experience_in_yrs"
                          ] && (
                            <CustomTooltipField size={7}>
                              {
                                form?.errors?.skillData?.[name]?.[index]?.[
                                  "experience_in_yrs"
                                ]
                              }
                            </CustomTooltipField>
                          )}
                      </div>
                    ) : (
                      row.experience_in_yrs
                    )}
                    {row?.type === "relevant_hard_skill" ||
                    row?.type === "relevant_soft_skill" ? (
                      <JobStarIco />
                    ) : row?.type === "extracted_hard_skill" ||
                      row?.type === "extracted_soft_skill" ? (
                      <JobPencilIco />
                    ) : (
                      <JobPenIco />
                    )}
                  </CommonGrid2>
                </CustomizeTableCell>
                {alignment === "Edit" && (
                  <CustomizeTableCell verticalAlign={"middle"} width={"5%"}>
                    <CustomTooltip title="Delete" backgroundColor="#000">
                      <DeleteIcon
                        style={{
                          cursor: "pointer",
                          width: "22px",
                        }}
                        onClick={() => handleDeleteSkillRow(row, form, name)}
                      />
                    </CustomTooltip>
                  </CustomizeTableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
    </TableContainerBorder>
  );
};

export default SkillTable;

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import React from "react";
import XCloseIco from "../../../../assets/icons/XCloseIco";
import { CustomDiv } from "../../../chat-bot/style";
import { CommonTippy } from "../../style";

const DialogCandidateHub = ({
  handleButttonClose,
  open,
  handleClose,
  footer,
  content,
  title,
  icon,
  closeIcon = true,
  disabled = false,
  titlepadding,
  centerIcon,
}) => {
  return (
    <Dialog
      fullWidth
      maxWidth={"xs"}
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "10px",
        },
      }}
    >
      {closeIcon && (
        <DialogActions sx={{ justifyContent: "space-between" }}>
          <CustomDiv>{icon}</CustomDiv>
          <CommonTippy
            content={"Button is disabled"}
            placement="top"
            disabled={!disabled}
            color="#7F56D9"
            fontWeight={600}
            fontSize={"13px"}
          >
            <CustomDiv>
              <Button
                onClick={handleButttonClose || handleClose}
                disabled={disabled}
              >
                <XCloseIco />
              </Button>
            </CustomDiv>
          </CommonTippy>
        </DialogActions>
      )}

      <DialogContent style={{ textAlign: "center", paddingTop: "0px" }}>
        {centerIcon}
        <Typography variant="h3" textAlign={"center"} padding={titlepadding}>
          {title}
        </Typography>
        <Typography variant="p" fontSize={"15px"}>
          {content}
        </Typography>

        {footer}
      </DialogContent>
    </Dialog>
  );
};

export default DialogCandidateHub;
